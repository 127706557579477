import React from 'react'
import '../../Css/Wrapper.css'

const Wrapper = ({children}) => {
  return (
    <div id='secondw'>
        {children}
    </div>
  )
}

export default Wrapper
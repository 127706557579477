import React, { useEffect, useState } from "react";
import Wrapper from "../../Components/UI/Wrapper";
import Card from "../../Components/UI/Card";
import Button from "../../Components/UI/Button";
import { Link, useNavigate } from "react-router-dom";
import { Form, Formik, Field } from "formik";
import { LoginSchema } from "../../Schemas";
import axios from "../../API";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import "../../Css/Auth/Login.css";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { toast } from "react-hot-toast";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";

const Login = () => {
  const [IsError, setIsError] = useState("");
  const ModalDataFromRedux = useSelector((state) => state.carData);

  // console.log(
  //   "ModalDataFromRedux=========!!!",
  //   ModalDataFromRedux[0]?.isOfferActivated
  // );

  const [Loading, setLoading] = useState(false);

  const Navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  const initialValues = {
    Email: "",
    Password: "",
  };

  const handleSubmit = async (values) => {
    setLoading(true);
    try {
      const response = await axios.post(
        "/login",
        {
          Email: values.Email,
          Password: values.Password,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );

      // console.log("🚀 response.......", response)
      setIsError(response.data.message);
      setLoading(false);
      if (response.data.data !== undefined) {
        let result = response.data.data;
        localStorage.setItem("user-info", JSON.stringify(result));
        toast.success(`${response.data.message}`);
        setLoading(true);
        try {
          const OfferRes = await axios.get("/get-offer-status", {
            headers: {
              Authorization: `Bearer ${response.data.data.Token}`,
            },
          });
          if (
            OfferRes.data.data.OfferSet == "FALSE" &&
            ModalDataFromRedux[0]?.isOfferActivated == true
          ) {
            try {
              const respon = axios.get("/update-offer-status", {
                headers: {
                  Authorization: `Bearer ${response.data.data.Token}`,
                },
              });
              // console.log(respon, "--_-------_-_-_--_-_-__--_-");
            } catch (error) {
              // console.log(error);
            }
          } else if (
            OfferRes.data.data.OfferSet == "TRUE" &&
            ModalDataFromRedux[0]?.isOfferActivated == false
          ) {
            try {
              const respon = axios.get("/update-offer-status", {
                headers: {
                  Authorization: `Bearer ${response.data.data.Token}`,
                },
              });
              // console.log(respon, "--_-------_-_-_--_-_-__--_-");
            } catch (error) {
              // console.log(error);
            }
          }
          // console.log("OfferRes---------!!!!", OfferRes);
        } catch (error) {
          // console.log("OfferRes ki error wo bhi catch wali___!!!", error);
        }

        const res = await axios.get("/get-history", {
          headers: {
            Authorization: `Bearer ${response.data.data.Token}`,
          },
        });

        if (res.data.status == "success" && res.data.message == "Record") {
          // Store.dispatch(setSessionFild(LOGIN,result))
          localStorage.removeItem("Register-Start");
          localStorage.removeItem("StaticCartData");
          setLoading(false);
          Navigate("/Perfil/History");
        } else if (
          res.data.status == "success" &&
          res.data.message == "No Record"
        ) {
          if (localStorage.getItem("StaticCartData")) {
            Navigate("/ContinuePayment");
          } else {
            Navigate("/Modelos");
          }
        }
      }
    } catch (error) {
      // console.log("🚀 error", error)
      if (error) {
        toast.error(`${error.response.data.message}`);
        setLoading(false);
      }
    }
  };

  return (
    <>
      <Helmet>
        <title>Kia EV | Inicia Sesión | Reserva en línea</title>
        <link rel="canonical" href="https://kiaevpr.com/#/Iniciar-Sesion" />
        <meta
          name="description"
          content="Inicia sesión en tu cuenta para ver el estatus de la reservación de tu modelo Kia EV, el nuevo modelo totalmente eléctrico."
        />
      </Helmet>
      <Wrapper>
        <Card head={"Iniciar sesión"}>
          <span className="form-error-head"> {IsError} </span>
          <div className="p-2"></div>
          <Formik
            initialValues={initialValues}
            validationSchema={LoginSchema}
            onSubmit={handleSubmit}
          >
            {(props) => (
              <Form className="d-flex flex-column gap-3">
                <div className="d-flex flex-column w-100">
                  <div className="input--">
                    <span>Correo electrónico</span>
                    <Field
                      type="email"
                      name="Email"
                      placeholder="Ingresa tu correo electrónico"
                      className="input-field"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.Email}
                    />
                    <div className="p-1"></div>
                  </div>
                  {props.errors.Email && props.touched.Email ? (
                    <p className="form-error"> {props.errors.Email} </p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="d-flex flex-column">
                  <div className="input--">
                    <span>Contraseña</span>
                    <Field
                      type="password"
                      name="Password"
                      placeholder={"Ingresa tu contraseña"}
                      className="input-field"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      value={props.values.Password}
                    />
                    <div className="p-1"></div>
                  </div>
                  {props.errors.Password && props.touched.Password ? (
                    <p className="form-error"> {props.errors.Password} </p>
                  ) : (
                    ""
                  )}
                </div>

                <div className="d-flex justify-content-center pt-3 pb-3">
                  <button type="submit" className="concesionario-span2">
                    Iniciar sesión
                  </button>
                </div>

                <div className="links-login">
                  <Link
                    to={
                      localStorage.getItem("StaticCartData")
                        ? "/Registrate"
                        : "/Intro"
                    }
                  >
                    <Button variant={"green"}>
                      {"  ¿No tienes cuenta? Regístrate."}
                    </Button>
                    {/* <span className="concesionario-span"></span> */}
                  </Link>
                  <Link to="/Olvidaste-contrasena">
                    <span className="concesionario-span">
                      ¿Olvidaste tu contraseña?
                    </span>
                  </Link>
                </div>

                <div className="d-flex text-center justify-content-center align-items-center w-100 white eresdiv">
                  <Link to="/RegistrateDealer">
                    <span className="concesionario-span">
                      ¿Eres un concesionario? Realiza la reserva aquí.
                    </span>
                  </Link>
                </div>
              </Form>
            )}
          </Formik>
        </Card>
        {!Loading && <div></div>}
        {Loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </Wrapper>
    </>
  );
};

export default Login;

import React, { useEffect, useRef, useState } from "react";
import { ContratoSchema } from "../../Schemas";
import "./Contrato.css";
import { useFormik } from "formik";
import axios from "../../API";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { Row } from "react-bootstrap";
import visalogo from "../../Assets/Images/visalogo.png";
import masterlogo from "../../Assets/Images/masterlogo.png";
import { useNavigate } from "react-router-dom";
import { Modal } from "react-bootstrap";
// import { Store } from "../../Redux/MainStore";
// import { setSessionFild } from "../../Redux/SessionAction";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const Contrato = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  function Alertmodel(props) {
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ zIndex: 1000000000 }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="white text-center">
          <h4 className="pt-2 pb-2">Alerta!</h4>
          <p className="Modal-text">
            a tarjeta ingresada no puede ser utilizada, favor de Añadir una
            nueva tarjeta para realizar su depósito.
          </p>
          <div className="p-3"></div>
        </Modal.Body>
      </Modal>
    );
  }

  const [modalShow, setModalShow] = useState(false);
  const [desible, setdisable] = useState(false);
  const Navigate = useNavigate();
  // Get User Data
  const UserData = JSON.parse(localStorage.getItem("user-info"));

  // Payment State
  const [PaymentShow, setPaymentShow] = useState(false);
  const [carddata, setcard] = useState([]);
  const [cardIndex, setcardIndex] = useState(0);

  // Loader useState Variable
  const [Loading, setLoading] = useState(true);

  // Store Api Data in useState Variable
  const [CartData, setCartData] = useState([]);

  // Api Error Data useState Variable
  const [ApiError, setApiError] = useState("");

  // Api User Profile Data useState Variable
  const [UserProfileData, setUserProfileData] = useState({});

  const [show, setshow] = useState(false);
  const scrollRef = useRef();
  const initialValues = {
    checkbox: false,
  };

  // useEffect(() => {
  //   const handleScroll = () => {
  //     const scrollTop = scrollref.current.scrollTop || 0;
  //     const clientHeight = scrollref.current.clientHeight || 0;
  //     const scrollHeight = scrollref.current.scrollHeight || 0;
  //     const isAtBottom = scrollTop + clientHeight === scrollHeight || 0;
  //     setshow(isAtBottom);
  //   };
  //   if (scrollref?.current) {
  //     scrollref.current.addEventListener("scroll", handleScroll);
  //   }
  //   return () => {
  //     if (scrollref?.current) {
  //       scrollref.current.removeEventListener("scroll", handleScroll);
  //     }
  //   };
  // }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = scrollRef.current.scrollTop || 0;
      const clientHeight = scrollRef.current.clientHeight || 0;
      const scrollHeight = scrollRef.current.scrollHeight || 0;
      const isAtBottom = Math.abs(scrollTop + clientHeight - scrollHeight) <= 1;
      setshow(isAtBottom);
    };
    if (scrollRef.current) {
      scrollRef.current.addEventListener("scroll", handleScroll);
    }

    return () => {
      if (scrollRef.current) {
        scrollRef.current.removeEventListener("scroll", handleScroll);
      }
    };
  }, []);

  const onSubmit = (values) => {
    setPaymentShow(true);
    setLoading(true);
    getcard();
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: ContratoSchema,
      onSubmit,
    });

  const getCartData = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/get-cart", {
        headers: {
          Authorization: `Bearer ${UserData?.Token}`,
        },
      });
      // console.log("🚀 ~ file: ContinuePayment.js:36 ~ getCartData ~ res", res);
      if (res.data.status === "success" && res.data.message == "Record") {
        setCartData(res.data);
        setLoading(false);
      } else {
        setLoading(false);
      }
    } catch (error) {
      setLoading(false);
      setApiError(error);
    }
  };

  const getcard = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-card-list", {
        headers: {
          Authorization: `Bearer ${UserData.Token}`,
        },
      });
      if (response.data.status == "success") {
        setcard(response.data.data);
        setLoading(false);
        // console.log("Get card responce", response);
        if (response.data.message === "No Record Found") {
          setModalShow(true);
          setdisable(true);
        }
      } else {
        setcard(null);
        setLoading(false);
      }
    } catch (error) {
      // console.log(error);
    }
    // console.log(cardIndex);
  };

  const handlePayment = async (e) => {
    // e.preventDefault()
    setLoading(true);
    try {
      const res = await axios.post(
        "/pay-order",
        {
          order_id: CartData.data.order_id,
          PaymentMethod: carddata[cardIndex].CardId,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData?.Token}`,
          },
        }
      );
      // console.log("🚀 Res Payment......", res);
      //  Store.dispatch(setSessionFild(USERS_DATAS,res))
      if (
        res.data.status == "success" &&
        res.data.message == "Payment successfully."
      ) {
        setLoading(false);
        Navigate("/Order-Detail");
      } else {
        setLoading(false);
        // console.log("Error.......", res);
      }
    } catch (error) {
      setLoading(false);
      // console.log("Error....", error);
      if (error.response.data.message === "something wrong.") {
        setModalShow(true);
      }
    }
  };

  // console.log(carddata)

  useEffect(() => {
    if (!localStorage.getItem("user-info")) {
      Navigate("/Iniciar-Sesion");
    }
    getCartData();
  }, []);

  return (
    <>
      {!PaymentShow ? (
        <div className="wraperdiv2">
          <form className="carddivmainn" onSubmit={handleSubmit}>
            <div className="acueh1">
              <h1>ACUERDO PARA RESERVAR VEHÍCULO</h1>
              {/* <span onClick={onClick}>{ico}</span> */}
            </div>
            <div
            // onScroll={onScroll}
            // className="pragrapscontrato"
            >
              <div
                className="contratocontent"
                id="scrollcontent"
                style={{ height: "320px", overflowY: "scroll" }}
                ref={scrollRef}
              >
                <p>
                  Mediante la aceptación de este acuerdo, demostrada por la
                  activación del botón encontrado al final de esta página,
                  acepto lo siguiente:
                </p>
                <p>
                  1. En este documento usuario reserva el vehículo de motor tal
                  como fue escogido por el usuario. Este documento no es un
                  contrato de venta de un vehículo de motor.
                </p>
                <p>
                  2. Motorambar, Inc., (en adelante “Kia Puerto Rico”), no
                  reserva o vende los vehículos de motor. Kia Puerto Rico no es
                  responsable por la reservación hecha por usuario.
                </p>
                <p>
                  3. El usuario libera de toda responsabilidad a Kia Puerto Rico
                  de cualquier situación relacionada al contrato de venta, venta
                  a plazos, arrendamiento financiero o cualquier otro negocio
                  jurídico mediante el cual el usuario adquiera posesión o
                  dominio del vehículo que aquí reservado.
                </p>
                <p>
                  4. El usuario ha solicitado reservar el vehículo de motor con
                  las características escogidas por el usuario y con el precio
                  MSRP como precio neto del auto acordado, que no incluye gastos
                  de registración o tablilla los cuales se trabajan directamente
                  con el concesionario. El precio asegurado por esta reserva es
                  el precio de venta del auto. Negocio con concesionario puede
                  variar dependiendo negocio final, ejemplos, accesorios
                  añadidos, trade in, financiamiento, etc.
                </p>
                <p>
                  5. Con la intención de reservar el vehículo descrito
                  anteriormente, el usuario se compromete a pagar la cuantía de
                  Quinientos dólares, ($500.00). Al momento de otorgar el
                  contrato de venta, venta a plazos, arrendamiento financiero o
                  cualquier otro negocio jurídico mediante el cual usuario
                  adquiere posesión o dominio del vehículo, esta cuantía de
                  dinero se restará del precio del vehículo que surge de la
                  reservación.
                </p>
                <p>
                  6. El usuario entiende y acepta que el vehículo objeto de
                  reservación se ordenará directamente del manufacturero en
                  Korea, por lo que puede transcurrir un término de tiempo de
                  hasta noventa (90) días para que el vehículo llegue a Puerto
                  Rico.
                </p>
                <p>
                  7. Del vehículo tardar más de noventa (90) días en llegar a
                  Puerto Rico, el usuario tendrá la opción de solicitar extender
                  esta reservación por un término de 30 días adicionales o dar
                  el mismo por terminado. De dar el contrato por terminado el
                  usuario libera de toda responsabilidad legal, relacionada a la
                  otorgación y cancelación, de esta reservación a Kia Puerto
                  Rico y se devuelven los $500 dólares de depósito.
                </p>
                <p>
                  8. En el momento que el vehículo sea asignado, se le informará
                  a Usuario de esto mediante el correo electrónico que proveyó y
                  comenzará con el concesionario el proceso de adquirir el
                  vehículo.
                </p>
                <p>
                  9. Kia Puerto Rico se reserva el derecho de cancelar esta
                  reservación por cualquier razón. Usuario libera de toda
                  responsabilidad contractual o extracontractual a Kia Puerto
                  Rico por esta reservación.
                </p>
                <p>
                  10. De Kia Puerto Rico cancelar esta reservación el usuario
                  recibirá cualquier pago hecho al reservar el vehículo.
                </p>
                <p>
                  11. El usuario acepta y entiende que el precio del vehículo
                  que surge en esta reservación no incluye ningún accesorio a
                  ser ordenado directamente del concesionario. Cualquier
                  accesorio que se ordene del concesionario debe ser objeto de
                  negociación directa entre el concesionario y el usuario y no
                  forma parte de esta reservación.
                </p>
                <p>
                  12. El usuario tendrá un término de tiempo no mayor quince
                  (15) días, partir del momento en que el vehículo es recibido
                  por el concesionario para llevar a cabo cualquier trámite
                  necesario adquirir el vehículo.
                </p>
                <p>
                  13. Esta reservación es un contrato que se rige por las leyes
                  del Estado Libre Asociado de Puerto Rico.
                </p>
                <p>
                  Al apretar el botón, Usuario, acepta los términos de este
                  contrato.
                </p>
              </div>
            </div>
            <div className="checkboxcontrato">
              <div className="maincheckbox">
                <label className="" htmlFor="acepto">
                  {/* <Checkbox name="termCheckbox" id={"html2"} /> */}
                  <div className="form-group">
                    <input
                      type="checkbox"
                      id={"acepto"}
                      name="checkbox"
                      value={values.checkbox}
                      onChange={handleChange}
                      onBlur={handleBlur}
                    />
                    {/* <label htmlFor="id"></label> */}
                  </div>
                  <p>Acepto el Acuerdo para reservar el vehículo.</p>
                </label>
                {errors.checkbox && touched.checkbox ? (
                  <p className="form-error">{errors.checkbox}</p>
                ) : null}
              </div>
              <div className="contratobtn">
                <button
                  disabled={show == false ? "disable" : false}
                  type={"submit"}
                >
                  Continuar con el pago
                </button>
              </div>
            </div>
          </form>
        </div>
      ) : (
        <div className="SelectCard-parent-div">
          {Loading && (
            <div>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
                // onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          )}
          {!Loading && (
            <>
              <form
                // onSubmit={handlePayment}
                className="select-card-form-parent-div"
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                  flexDirection: "column",
                  gap: "2rem",
                }}
              >
                {carddata !== null ? (
                  <div style={{ color: "white" }} className="getcarddata">
                    <>
                      {carddata?.map((curelem, index) => {
                        return (
                          <div
                            lg={12}
                            key={index}
                            onClick={() => {
                              setcardIndex(index);
                            }}
                            className="d-flex justify-content-center pt-5 gap-4"
                          >
                            <div
                              lg={6}
                              style={
                                cardIndex == index
                                  ? {
                                      background: "#9f9f9f",
                                      padding: "10px 3rem",
                                      borderRadius: "10px",
                                    }
                                  : { padding: "10px 3rem" }
                              }
                            >
                              <Row>
                                <div className="d-flex gap-3">
                                  <div className="brandlogo">
                                    <img
                                      src={
                                        curelem.CardBrand === "Visa"
                                          ? visalogo
                                          : curelem.CardBrand === "MasterCard"
                                          ? masterlogo
                                          : null
                                      }
                                      alt=""
                                    />
                                  </div>
                                  <span className="ProfileHead">
                                    Ending {curelem.CardNumber}
                                  </span>
                                </div>
                              </Row>
                              <Row className="pt-1">
                                <span className="HeadText">
                                  {curelem.CardName}
                                </span>
                              </Row>
                            </div>
                          </div>
                        );
                      })}
                    </>
                  </div>
                ) : (
                  <></>
                )}
                <div
                  style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: "20px",
                    paddingTop: "10px",
                  }}
                >
                  <button
                    className="PaymentBtn"
                    type="submit"
                    onClick={() => handlePayment()}
                    // disabled={desible ? "disable" : ""}
                    disabled={carddata == null ? "disable" : ""}
                  >
                    Pagar
                  </button>
                  <button
                    className="PaymentBtn-add"
                    type="button"
                    onClick={() => Navigate("/AddnewCard")}
                  >
                    Añadir nueva tarjeta
                  </button>
                </div>
              </form>
              <Alertmodel show={modalShow} onHide={() => setModalShow(false)} />
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Contrato;

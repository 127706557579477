import React, { useState, useEffect } from "react";
import Wrapper from "../../Components/UI/Wrapper";
import Card from "../../Components/UI/Card";
import Input from "../../Components/UI/Input";
import Button from "../../Components/UI/Button";
import { useFormik } from "formik";
import { PaymentSchema } from "../../Schemas";
import { Form } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "../../API";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const CARD_OPTIONS = {
  iconStyle: "solid",
  style: {
    base: {
      iconColor: "#c4f0ff",
      color: "#000000",
      fontWeight: 500,
      fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
      fontSize: "16px",
      fontSmoothing: "antialiased",
      ":-webkit-autofill": {
        color: "#fce883",
      },
      "::placeholder": {
        color: "#000000",
      },
    },
    invalid: {
      iconColor: "#D40000",
      color: "#D40000",
    },
  },
};

const initialValues = {
  CardName: "",
};

const AddNewCard = () => {
  const Token2 = JSON.parse(localStorage.getItem("user-info"));
  const UserData = JSON.parse(localStorage.getItem("user-info"));
  const [IsError, setIsError] = useState("");
  const [Loading, setLoading] = useState(false);

  const Navigate = useNavigate();

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  const stripe = useStripe();
  const elements = useElements();

  const onSubmit = async () => {
    setLoading(true);
    // e.preventDefault();
    // console.log("token...",Token.Token)

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card, {
      name: values.CardName,
    });
    if (result.error) {
      setLoading(false);
      setIsError(result.error.message);
      // console.log("[error]", result.error);
    } else {
      // console.log("[Result]", result);
      // setLoading(false)
      try {
        const response = await axios.post(
          "/add-card",
          {
            CardToken: result.token.id,
          },
          {
            headers: {
              Authorization: `Bearer ${UserData.Token}`,
            },
          }
        );
        if (response) {
          // console.log(response);
          setLoading(false);
          Navigate("/Contrato");
        }
      } catch (error) {
        setLoading(false);
        setIsError(error);
        // console.log(error);
      }
    }

    // if (!result.error) {
    //   try {
    //     const response = await axios.post(
    //       "/register-step-4",
    //       {
    //         RegsterId: Token.reg_id !== null ? Token.reg_id : Token2.Token,
    //         CardToken: result.token.id,
    //       },
    //       {
    //         headers: {
    //           "content-type": "application/json",
    //         },
    //       }
    //     );
    //     // console.log(result.token.id)
    //     // console.log(Token.reg_id)
    //     if (response) {
    //       console.log(response);
    //       setLoading(false);
    //     }
    //     if (response.data.message == "Account Registration Successfully") {
    //       // console.log(response);
    //       localStorage.setItem("user-info", JSON.stringify(response.data.data));
    //       localStorage.removeItem("Register-token");
    //       Navigate("/ContinuePayment");
    //     }
    //   } catch (error) {
    //     // console.log("sassa", response);
    //     console.log("Error", error);
    //     setLoading(false);
    //     setIsError(error);
    //   }
    // }
  };
  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: PaymentSchema,
      onSubmit,
    });

  // console.log(IsError);

  return (
    <div>
      <Wrapper>
        <Card head={"Completa tu reserva"}>
          <Form onSubmit={handleSubmit} className="mainform6">
            <div className="inputs6">
              <div className="cardelemnt">
                <Input
                  type={"text"}
                  text={"Nombre en la tarjeta"}
                  name="CardName"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.CardName}
                />
              </div>
              {errors.CardName && touched.CardName ? (
                <p className="form-error2"> {errors.CardName} </p>
              ) : null}
              <div className="cardmainelementdiv">
                <div className="cardelemnt">
                  <CardElement
                    options={{ CARD_OPTIONS, hidePostalCode: true }}
                    name="CardNumber"
                  />
                </div>
                <p className="form-error2">{IsError ? IsError : ""}</p>
              </div>
            </div>
            <div className="">
              <Button variant="red" type={"submit"}>
                {Loading ? "Loading...." : "Continuar"}
              </Button>
            </div>
          </Form>
        </Card>
        {!Loading && <div></div>}
        {Loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </Wrapper>
    </div>
  );
};

export default AddNewCard;

import { Link } from "react-router-dom";
import React, { useContext, useEffect, useRef } from "react";
import "./GalleryModal.css";
import blackcarev6 from "../../../Assets/EV6 GT-Line (E-AWD)/CV_GT_Line_Front_Aurora_Black Pearl_ABP.png";
import ev9car from "../../../Assets/EV9/1111 1 (1).png";
import { GlobalContext } from "../../../Context/GlobalContext";
import { Icons } from "../../../Constant/Icons";

const GalleryModal = ({ steps, setsteps }) => {
  const { setgalleryModal } = useContext(GlobalContext);
  const modelref = useRef();

  useEffect(() => {
    let handler = (event) => {
      if (modelref.current) {
        if (!modelref.current.contains(event.target)) {
          setgalleryModal(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  }, []);

  return (
    <>
      <div ref={modelref} className="main_dox2">
        <span>{Icons.ratangle}</span>
        <div className="dropdown_main2">
          <div className="dropdown_sub2">
            <div className="Main_model2">
              <Link
                className="link_box2"
                onClick={() => {
                  setgalleryModal(false);
                }}
                to="/Galeria"
              >
                <div style={{ width: "120px" }}>
                  <img className="carimg_main" src={blackcarev6} alt="" />
                </div>
                <h2 className="headingtext">EV6</h2>
              </Link>
              <Link
                className="link_box2"
                onClick={() => {
                  setgalleryModal(false);
                }}
                to="/Galeria/EV9"
              >
                <div style={{ width: "120px" }}>
                  <img className="carimg_main ev9car" src={ev9car} alt="" />
                </div>
                <h2 className="headingtext2">EV9</h2>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GalleryModal;

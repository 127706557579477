// import React, { useState } from "react";
// import "../../Css/Car/ConfirmOrder.css";
// import KiaLogo from "../../Assets/Images/KiaLogo.png";
// import { useLocation, useNavigate } from "react-router-dom";
// import img from "../../Assets/Images/greenCar.png";
// import Button from "../../Components/UI/Button";
// import Specification from "../../Components/CarDetail/Specification";
// import { Modal } from "react-bootstrap";

// const SecondConfirmOrder = () => {
//   const Navigate = useNavigate();
//   const [Specs, setSpecs] = useState(false);

//   const [FirstmodalShow, setFirstmodalShow] = useState(false);
//   const [SecondmodalShow, setSecondmodalShow] = useState(false);
//   const [ThirdmodalShow, setThirdmodalShow] = useState(false);

//   let route = useLocation();
//   const HistoryData = route.state.HistoryData;

//   console.log(HistoryData);

//   function FirstModal(props) {
//     return (
//       <Modal
//         {...props}
//         size="md"
//         aria-labelledby="contained-modal-title-vcenter"
//         backdrop="static"
//         centered
//         style={{ zIndex: "10000" }}
//       >
//         <Modal.Header
//         // closeButton
//         ></Modal.Header>
//         <Modal.Body className="white text-center">
//           <div className="Dropdown-Car-info-body-Head">
//             {/* <i> */}
//             ¿Deseas continuar?
//             {/* </i> */}
//           </div>
//           <p className="Modal-text d-flex flex-column gap-4">
//             <div>
//               Favor de confirmar si desea continuar con el proceso o cancelarlo.
//             </div>
//             <div>
//               Si desea cancelar en este momento se le hará la devolución
//               completa del depósito dado ($500).
//             </div>
//             <div className="modal-btns-div w-100 gap-3">
//               <Button
//                 variant="white"
//                 onClick={() => {
//                   setFirstmodalShow(false);
//                   setSecondmodalShow(true);
//                 }}
//               >
//                 NO DESEO CONTINUAR
//               </Button>
//               <Button variant="red">Sí, DESEO CONTINUAR</Button>
//             </div>
//           </p>
//           <div className="p-1"></div>
//         </Modal.Body>
//       </Modal>
//     );
//   }

//   function SecondModal(props) {
//     return (
//       <Modal
//         {...props}
//         size="md"
//         aria-labelledby="contained-modal-title-vcenter"
//         backdrop="static"
//         centered
//         style={{ zIndex: "10000" }}
//       >
//         <Modal.Header
//         // closeButton
//         ></Modal.Header>
//         <Modal.Body className="white text-center">
//           <div className="Dropdown-Car-info-body-Head">
//             {/* <i> */}
//             Cancelar pedido
//             {/* </i> */}
//           </div>
//           <p className="Modal-text d-flex flex-column gap-4">
//             <div>
//               ¿Estás seguro que deseas cancelar? Se te hará la devolución del
//               depósito dado ($500).
//             </div>
//             <div className="modal-btns-div w-100 gap-3">
//               <Button
//                 variant="white"
//                 onClick={() => {
//                   setSecondmodalShow(false);
//                 }}
//               >
//                 NO DESEO CANCELAR
//               </Button>
//               <Button variant="red">SÍ, DESEO CANCELAR</Button>
//             </div>
//           </p>
//           <div className="p-1"></div>
//         </Modal.Body>
//       </Modal>
//     );
//   }

//   function ThirdModal(props) {
//     return (
//       <Modal
//         {...props}
//         size="md"
//         aria-labelledby="contained-modal-title-vcenter"
//         backdrop="static"
//         centered
//         style={{ zIndex: "10000" }}
//       >
//         <Modal.Header
//         // closeButton
//         ></Modal.Header>
//         <Modal.Body className="white text-center">
//           <div className="Dropdown-Car-info-body-Head">
//             {/* <i> */}
//             ¡Lo lamentamos!
//             {/* </i> */}
//           </div>
//           <p className="Modal-text d-flex flex-column gap-4">
//             <div>
//               Lamentamos que no deseara continuar con el proceso. En los
//               próximos días verás reflejado en tu cuenta la devolución del
//               depósito dado ($500).
//             </div>
//             <Button
//               variant="red"
//               onClick={() => {
//                 setThirdmodalShow(false);
//                 Navigate("/Modelos");
//               }}
//             >
//               OK
//             </Button>
//           </p>
//           <div className="p-1"></div>
//         </Modal.Body>
//       </Modal>
//     );
//   }

//   return (
//     <>
//       <FirstModal
//         show={FirstmodalShow}
//         onHide={() => setFirstmodalShow(false)}
//       />
//       <SecondModal
//         show={SecondmodalShow}
//         onHide={() => setSecondmodalShow(false)}
//       />
//       <ThirdModal
//         show={ThirdmodalShow}
//         onHide={() => setThirdmodalShow(false)}
//       />
//       {!Specs ? (
//         <div className="ConfirmOrder-Main-parent-div">
//           <div className="ConfirmOrder-kia-logo-div pt-5 pb-5">
//             <img
//               src={KiaLogo}
//               style={{ cursor: "pointer" }}
//               alt="Logo"
//               onClick={() => Navigate("/Inicio")}
//             />
//           </div>
//           <div className="ConfirmOrder-Body-parent-div">
//             <div className="ConfirmOrder-Body-header-div">
//               Confirmación de la orden
//             </div>
//             <div className="ConfirmOrder-Body-content-div">
//               <div className="ConfirmOrder-Body-image-div d-flex justify-content-center align-items-center w-100">
//                 <img src={img} width="90%" />
//               </div>
//               <div className="ConfirmOrder-Body-content">
//                 <div className="Confirm-order-Specs-div">
//                   <span>Número de transacción: GHJ89900</span>
//                   <span>Número de orden: 1001</span>
//                 </div>
//                 <div className="ModelName-Price-div">
//                   <span>Model EV1 2023</span>
//                 </div>
//                 <div className="Confirm-order-Specs-div">
//                   <span>Model: eV1</span>
//                   <span>Color exterior: Yatch Blue</span>
//                   <span>Color interior: Deep Forest Green</span>
//                   <span>Aros: Gris oscuro</span>
//                 </div>
//                 <div className="additionalSpecs">
//                   <button
//                     className="additionalSpecs-btn"
//                     onClick={() => setSpecs(true)}
//                   >
//                     Especificaciones adicionales
//                   </button>
//                 </div>
//                 <div className="w-100">
//                   <Button
//                     variant={"red"}
//                     onClick={() => setFirstmodalShow(true)}
//                   >
//                     Cancelar pedido
//                   </Button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       ) : (
//         <Specification onClick={() => setSpecs(false)} />
//       )}
//     </>
//   );
// };

// export default SecondConfirmOrder;

import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import WrapperSecond from "../../Components/UI/Wrapper";
import "../../Css/Car/Detail.css";
import img from "../../Assets/Images/greenCar.png";
import Button from "../../Components/UI/Button";
// import CancleOrderAlert from '../Alerts/CancleOrder'
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import KiaLogo from "../../Assets/Images/KiaLogo.png";
import axios from "../../API";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import img1 from "../../Assets/EV6 EX RWD/CV_BL_Front_Yacht_Blue_DU3.png";
import img2 from "../../Assets/EV6 EX RWD/CV_BL_Front_Gravity_Blue_B4U.png";
import img3 from "../../Assets/EV6 EX RWD/CV_BL_Front_Moonscape_Matte_KLM.png";
import img4 from "../../Assets/EV6 EX RWD/CV_BL_Front_Steel_Gray_KLG.png";
import img5 from "../../Assets/EV6 EX RWD/CV_BL_Front_Snow_White_Pearl_SWP.png";
import img6 from "../../Assets/EV6 EX RWD/CV_BL_Front_Glacier_GLB.png";
import img7 from "../../Assets/EV6 EX RWD/CV_BL_Front_Runway_Red_CR5.png";
import img8 from "../../Assets/EV6 EX RWD/CV_BL_Front_Aurora_Black Pearl_ABP.png";
import img9 from "../../Assets/EV6 EX RWD/CV_BL_Front_Urban_Yellow_UBY.png";
import img10 from "../../Assets/EV6 EX RWD/CV_BL_Front_Interstella_Gray_AGT.png";
import img11 from "../../Assets/GT LINE/CV_GT_Line_Front_Yacht_Blue_DU3-removebg-preview.png";
import img13 from "../../Assets/GT LINE/CV_GT_Line_Front_Moonscape_Matte_KLM-removebg-preview.png";
import img15 from "../../Assets/GT LINE/CV_GT_Line_Front_Snow_White_Pearl_SWP-removebg-preview.png";
import img17 from "../../Assets/GT LINE/CV_GT_Line_Front_Runway_Red_CR5-removebg-preview.png";
import img18 from "../../Assets/GT LINE/CV_GT_Line_Front_Aurora_Black_Pearl_ABP-removebg-preview.png";
import Specification from "../../Components/CarDetail/Specification";
import Specificationtwo from "../../Components/CarDetail/Specificationtwo";
import Specificationthree from "../../Components/CarDetail/Specificationthree";

// import NewImg1 from "../../Assets/Images/EV6_GT_Line_RWD/Yacht_Blue";
// import NewImg2 from "../../Assets/Images/EV6_GT_Line_RWD/Runway_Red";
// import NewImg3 from "../../Assets/Images/EV6_GT_Line_RWD/Aurora_Black Pearl";
// import NewImg4 from "../../Assets/Images/EV6_GT_Line_RWD/Moonscape_Matte";
// import NewImg5 from "../../Assets/Images/EV6_GT_Line_RWD/Snow_White_Pearl";

import NewImg1 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Yacht_Blue_DU3.png";
import NewImg2 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Runway_Red_CR5.png";
import NewImg3 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Aurora_Black Pearl_ABP.png";
import NewImg4 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Moonscape_Matte_KLM.png";
import NewImg5 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Snow_White_Pearl_SWP.png";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { toast } from "react-hot-toast";
import { imagesdata } from "../../Components/Data/Data";
import EV9Specification from "../../Components/EV9Detail/EV9Specification";

const SecondConfirmOrder = () => {
  const Navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  const UserData = JSON.parse(localStorage.getItem("user-info"));

  const [disable, setdisable] = useState(false);
  const [Specs, setshow] = useState(false);

  const [Loading, setLoading] = useState(true);

  const [FirstmodalShow, setFirstmodalShow] = useState(false);
  const [$250ModalShow, set$250ModalShow] = useState(false);
  const [SecondmodalShow, setSecondmodalShow] = useState(false);
  const [ThirdmodalShow, setThirdmodalShow] = useState(false);

  const [OrderHistory, setOrderHistory] = useState({});

  const [Ordertrack, setOrdertrack] = useState({});

  const getOrderHistory = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/get-history", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${UserData?.Token}`,
        },
      });
      // console.log("OrderHistory...............", res);
      if (res.data.status == "success") {
        setOrderHistory(res.data);
        setLoading(false);
        if (res.data.message === "No Record") {
          Navigate("/Modelos");
        } else {
          try {
            setLoading(true);
            const response = await axios.post(
              "/get-order-status",
              {
                track_id: res.data.data.track_id,
              },
              {
                headers: {
                  "content-type": "application/json",
                  Authorization: `Bearer ${UserData?.Token}`,
                },
              }
            );
            if (response.data.status == "success") {
              setOrdertrack(response.data.data);
              setLoading(false);
              if (response.data.data.OTW_Status == "TRUE") {
                setTimeout(() => {
                  set$250ModalShow(true);
                }, 1000);
              } else {
                setTimeout(() => {
                  setFirstmodalShow(true);
                }, 1000);
              }
            } else {
              setLoading(false);
              Navigate("/Iniciar-Sesion");
              // console.log("error.....", response);
            }
          } catch (error) {
            // console.log(error, "errorrr");
          }
        }
      } else {
        // setLoading(false);
        // console.log("res Error....", res);
      }
    } catch (error) {
      // setLoading(false);
      if (error.response.data.message == "Invalid Token.") {
        localStorage.removeItem("user-info");
        Navigate("/");
      } else {
        Navigate("/");
      }
      //   console.log("Catched Error....", error);
    }
  };

  useEffect(() => {
    if (UserData) {
      getOrderHistory();
    } else {
      Navigate("/Iniciar-Sesion");
    }
  }, []);

  const colorcode = imagesdata.filter(
    (item) => item.colorcode === OrderHistory?.data?.Ext_Color_Code
  );
  let images;
  if (OrderHistory.data) {
    if (OrderHistory.data.ModelType === "EV6") {
      if (OrderHistory.data.model == "CVE1" || "CVE2") {
        if (OrderHistory.data.Ext_Color_Code == "#1A30AD") {
          images = img1;
        } else if (OrderHistory.data.Ext_Color_Code == "#34425F") {
          images = img2;
        } else if (OrderHistory.data.Ext_Color_Code == "#666666") {
          images = img3;
        } else if (OrderHistory.data.Ext_Color_Code == "#9C9EA0") {
          images = img4;
        } else if (OrderHistory.data.Ext_Color_Code == "#C6C4B7") {
          images = img5;
        } else if (OrderHistory.data.Ext_Color_Code == "#F9F9F9") {
          images = img6;
        } else if (OrderHistory.data.Ext_Color_Code == "#933531") {
          images = img7;
        } else if (OrderHistory.data.Ext_Color_Code == "#1B1B1B") {
          images = img8;
        } else if (OrderHistory.data.Ext_Color_Code == "#CD8700") {
          images = img9;
        } else if (OrderHistory.data.Ext_Color_Code == "#4F5354") {
          images = img10;
        }
      } else if (OrderHistory.data.model == "GT-LINE RWD") {
        if (OrderHistory.data.Ext_Color_Code == "#1A30AD") {
          images = NewImg1;
        } else if (OrderHistory.data.Ext_Color_Code == "#933531") {
          images = NewImg2;
        } else if (OrderHistory.data.Ext_Color_Code == "#1B1B1B") {
          images = NewImg3;
        } else if (OrderHistory.data.Ext_Color_Code == "#666666") {
          images = NewImg4;
        } else if (OrderHistory.data.Ext_Color_Code == "#C6C4B7") {
          images = NewImg5;
        }
      } else if (OrderHistory.data.model == "EV6 GT-LINE (E-AWD)") {
        if (OrderHistory.data.Ext_Color_Code == "#1A30AD") {
          images = img11;
        } else if (OrderHistory.data.Ext_Color_Code == "#933531") {
          images = img17;
        } else if (OrderHistory.data.Ext_Color_Code == "#1B1B1B") {
          images = img18;
        } else if (OrderHistory.data.Ext_Color_Code == "#666666") {
          images = img13;
        } else if (OrderHistory.data.Ext_Color_Code == "#C6C4B7") {
          images = img15;
        }
      }
    } else if (OrderHistory.data.ModelType === "EV9") {
      if (OrderHistory.data.Ext_Color_Code === colorcode[0].colorcode) {
        images = colorcode[0]?.images[5];
      }
    }
  }

  var nf = new Intl.NumberFormat();

  const navigate = useNavigate();
  const handlenavi = () => {
    // console.log(OrderHistory.data);
    if (OrderHistory.data.model == "EV6 EX RWD") {
      setshow(true);
    } else if (OrderHistory.data.model == "GT-LINE RWD") {
      setshow(true);
    } else if (OrderHistory.data.model == "EV6 GT-LINE (E-AWD)") {
      setshow(true);
    } else if (OrderHistory.data.model == "EV9 GT-LINE") {
      setshow(true);
    }
  };

  const CancleOrder = async () => {
    setSecondmodalShow(false);
    set$250ModalShow(false);
    setLoading(true);
    try {
      const res = await axios.post(
        "/cancel-order",
        {
          TrackId: OrderHistory.data.track_id,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData.Token}`,
          },
        }
      );
      if (res) {
        // console.log("...............", res);
        if (res.data.status == "success") {
          // navigate("/Modelos");
          setdisable(true);
          toast.success("pedido cancelado con éxito");
          setLoading(false);
          if (Ordertrack.OTW_Status == "FALSE") {
            setThirdmodalShow(true);
          } else if (Ordertrack.OTW_Status == "TRUE") {
            navigate("/Perfil/CanceledOrders");
          }
        } else {
          setLoading(false);
          if (Ordertrack.OTW_Status == "FALSE") {
            setThirdmodalShow(true);
          } else if (Ordertrack.OTW_Status == "TRUE") {
            navigate("/Perfil/CanceledOrders");
          }
          // console.log("res Error....", res);
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log("Catched Error....", error);
    }
  };

  function FirstModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ zIndex: "10000" }}
      >
        <Modal.Header
        // closeButton
        ></Modal.Header>
        <Modal.Body className="white text-center">
          <div className="Dropdown-Car-info-body-Head">
            {/* <i> */}
            ¿Deseas continuar?
            {/* </i> */}
          </div>
          <p className="Modal-text d-flex flex-column gap-4">
            <div>
              Favor de confirmar si deseas continuar con el proceso o
              cancelarlo.
            </div>
            <div>
              Sí deseas cancelar en este momento se te hará la devolución
              completa del depósito dado ($500).
            </div>

            <div className="modal-btns-div w-100 gap-3">
              <Button
                variant="white"
                onClick={() => {
                  setFirstmodalShow(false);
                  setSecondmodalShow(true);
                }}
              >
                NO DESEO CONTINUAR
              </Button>
              <Button
                variant="red"
                onClick={async () => {
                  //   Navigate("/Perfil/History", {
                  //     state: true,
                  //   });
                  setFirstmodalShow(false);
                  try {
                    setLoading(true);
                    const res = await axios.post(
                      "/change-otw-order-status",
                      {
                        TrackId: OrderHistory.data.track_id,
                      },
                      {
                        headers: {
                          "content-type": "application/json",
                          Authorization: `Bearer ${UserData.Token}`,
                        },
                      }
                    );
                    // console.log(res);
                    if (res.data.status == "success") {
                      if (res.data.message == "Status Change successfully.") {
                        setLoading(false);
                        Navigate("/Perfil/History");
                        localStorage.setItem("Has-confirmado", true);
                      } else {
                        setLoading(false);
                        // console.log("res Error....", res);
                      }
                    }
                  } catch (error) {
                    setLoading(false);
                    // console.log("Catched Error....", error);
                  }
                }}
              >
                Sí, DESEO CONTINUAR
              </Button>
            </div>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }

  function $250Modal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ zIndex: "10000" }}
      >
        <Modal.Header
        // closeButton
        ></Modal.Header>
        <Modal.Body className="white text-center">
          <div className="Dropdown-Car-info-body-Head">
            {/* <i> */}
            Cancelar pedido
            {/* </i> */}
          </div>
          <p className="Modal-text d-flex flex-column gap-4">
            <div>
              ¿Estás seguro que desea cancelar? Al cancelar en este momento en
              donde ya habías confirmado seguir con el proceso, conlleva una
              penalidad del 50% del depósito dado ($250).
            </div>
            <div className="modal-btns-div w-100 gap-3">
              <Button
                variant="white"
                onClick={() => {
                  set$250ModalShow(false);
                }}
              >
                NO DESEO CANCELAR
              </Button>

              <Button
                variant="red"
                onClick={() => {
                  CancleOrder();
                }}
              >
                SÍ, DESEO CANCELAR
              </Button>
            </div>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }

  function SecondModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ zIndex: "10000" }}
      >
        <Modal.Header
        // closeButton
        ></Modal.Header>
        <Modal.Body className="white text-center">
          <div className="Dropdown-Car-info-body-Head">
            {/* <i> */}
            Cancelar pedido
            {/* </i> */}
          </div>
          <p className="Modal-text d-flex flex-column gap-4">
            <div>
              ¿Estás seguro que deseas cancelar? Se te hará la devolución del
              depósito dado ($500).
            </div>
            <div className="modal-btns-div w-100 gap-3">
              <Button
                variant="white"
                onClick={() => {
                  setSecondmodalShow(false);
                }}
              >
                NO DESEO CANCELAR
              </Button>
              <Button
                variant="red"
                onClick={() => {
                  CancleOrder();
                }}
              >
                SÍ, DESEO CANCELAR
              </Button>
            </div>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }

  function ThirdModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ zIndex: "10000" }}
      >
        <Modal.Header
        // closeButton
        ></Modal.Header>
        <Modal.Body className="white text-center">
          <div className="Dropdown-Car-info-body-Head">
            {/* <i> */}
            ¡Lo lamentamos!
            {/* </i> */}
          </div>
          <p className="Modal-text d-flex flex-column gap-4">
            <div>
              Lamentamos que no desearas continuar con el proceso. En los
              próximos días verás reflejado en tu cuenta la devolución del
              depósito dado ($500).
            </div>
            <Button
              variant="red"
              onClick={() => {
                setThirdmodalShow(false);
                Navigate("/Perfil");
              }}
            >
              OK
            </Button>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }

  return (
    <>
      {!Loading && (
        <>
          <FirstModal
            show={FirstmodalShow}
            onHide={() => setFirstmodalShow(false)}
          />
          <$250Modal
            show={$250ModalShow}
            onHide={() => set$250ModalShow(false)}
          />
          <SecondModal
            show={SecondmodalShow}
            onHide={() => setSecondmodalShow(false)}
          />
          <ThirdModal
            show={ThirdmodalShow}
            onHide={() => setThirdmodalShow(false)}
          />
        </>
      )}
      {!Specs ? (
        <div style={{ background: "#292d32" }}>
          {Loading ? (
            <div>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
                // onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          ) : (
            <div className="OrderDetail-Main-parent-div">
              <div className="OrderDetail-kia-logo-div pt-5 pb-5">
                <img
                  src={KiaLogo}
                  style={{ cursor: "pointer" }}
                  alt="Logo"
                  onClick={() => Navigate("/Inicio")}
                />
              </div>
              <div className="ConfirmOrder-Body-parent-div">
                <div className="ConfirmOrder-Body-header-div">
                  Confirmación de la orden
                </div>
                <div className="ConfirmOrder-Body-content-div">
                  <div className="ConfirmOrder-Body-image-div d-flex justify-content-center align-items-center w-100">
                    <img src={images} width="90%" />
                  </div>
                  <div className="ConfirmOrder-Body-content">
                    <div className="Confirm-order-Specs-div">
                      <span>
                        Número de transacción: {OrderHistory.data.trans_id}
                      </span>
                      <span>Número de orden: {OrderHistory.data.order_id}</span>
                      {/* <span className="dollor-txt">Total reservas: $ 500</span> */}
                      <span className="dollor-txt">Depósito: $ 500</span>
                    </div>
                    <div className="ModelName-Price-div">
                      <span>
                        Model {OrderHistory.data.model}{" "}
                        {OrderHistory.data.Mng_Year}
                      </span>
                    </div>
                    <div className="Confirm-order-Specs-div">
                      {/* <span>MSRP: ${OrderHistory.data.Price}</span> */}
                      <span>
                        MSRP: ${nf.format(parseInt(OrderHistory.data.Price))}
                      </span>
                      <span>
                        Color exterior: {OrderHistory.data.Ext_Color_Name}
                      </span>
                      <span>
                        Color interior: {OrderHistory.data.Int_Color_Name}
                      </span>
                      {/* <span>Aros: Gris oscuro</span> */}
                    </div>
                    {/* <div className="w-100">
              <ul>
                <li>Motor Sincrónico de Imanes Permanentes</li>
                <li>Tipo de tracción RWD</li>
                <li>
                  Motor de tracción trasera de 125 kW con 167 caballos de fuerza
                  & 258 lbs-pies. de par de torsión
                </li>
                <li>
                  Freno de estacionamiento electrónico (EPB) con retención
                  automática
                </li>
                <li>Sistema de frenado regenerativo con palancas de control</li>
              </ul>
            </div> */}
                    <div className="additionalSpecs">
                      <button
                        className="additionalSpecs-btn"
                        onClick={handlenavi}
                      >
                        Especificaciones adicionales
                      </button>
                    </div>
                    <div className="w-100 d-flex flex-column gap-3">
                      {/* <Button
                        // disable={disable}
                        variant={"red"}
                        // onClick={() => navigate("/Perfil/History")}
                      >
                        {disable ? "pedido cancelado" : "Cancelar pedido"}
                        Ir al perfil
                      </Button> */}
                      <Button
                        variant={"red"}
                        disable={disable}
                        onClick={() => {
                          Ordertrack.OTW_Status == "FALSE"
                            ? setFirstmodalShow(true)
                            : Ordertrack.OTW_Status == "TRUE" &&
                              set$250ModalShow(true);
                        }}
                      >
                        {disable
                          ? "PEDIDO CANCELADO"
                          : Ordertrack.OTW_Status == "FALSE"
                          ? "¿DESEAS CONTINUAR?"
                          : "CANCELAR PEDIDO"}
                      </Button>

                      <Button
                        variant={"red"}
                        onClick={() => Navigate("/Perfil/History")}
                      >
                        VOLVER AL PERFIL
                      </Button>

                      {/* <CancleOrderModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* {!Loading && OrderHistory && (
      )} */}
        </div>
      ) : (
        <>
          {" "}
          {OrderHistory?.data.model == "EV6 EX RWD" ? (
            <Specification onClick={() => setshow(false)} />
          ) : null}
          {OrderHistory?.data.model == "GT-LINE RWD" ? (
            <Specificationtwo onClick={() => setshow(false)} />
          ) : null}
          {OrderHistory.data.model == "EV6 GT-LINE (E-AWD)" ? (
            <Specificationthree onClick={() => setshow(false)} />
          ) : null}
          {OrderHistory.data.model == "EV9 GT-LINE" ? (
            <EV9Specification onClick={() => setshow(false)} />
          ) : null}
        </>
      )}
    </>
  );
};

export default SecondConfirmOrder;

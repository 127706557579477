import React, { useState, useEffect } from "react";
import Wrapper from "../../Components/UI/Wrapper";
import "../../Css/Policy/Policy.css";
import axios from "../../API";
import Loader from "../../Components/UI/Loader";
import Footer from "../../Components/UI/Footer";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const TermsConditions = () => {
  // Store Api Data in useState Variable
  const [ApiData, setApiData] = useState([]);

  // Api Error Data useState Variable
  const [ApiError, setApiError] = useState("");

  // Loader useState Variable
  const [Loading, setLoading] = useState(true);
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  // Terms and Conditions Get-Data Function
  const getApiData = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/get-terms-condition");
      if (res.data.status == "success") {
        setApiData(res.data.data.Description);
        setLoading(false);
      }
    } catch (error) {
      setApiError(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // Terms and Conditions Get-Data Function call
    getApiData();
  }, []);

  return (
    <>
      <Wrapper>
        <section className="white position-relative privacy-sec">
          <div className="p-5"></div>

          <div className="d-flex justify-content-center ">
            <div className="d-flex justify-content-center heading-policy border-bottom-for-policy">
              {/* <div className='p-4'></div> */}

              <span>Términos y Condiciones </span>

              {/* <div className='p-4'></div> */}
            </div>
          </div>

          <div className="p-3"></div>

          {!Loading && (
            <div className="d-flex justify-content-center">
              <div
                className="d-flex flex-column content-privacy-policy w-80"
                dangerouslySetInnerHTML={{ __html: ApiData }}
              ></div>
            </div>
          )}
        </section>
        {Loading && <Loader />}

        <div className="p-5"></div>
      </Wrapper>
      <Footer />
    </>
  );
};

export default TermsConditions;

import { MDBFooter, MDBContainer, MDBRow, MDBCol } from "mdb-react-ui-kit";
import "../../Css/Footer.css";
import KiaLogo from "../../Assets/Images/KiaLogo.png";
import { Outlet, NavLink } from "react-router-dom";
import { Link } from "react-scroll";
import { useEffect } from "react";

export default function Footer() {
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Footer is in the viewport
          // console.log('Footer is in the viewport');
          // Load your footer content here or trigger a function
        }
      });
    });
    const footerElement = document.querySelector("#footer");
    observer.observe(footerElement);

    return () => {
      observer.unobserve(footerElement);
    };
  }, []);

  const currentyear = new Date().getFullYear();

  return (
    <>
      <MDBFooter className="text-center text-lg-start MainFooter">
        <section className="p-4"></section>

        <section className="" id="footer">
          <MDBContainer className="text-center text-md-start mt-5 ">
            <MDBRow className="mt-3 ">
              <MDBCol md="3" lg="4" xl="3" className="mx-auto mb-4">
                <Link className="flogo" to="Inicio">
                  <img src={KiaLogo} />
                </Link>
                <p className="pt-2" style={{ color: "#696871" }}>
                  El EV totalmente eléctrico está diseñado para inspirar.
                </p>
                <div className="sociallinks">
                  <a
                    target={"blank"}
                    href="https://www.facebook.com/kiapuertorico"
                    className="me-4 text-reset"
                  >
                    <svg
                      className="FacebookIcon"
                      viewBox="0 0 18 24"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M8.2 2.875C6.97337 2.875 5.79699 3.36228 4.92963 4.22963C4.06228 5.09699 3.575 6.27337 3.575 7.5V10.075H1.1C0.975736 10.075 0.875 10.1757 0.875 10.3V13.7C0.875 13.8243 0.975736 13.925 1.1 13.925H3.575V20.9C3.575 21.0243 3.67574 21.125 3.8 21.125H7.2C7.32426 21.125 7.425 21.0243 7.425 20.9V13.925H9.92192C10.0252 13.925 10.1152 13.8547 10.1402 13.7546L10.9902 10.3546C11.0257 10.2126 10.9183 10.075 10.7719 10.075H7.425V7.5C7.425 7.29446 7.50665 7.09733 7.65199 6.95199C7.79733 6.80665 7.99446 6.725 8.2 6.725H10.8C10.9243 6.725 11.025 6.62426 11.025 6.5V3.1C11.025 2.97574 10.9243 2.875 10.8 2.875H8.2Z"
                        fill="currentColore"
                      />
                    </svg>
                  </a>
                  <a
                    target={"blank"}
                    href="https://www.instagram.com/kiapuertorico/"
                    className="me-4 text-reset"
                  >
                    <svg
                      className="InstaIcon"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M11.9999 8.75005C10.205 8.75005 8.74994 10.2051 8.74994 12C8.74994 13.795 10.205 15.25 11.9999 15.25C13.7949 15.25 15.2499 13.795 15.2499 12C15.2499 10.2051 13.7949 8.75005 11.9999 8.75005Z"
                        fill="currentColore"
                      />
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.76948 3.08151C10.2176 2.69614 13.7823 2.69614 17.2304 3.08151C19.1288 3.29369 20.66 4.78947 20.8828 6.69452C21.2951 10.2195 21.2951 13.7806 20.8828 17.3056C20.66 19.2106 19.1288 20.7064 17.2304 20.9186C13.7823 21.304 10.2176 21.304 6.76948 20.9186C4.87108 20.7064 3.33989 19.2106 3.11707 17.3056C2.70479 13.7806 2.70479 10.2195 3.11707 6.69452C3.33989 4.78947 4.87108 3.29369 6.76948 3.08151ZM16.9999 6.00005C16.4477 6.00005 15.9999 6.44776 15.9999 7.00005C15.9999 7.55233 16.4477 8.00005 16.9999 8.00005C17.5522 8.00005 17.9999 7.55233 17.9999 7.00005C17.9999 6.44776 17.5522 6.00005 16.9999 6.00005ZM7.24994 12C7.24994 9.37669 9.37659 7.25005 11.9999 7.25005C14.6233 7.25005 16.7499 9.37669 16.7499 12C16.7499 14.6234 14.6233 16.75 11.9999 16.75C9.37659 16.75 7.24994 14.6234 7.24994 12Z"
                        fill="currentColore"
                      />
                    </svg>
                  </a>
                  <a
                    target={"blank"}
                    href="https://www.linkedin.com/company/kia-puerto-rico/"
                    className="me-4 text-reset"
                  >
                    <svg
                      className="LinkedinIcon"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M4.75 1.875C3.57639 1.875 2.625 2.82639 2.625 4C2.625 5.1736 3.57639 6.125 4.75 6.125C5.9236 6.125 6.875 5.1736 6.875 4C6.875 2.82639 5.9236 1.875 4.75 1.875Z"
                        fill="currentColore"
                      />
                      <path
                        d="M2.75 7.875C2.68096 7.875 2.625 7.93096 2.625 8V21C2.625 21.069 2.68096 21.125 2.75 21.125H6.75C6.81904 21.125 6.875 21.069 6.875 21V8C6.875 7.93096 6.81904 7.875 6.75 7.875H2.75Z"
                        fill="currentColore"
                      />
                      <path
                        d="M9.25 7.875C9.18096 7.875 9.125 7.93096 9.125 8V21C9.125 21.069 9.18096 21.125 9.25 21.125H13.25C13.319 21.125 13.375 21.069 13.375 21V14C13.375 13.5027 13.5725 13.0258 13.9242 12.6742C14.2758 12.3225 14.7527 12.125 15.25 12.125C15.7473 12.125 16.2242 12.3225 16.5758 12.6742C16.9275 13.0258 17.125 13.5027 17.125 14V21C17.125 21.069 17.181 21.125 17.25 21.125H21.25C21.319 21.125 21.375 21.069 21.375 21V12.3802C21.375 9.9535 19.2644 8.05499 16.8498 8.2745C16.1052 8.34219 15.369 8.52731 14.6815 8.82194L13.375 9.38186V8C13.375 7.93096 13.319 7.875 13.25 7.875H9.25Z"
                        fill="currentColore"
                      />
                    </svg>
                  </a>
                  <a
                    target={"blank"}
                    href="https://www.youtube.com/channel/UC7pJwIBB7YneW_FOhqqkRpw"
                    className="me-4 text-reset"
                  >
                    <svg
                      className="YoutubeIcon"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        fillRule="evenodd"
                        clipRule="evenodd"
                        d="M6.98888 4.89055C10.3246 4.62958 13.6755 4.62958 17.0112 4.89055L19.2519 5.06585C20.5 5.16349 21.521 6.09889 21.7273 7.33366C22.2435 10.4232 22.2435 13.5769 21.7273 16.6664C21.521 17.9012 20.5 18.8366 19.2519 18.9342L17.0112 19.1095C13.6755 19.3705 10.3246 19.3705 6.98888 19.1095L4.74818 18.9342C3.50011 18.8366 2.47909 17.9012 2.27278 16.6664C1.75657 13.5769 1.75657 10.4232 2.27278 7.33366C2.47909 6.09889 3.50011 5.16349 4.74818 5.06585L6.98888 4.89055ZM10 14.4702V9.52989C10 9.2967 10.2544 9.15267 10.4543 9.27264L14.5713 11.7428C14.7655 11.8593 14.7655 12.1408 14.5713 12.2573L10.4543 14.7274C10.2544 14.8474 10 14.7034 10 14.4702Z"
                        fill="currentColore"
                      />
                    </svg>
                  </a>
                  <a
                    target={"_blank"}
                    href="https://twitter.com/Kiapuertorico"
                    className="me-4 text-reset"
                  >
                    <svg
                      className="TwitterIcon"
                      viewBox="0 0 24 24"
                      fill="currentColor"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M21.8098 6.22724C21.8677 6.14647 21.7821 6.04218 21.6894 6.07811C21.0307 6.33344 20.3423 6.50571 19.64 6.5906C20.4219 6.12348 21.0253 5.4124 21.3599 4.57053C21.394 4.48477 21.3003 4.40819 21.2195 4.4528C20.4928 4.85414 19.7091 5.14313 18.8949 5.30957C18.8608 5.31654 18.8257 5.30494 18.8018 5.27969C18.1908 4.63502 17.3866 4.20578 16.5098 4.05745C15.6147 3.90601 14.6946 4.05596 13.8938 4.48379C13.0931 4.91162 12.457 5.59313 12.0853 6.42144C11.7319 7.209 11.6365 8.08691 11.8108 8.93003C11.8243 8.99545 11.7731 9.05721 11.7065 9.05307C10.1224 8.95469 8.57469 8.53336 7.15868 7.81446C5.74627 7.09739 4.49554 6.09983 3.48267 4.8833C3.43772 4.82931 3.35252 4.83629 3.31977 4.89845C3.0046 5.49651 2.83958 6.16311 2.84003 6.8406C2.83875 7.51498 3.00425 8.17922 3.32178 8.77416C3.63932 9.36911 4.09905 9.87631 4.66003 10.2506C4.0651 10.2344 3.48178 10.0873 2.95128 9.82067C2.88291 9.78631 2.8009 9.83524 2.80446 9.91167C2.84583 10.8009 3.17228 11.6936 3.73734 12.3786C4.33866 13.1074 5.17328 13.6062 6.10003 13.7906C5.74328 13.8992 5.3729 13.9564 5.00003 13.9606C4.79709 13.9582 4.59454 13.9433 4.39356 13.9158C4.31904 13.9056 4.2582 13.9764 4.28428 14.0469C4.5607 14.7944 5.04903 15.4467 5.6911 15.9228C6.37755 16.4318 7.2056 16.7142 8.06003 16.7306C6.61723 17.8659 4.83591 18.4855 3.00003 18.4906C2.81138 18.4912 2.62282 18.4851 2.43471 18.4723C2.3287 18.4651 2.27995 18.6063 2.37132 18.6605C4.16009 19.7221 6.20462 20.2831 8.29003 20.2806C9.82972 20.2966 11.3572 20.0056 12.7831 19.4247C14.2091 18.8437 15.5051 17.9845 16.5952 16.8971C17.6854 15.8097 18.548 14.516 19.1326 13.0915C19.7172 11.667 20.0121 10.1403 20 8.6006V8.12077C20 8.0892 20.015 8.05951 20.0402 8.04048C20.7184 7.52834 21.3149 6.91691 21.8098 6.22724Z"
                        fill="currentColore"
                      />
                    </svg>
                  </a>
                </div>
              </MDBCol>

              <MDBCol
                md="2"
                lg="2"
                xl="2"
                className="mx-auto mb-4 FooterTextLinkParent"
              >
                <h6 className="footer-text-head mb-2">Reservar</h6>
                <p>
                  <NavLink to="/Intro" className="text-reset footer-text">
                    Regístrate
                  </NavLink>
                  {/* <span
                    style={{ cursor: "pointer" }}
                    className="text-reset footer-text"
                    onClick={() => setAlertModal(true)}
                  >
                    Regístrate
                  </span> */}
                </p>
                <p>
                  <NavLink to="/Iniciar-Sesion" className="text-reset">
                    Inicia sesión
                  </NavLink>
                  {/* <span
                    style={{ cursor: "pointer" }}
                    className="text-reset footer-text"
                    onClick={() => setAlertModal(true)}
                  >
                    Inicia sesión
                  </span> */}
                </p>
              </MDBCol>

              <MDBCol
                md="2"
                lg="1"
                xl="2"
                className="mx-auto mb-4 FooterTextLinkParent"
              >
                <h6 className="mb-2 footer-text-head">Soporte</h6>
                <p>
                  <NavLink to="/preguntas" className="text-reset">
                    Preguntas frecuentes
                  </NavLink>
                </p>
                <p>
                  <NavLink to="/Soporte" className="text-reset">
                    Contáctanos
                  </NavLink>
                </p>
              </MDBCol>

              <MDBCol
                md="2"
                lg="3"
                xl="3"
                className="mx-auto mb-md-0 mb-4 FooterTextLinkParent"
              >
                <h6 className="footer-text-head mb-2">Legal</h6>
                <p className="mb-1">
                  <NavLink to="/Politica/Terminos-y-Condiciones">
                    Términos y Condiciones
                  </NavLink>
                </p>
                <p className="mb-1">
                  <NavLink to="/Politica/Privacidad">
                    Política de Privacidad
                  </NavLink>
                </p>
                {/* <p className="mb-1" id="disable" >
                  <NavLink to="/Politica/Compra-y-Devolucion" >
                    Política de compra y devolución
                  </NavLink>
                </p> */}
              </MDBCol>

              <MDBCol
                md="3"
                lg="2"
                xl="2"
                className="mx-auto mb-md-0 mb-4 FooterTextLinkParent"
              >
                <h6 className="footer-text-head mb-2">Contáctanos</h6>
                <a
                  href={"mailto:info@kiaevpr.com"}
                  style={{ textDecoration: "none" }}
                >
                  <p style={{ color: "#D40000" }}>info@kiaevpr.com</p>
                </a>
                {/* <p>
                  <NavLink to="/preguntas" className="text-reset">
                    Enviar preguntas
                  </NavLink>
                </p> */}
                {/* <p>
                  <a
                    href={"tel:787-787-7878"}
                    style={{ textDecoration: "none" }}
                    className="disable"
                  >
                    787-787-7878
                  </a>
                </p> */}
              </MDBCol>
            </MDBRow>
            <MDBRow>
              <section className=" d-flex justify-content-center">
                <div className="w-100 d-flex justify-content-between down-footer FooterTextLinkParent ">
                  <p>{`Copyright© ${currentyear} Kia. All Rights Reserved.`}</p>
                  {/* 
                  <MDBRow>
                    <MDBCol
                      md="3"
                      lg="2"
                      xl="2"
                      className="me-auto mb-md-0 mb-4 FooterTextLinkParent"
                    >
                      <h6 className="footer-text-head mb-2">Idioma</h6>
                      <p className="mb-1">Español</p>
                      <p className="mb-1">Inglés</p>
                    </MDBCol>
                  </MDBRow> */}
                </div>
              </section>
            </MDBRow>
            <MDBRow>
              <section className=" d-flex justify-content-center">
                <div className="w-100 d-flex justify-content-between down-footer FooterModalText">
                  <p>
                    El usuario entiende y acepta que la página de Kiaevpr no es
                    una página exclusivamente de mercadeo. En esta página el
                    usuario además de ver y educarse sobre el producto de EV
                    también tendrá la opción de reservarlo de así desearlo. Del
                    usuario querer conocer más sobre Kia y el resto de los
                    modelos distribuidos por Kia Puerto Rico, debe visitar el
                    enlace{" "}
                    <a
                      style={{ borderBottom: "1px solid grey" }}
                      target={"_blank"}
                      href="https://www.kia.com/pr/main.html"
                    >
                      www.kia.com/pr.
                    </a>
                  </p>
                </div>
              </section>
            </MDBRow>
          </MDBContainer>
        </section>
      </MDBFooter>
      <Outlet />
    </>
  );
}

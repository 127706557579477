import React, { useEffect } from "react";
import Payment from "../../Components/ProfileTabs/Payment";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import AddNewCard from "../Contrato/AddNewCard";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

// Live
const zishuMalek2 = process.env.REACT_APP_STRIP_KEY;
const stripePromise = loadStripe(zishuMalek2);
// const stripePromise = loadStripe(
//   "pk_live_51MAIgBGul4sfg2qk549FfkXF7hETsD15u6j7CD9n1dYx9qqxgRZWkjJoCQsnvqa0Lr0Bbvab0LfxtQFLjEbKLqbB00R3RG1jce"
// );

// Test
// const stripePromise = loadStripe(
//   "pk_test_51KVyV0AJ9kDiPelhQATfYgez7QYawfNdfeZqusSDTgT3ay4yvkJe6xmSP0SfckSW2EOcIOXgFClgoqc28eVyEco600Zr5672lu"
// );

const StripeContainer2 = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  return (
    <div>
      <Elements stripe={stripePromise}>
        <Payment />
      </Elements>
    </div>
  );
};

export default StripeContainer2;

import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../../Css/Profile/History.css";
import img from "../../Assets/Images/GreenImage.png";
import Modal from "react-bootstrap/Modal";
import Button, { Bonobtn } from "../UI/Button";
import axios from "../../API";
import Loader from "../UI/Loader";
// import img1 from "../../Assets/Images/CV_BL_Front_Yacht_Blue_DU3.png";
// import img2 from "../../Assets/Images/CV_BL_Front_Gravity_Blue_B4U.png";
// import img3 from "../../Assets/Images/CV_BL_Front_Moonscape_Matte_KLM.png";
// import img4 from "../../Assets/Images/CV_BL_Front_Steel_Gray_KLG.png";
// import img5 from "../../Assets/Images/CV_BL_Front_Snow_White_Pearl_SWP.png";
// import img6 from "../../Assets/Images/CV_BL_Front_Glacier_GLB.png";
// import img7 from "../../Assets/Images/CV_BL_Front_Runway_Red_CR5.png";
// import img8 from "../../Assets/Images/CV_BL_Front_Aurora_Black Pearl_ABP.png";
// import img9 from "../../Assets/Images/CV_BL_Front_Urban_Yellow_UBY.png";
// import img10 from "../../Assets/Images/CV_BL_Front_Interstella_Gray_AGT.png";
import img1 from "../../Assets/EV6 EX RWD/CV_BL_Front_Yacht_Blue_DU3.png";
import img2 from "../../Assets/EV6 EX RWD/CV_BL_Front_Gravity_Blue_B4U.png";
import img3 from "../../Assets/EV6 EX RWD/CV_BL_Front_Moonscape_Matte_KLM.png";
import img4 from "../../Assets/EV6 EX RWD/CV_BL_Front_Steel_Gray_KLG.png";
import img5 from "../../Assets/EV6 EX RWD/CV_BL_Front_Snow_White_Pearl_SWP.png";
import img6 from "../../Assets/EV6 EX RWD/CV_BL_Front_Glacier_GLB.png";
import img7 from "../../Assets/EV6 EX RWD/CV_BL_Front_Runway_Red_CR5.png";
import img8 from "../../Assets/EV6 EX RWD/CV_BL_Front_Aurora_Black Pearl_ABP.png";
import img9 from "../../Assets/EV6 EX RWD/CV_BL_Front_Urban_Yellow_UBY.png";
import img10 from "../../Assets/EV6 EX RWD/CV_BL_Front_Interstella_Gray_AGT.png";

import img11 from "../../Assets/GT LINE/CV_GT_Line_Front_Yacht_Blue_DU3-removebg-preview.png";
import img13 from "../../Assets/GT LINE/CV_GT_Line_Front_Moonscape_Matte_KLM-removebg-preview.png";
import img15 from "../../Assets/GT LINE/CV_GT_Line_Front_Snow_White_Pearl_SWP-removebg-preview.png";
import img17 from "../../Assets/GT LINE/CV_GT_Line_Front_Runway_Red_CR5-removebg-preview.png";
import img18 from "../../Assets/GT LINE/CV_GT_Line_Front_Aurora_Black_Pearl_ABP-removebg-preview.png";
import trakertick from "../../Assets/Images/trackertick.png";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";

import NewImg1 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Yacht_Blue_DU3.png";
import NewImg2 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Runway_Red_CR5.png";
import NewImg3 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Aurora_Black Pearl_ABP.png";
import NewImg4 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Moonscape_Matte_KLM.png";
import NewImg5 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Snow_White_Pearl_SWP.png";

import ReactGA from "react-ga";
import { useSelector } from "react-redux";
import { GlobalContext } from "../../Context/GlobalContext";
import { imagesdata } from "../Data/Data";

const OrderHistory = () => {
  // let route = useLocation();
  // const HistoryData = route.state;
  const ModalDataFromRedux = useSelector((state) => state.carData);

  const [alertModalShow, setalertModalShow] = useState(false);

  const location = useLocation();

  const Has_confirmado = localStorage.getItem("Has-confirmado");

  const { setD4000Show } = useContext(GlobalContext);

  useEffect(() => {
    if (Has_confirmado) {
      setTimeout(() => {
        setalertModalShow(true);
        localStorage.removeItem("Has-confirmado");
      }, 1500);
    }
    ReactGA.pageview(location.pathname);
  }, []);

  function AlertModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ zIndex: "10000" }}
      >
        <Modal.Header
        // closeButton
        ></Modal.Header>
        <Modal.Body className="white text-center">
          <div className="Dropdown-Car-info-body-Head">
            {/* <i> */}
            ¡Gracias!
            {/* </i> */}
          </div>
          <p className="Modal-text d-flex flex-column gap-4">
            <div>Has confirmado que deseas continuar con el proceso. </div>
            <Button
              variant="red"
              onClick={() => {
                localStorage.removeItem("Has-confirmado");
                setalertModalShow(false);
                setContent(false);
              }}
            >
              OK
            </Button>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }

  // useEffect(() => {
  //   if (HistoryData == true) {
  //     // setContent(false);
  //     setalertModalShow(true);
  //   }
  // }, []);

  function CancleOrderModal(props) {
    function ChangeBtn() {
      setdisable(true);
      setModalShow(false);
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}
      >
        <Modal.Body className="white text-center pt-3">
          <h4 className="pb-3">Cancelar pedido</h4>
          <p className="Modal-text">
            El Usuario entiende y acepta que la Reservación podrá ser cancelada
            por Kia de Puerto Rico por cualquier razón. Usuario acepta que, de
            darse una Cancelación, el único remedio contractual o
            extracontractual al que tendrá derecho será la restitución del Monto
            pagado.
          </p>
          <div className="p-2"></div>
          <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
            <Button variant="white" onClick={props.onHide}>
              No deseo cancelar
            </Button>
            {/* <Button variant="red" onClick={ChangeBtn}> */}
            <Button variant="red" onClick={() => CancleOrder()}>
              SÍ, deseo Cancelar
            </Button>
          </div>
          <div className="p-3"></div>
        </Modal.Body>
      </Modal>
    );
  }

  const UserData = JSON.parse(localStorage.getItem("user-info"));

  const [Content, setContent] = useState(true);

  const [disable, setdisable] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  const [Loading, setLoading] = useState(true);

  const [OrderHistory, setOrderHistory] = useState([]);
  const [Ordertrackk, setOrdertrack] = useState({});

  // OrderHistory API
  const getOrderHistory = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/get-history", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${UserData?.Token}`,
        },
      });
      if (res) {
        // console.log("OrderHistory...............", res);
        localStorage.setItem("trackid", res.data.data.track_id);
        if (res.data.message == "No Record") {
          setLoading(false);
        } else {
          setOrderHistory(res.data);
          setLoading(false);
          // console.log("res Error....", res);
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log("Catched Error....", error);
    }
  };

  // Order track API
  const trackid = localStorage.getItem("trackid");
  const Ordertrack = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "/get-order-status",
        {
          // track_id: "1",
          track_id: trackid,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData?.Token}`,
          },
        }
      );
      // console.log(response);
      // if(response){
      setOrdertrack(response.data.data);
      // setLoading(true);
      // }
      // console.log("responsessss . . . . track", response);
    } catch (error) {
      // console.log(error, "errorrr");
    }
  };

  useEffect(() => {
    getOrderHistory();
  }, []);
  useEffect(() => {
    Ordertrack();
  }, []);

  // console.log(OrderHistory)
  const navigate = useNavigate();

  const handlenavi = () => {
    // console.log(OrderHistory.data);
    if (OrderHistory.data.model == "EV6 EX RWD") {
      navigate("/EspecificacionesEV6-EX-RWD");
    } else if (OrderHistory.data.model == "GT-LINE RWD") {
      navigate("/EspecificacionesGT-LINE-RWD");
    } else if (OrderHistory.data.model == "EV6 GT-LINE (E-AWD)") {
      navigate("/EspecificacionesEV6-GT-LINE-(E-AWD)");
    } else if (OrderHistory.data.model == "EV9 GT-LINE") {
      navigate("/EspecificacionesEV9");
    }
  };

  const colorcode = imagesdata.filter(
    (item) => item.colorcode === OrderHistory?.data?.Ext_Color_Code
  );

  let images;
  if (OrderHistory.data) {
    if (OrderHistory.data.ModelType === "EV6") {
      if (OrderHistory.data.model == "CVE1" || "CVE2") {
        if (OrderHistory.data.Ext_Color_Code == "#1A30AD") {
          images = img1;
        } else if (OrderHistory.data.Ext_Color_Code == "#34425F") {
          images = img2;
        } else if (OrderHistory.data.Ext_Color_Code == "#666666") {
          images = img3;
        } else if (OrderHistory.data.Ext_Color_Code == "#9C9EA0") {
          images = img4;
        } else if (OrderHistory.data.Ext_Color_Code == "#C6C4B7") {
          images = img5;
        } else if (OrderHistory.data.Ext_Color_Code == "#F9F9F9") {
          images = img6;
        } else if (OrderHistory.data.Ext_Color_Code == "#933531") {
          images = img7;
        } else if (OrderHistory.data.Ext_Color_Code == "#1B1B1B") {
          images = img8;
        } else if (OrderHistory.data.Ext_Color_Code == "#CD8700") {
          images = img9;
        } else if (OrderHistory.data.Ext_Color_Code == "#4F5354") {
          images = img10;
        }
      } else if (OrderHistory.data.model == "GT-LINE RWD") {
        if (OrderHistory.data.Ext_Color_Code == "#1A30AD") {
          images = NewImg1;
        } else if (OrderHistory.data.Ext_Color_Code == "#933531") {
          images = NewImg2;
        } else if (OrderHistory.data.Ext_Color_Code == "#1B1B1B") {
          images = NewImg3;
        } else if (OrderHistory.data.Ext_Color_Code == "#666666") {
          images = NewImg4;
        } else if (OrderHistory.data.Ext_Color_Code == "#C6C4B7") {
          images = NewImg5;
        }
      } else if (OrderHistory.data.model == "EV6 GT-LINE (E-AWD)") {
        if (OrderHistory.data.Ext_Color_Code == "#1A30AD") {
          images = img11;
        } else if (OrderHistory.data.Ext_Color_Code == "#933531") {
          images = img17;
        } else if (OrderHistory.data.Ext_Color_Code == "#1B1B1B") {
          images = img18;
        } else if (OrderHistory.data.Ext_Color_Code == "#666666") {
          images = img13;
        } else if (OrderHistory.data.Ext_Color_Code == "#C6C4B7") {
          images = img15;
        }
      }
    } else if (OrderHistory.data.ModelType === "EV9") {
      if (OrderHistory.data.Ext_Color_Code === colorcode[0].colorcode) {
        images = colorcode[0]?.images[5];
      }
    }
  }

  const CancleOrder = async () => {
    setModalShow(false);
    setLoading(true);
    try {
      const res = await axios.post(
        "/cancel-order",
        {
          TrackId: OrderHistory.data.track_id,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData.Token}`,
          },
        }
      );
      if (res) {
        // console.log("...............", res);
        if (res.data.status == "success") {
          // navigate("/Modelos");
          setLoading(false);
          // setContent(true);
          setdisable(true);
          getOrderHistory();
        } else {
          setLoading(false);
          // console.log("res Error....", res);
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log("Catched Error....", error);
    }
  };

  var nf = new Intl.NumberFormat();
  const fromCartdata = ModalDataFromRedux.filter(
    (item) => item?.name == OrderHistory?.data?.model
  );

  // console.log("fromCartdata_______!!!", fromCartdata);

  return (
    <section className="white pt-4">
      <AlertModal
        show={alertModalShow}
        onHide={() => setalertModalShow(false)}
      />
      {Loading ? (
        <Loader />
      ) : (
        <>
          {Loading == false &&
          OrderHistory.status == "success" &&
          OrderHistory.message == "No Record" ? (
            <></>
          ) : (
            OrderHistory.status == "success" &&
            OrderHistory.message == "Record" && (
              <>
                {Content ? (
                  <div
                    className="OrderHistoryDiv d-flex gap-4 align-items-center"
                    style={{ cursor: "pointer" }}
                    onClick={() => setContent(false)}
                  >
                    <div>
                      <img src={images} width="150px" />
                    </div>
                    <div>
                      <div className="d-flex gap-4 align-items-center ">
                        <div className="d-flex flex-column">
                          <span className="OrderText">
                            Número de orden: {OrderHistory?.data?.order_id}
                          </span>
                          <span className="OrderText">
                            Fecha de la orden: {OrderHistory?.data?.order_at}
                          </span>
                        </div>
                        <div>
                          <svg
                            width="15"
                            height="25"
                            viewBox="0 0 15 25"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M14.3723 11.1213L3.33586 0.553275C3.1546 0.377961 2.93894 0.238811 2.70132 0.143851C2.46371 0.0488906 2.20885 0 1.95144 0C1.69403 0 1.43917 0.0488906 1.20156 0.143851C0.963943 0.238811 0.748283 0.377961 0.567015 0.553275C0.203845 0.903726 0 1.37779 0 1.87194C0 2.36608 0.203845 2.84015 0.567015 3.1906L10.219 12.5428L0.567015 21.8015C0.203845 22.152 0 22.6261 0 23.1202C0 23.6143 0.203845 24.0884 0.567015 24.4389C0.747603 24.6156 0.962959 24.7562 1.2006 24.8525C1.43825 24.9488 1.69345 24.999 1.95144 25C2.20942 24.999 2.46463 24.9488 2.70228 24.8525C2.93992 24.7562 3.15528 24.6156 3.33586 24.4389L14.3723 13.8708C14.5702 13.6957 14.7281 13.4831 14.8362 13.2465C14.9442 13.0099 15 12.7544 15 12.4961C15 12.2378 14.9442 11.9822 14.8362 11.7456C14.7281 11.509 14.5702 11.2964 14.3723 11.1213V11.1213Z"
                              fill="#ED1B24"
                            />
                          </svg>
                        </div>
                        {/* <div className='pe-1'></div> */}
                      </div>
                    </div>
                  </div>
                ) : (
                  Loading == false && (
                    <div className="back-btn">
                      <svg
                        style={{
                          cursor: "pointer",
                          position: "absolute",
                          top: "-45px",
                          left: "0",
                        }}
                        onClick={() => setContent(true)}
                        width="40"
                        height="40"
                        viewBox="0 0 108 108"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M38.07 57.1951L63.54 82.6201C63.9584 83.0419 64.4561 83.3766 65.0044 83.6051C65.5528 83.8335 66.141 83.9512 66.735 83.9512C67.3291 83.9512 67.9172 83.8335 68.4656 83.6051C69.014 83.3766 69.5117 83.0419 69.93 82.6201C70.7681 81.7769 71.2386 80.6364 71.2386 79.4476C71.2386 78.2587 70.7682 77.1182 69.93 76.2751L47.655 53.7751L69.93 31.5001C70.7682 30.657 71.2386 29.5164 71.2386 28.3276C71.2386 27.1387 70.7682 25.9982 69.93 25.1551C69.5133 24.7299 69.0163 24.3916 68.4678 24.1599C67.9194 23.9281 67.3304 23.8076 66.735 23.8051C66.1396 23.8076 65.5507 23.9281 65.0022 24.1599C64.4538 24.3916 63.9568 24.7299 63.54 25.1551L38.07 50.5801C37.6132 51.0015 37.2487 51.5129 36.9994 52.0821C36.75 52.6514 36.6213 53.2661 36.6213 53.8876C36.6213 54.509 36.75 55.1238 36.9994 55.693C37.2487 56.2623 37.6132 56.7737 38.07 57.1951Z"
                          fill="#d40000"
                        />
                      </svg>
                      <div className="OrderHistory-parent-div">
                        <div className="OrderHistory-img-div">
                          <img
                            // src={OrderHistory.data.Images[0]}
                            src={images}
                            width="100%"
                            alt=""
                          />
                        </div>
                        <div>
                          <div className="w-100 d-flex flex-column gap-4">
                            <div className="w-100 d-flex flex-column gap-2">
                              <span className="OrderText">
                                Número de transacción:{" "}
                                {OrderHistory?.data.trans_id}
                              </span>
                              <span className="OrderText">
                                Número de orden: {OrderHistory?.data.order_id}
                              </span>
                            </div>
                            <span className="OrderHead">
                              Model {OrderHistory?.data.model}{" "}
                              {OrderHistory.data.ModelType === "EV6" &&
                                OrderHistory?.data.Mng_Year}
                            </span>
                            <span className="OrderText">
                              <span className="dollor-txt">
                                {/* Total de la reserva $ */}
                                Depósito $
                              </span>
                              500
                            </span>
                            <div className="w-100 d-flex flex-column gap-3">
                              {OrderHistory.data.ModelType === "EV6" ? (
                                <>
                                  {fromCartdata[0]?.isOfferActivated == true ? (
                                    <>
                                      <p className="OrderText">
                                        MSRP:
                                        <span className="line-through">
                                          {fromCartdata[0]?.price}
                                        </span>
                                      </p>
                                      <p>
                                        <span
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            setD4000Show(true);
                                          }}
                                        >
                                          -$6,000{" "}
                                        </span>
                                        <span
                                          style={{
                                            color: "#F3C300",
                                            fontSize: "22px",
                                            fontWeight: "600",
                                          }}
                                        >
                                          {" "}
                                          $
                                          {nf.format(
                                            parseInt(OrderHistory?.data.Price)
                                          )}
                                        </span>
                                      </p>
                                    </>
                                  ) : (
                                    <>
                                      <p className="OrderText">
                                        MSRP:
                                        <span className="">
                                          $
                                          {nf.format(
                                            parseInt(OrderHistory?.data.Price)
                                          )}
                                        </span>
                                      </p>
                                    </>
                                  )}
                                </>
                              ) : (
                                <span>MSRP: ${OrderHistory.data.Price}</span>
                              )}

                              <span className="OrderText">
                                Color exterior:{" "}
                                {OrderHistory?.data.Ext_Color_Name}
                              </span>
                              <span className="OrderText">
                                Color interior:{" "}
                                {OrderHistory?.data.Int_Color_Name}
                              </span>
                              {/* <span className="OrderText">Aros: Gris oscuro</span> */}
                            </div>
                            <div className="ecebtn">
                              <button
                                className="additionalSpecs-btn"
                                onClick={handlenavi}
                              >
                                Especificaciones adicionales
                              </button>
                            </div>
                            <div className="w-100 d-flex">
                              {Ordertrackk?.Entregado === "Yes" ? null : (
                                <Button
                                  disable={
                                    disable
                                    // OrderHistory?.data.status == "Complete"
                                    //   ? false
                                    //   : true
                                  }
                                  variant={"red"}
                                  // onClick={CancleOrder()}
                                  onClick={() => {
                                    Ordertrackk?.OTW == "Yes"
                                      ? navigate("/Confirm-Order")
                                      : setModalShow(true);
                                  }}
                                >
                                  {/* CANCELAR PEDIDO */}
                                  {/* {Ordertrackk?.Entregado == "Yes"
                                    ? "¿Deseas continuar?"
                                    :  */}
                                  {disable
                                    ? "PEDIDO CANCELADO"
                                    : Ordertrackk?.OTW == "Yes" &&
                                      Ordertrackk?.OTW_Status == "FALSE"
                                    ? "¿Deseas continuar?"
                                    : "CANCELAR PEDIDO"}
                                  {/* {OrderHistory?.data.status == "Complete"
                              ? "Cancelar pedido"
                              : "pedido cancelado"} */}
                                </Button>
                              )}
                            </div>
                            <div className="ordertrackmain">
                              <div className="ordertrack">
                                <div
                                  className={
                                    Ordertrackk?.Pendiente == "Yes"
                                      ? "ringactive"
                                      : "ring"
                                  }
                                >
                                  {Ordertrackk?.Pendiente == "Yes" ? (
                                    <motion.img
                                      initial={{ opacity: 0, x: -10 }}
                                      whileInView={{ opacity: 1, x: 0 }}
                                      transition={{ duration: 0.3 }}
                                      src={trakertick}
                                      alt=""
                                    />
                                  ) : null}
                                </div>
                                <div
                                  style={{
                                    backgroundColor:
                                      Ordertrackk?.Pendiente == "No"
                                        ? "#D1D5DB"
                                        : "#D40000",
                                  }}
                                  className="ringborder"
                                ></div>
                                {/* <div
                                  className={
                                    Ordertrackk?.Esta_en_camino == "Yes"
                                      ? "ringactive"
                                      : "ring"
                                  }
                                >
                                  {Ordertrackk?.Esta_en_camino == "Yes" ? (
                                    <motion.img
                                      initial={{ opacity: 0, x: -10 }}
                                      whileInView={{ opacity: 1, x: 0 }}
                                      transition={{ duration: 0.3 }}
                                      src={trakertick}
                                      alt=""
                                    />
                                  ) : null}
                                </div>
                                <div
                                  style={{
                                    backgroundColor:
                                      Ordertrackk?.Esta_en_camino == "Yes"
                                        ? "#D40000"
                                        : "#D1D5DB",
                                  }}
                                  className="ringborder"
                                ></div> */}
                                <div
                                  className={
                                    Ordertrackk?.OTW == "Yes"
                                      ? "ringactive"
                                      : "ring"
                                  }
                                >
                                  {Ordertrackk?.OTW == "Yes" ? (
                                    <motion.img
                                      initial={{ opacity: 0, x: -10 }}
                                      whileInView={{ opacity: 1, x: 0 }}
                                      transition={{ duration: 0.3 }}
                                      src={trakertick}
                                      alt=""
                                    />
                                  ) : null}
                                </div>
                                <div
                                  style={{
                                    backgroundColor:
                                      Ordertrackk?.OTW == "Yes"
                                        ? "#D40000"
                                        : "#D1D5DB",
                                  }}
                                  className="ringborder"
                                ></div>
                                {/* <div
                                  className={
                                    Ordertrackk?.OTWW == "Yes"
                                      ? "ringactive"
                                      : "ring"
                                  }
                                >
                                  {Ordertrackk?.OTWW == "Yes" ? (
                                    <motion.img
                                      initial={{ opacity: 0, x: -10 }}
                                      whileInView={{ opacity: 1, x: 0 }}
                                      transition={{ duration: 0.3 }}
                                      src={trakertick}
                                      alt=""
                                    />
                                  ) : null}
                                </div>
                                <div
                                  style={{
                                    backgroundColor:
                                      Ordertrackk?.OTWW == "No"
                                        ? "#D1D5DB"
                                        : "#D40000",
                                  }}
                                  className="ringborder"
                                ></div> */}

                                <div
                                  className={
                                    Ordertrackk?.Entregado == "Yes"
                                      ? "ringactive"
                                      : "ring"
                                  }
                                >
                                  {Ordertrackk?.Entregado == "Yes" ? (
                                    <motion.img
                                      initial={{ opacity: 0, x: -10 }}
                                      whileInView={{ opacity: 1, x: 0 }}
                                      transition={{ duration: 0.3 }}
                                      src={trakertick}
                                      alt=""
                                    />
                                  ) : null}
                                </div>
                              </div>
                              <div className="trackname">
                                <h6>Pendiente</h6>
                                {/* <h6>Está en camino</h6> */}
                                <h6>En espera</h6>
                                {/* <h6>En espera</h6> */}
                                <h6>Entregado</h6>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )
                )}
              </>
            )
          )}
        </>
      )}
      <CancleOrderModal show={modalShow} onHide={() => setModalShow(false)} />
    </section>
  );
};

export default OrderHistory;

import React, { Suspense, lazy, useEffect, useState } from "react";
import ReactGA from "react-ga";
import { useLocation } from "react-router-dom";
import "../../../Css/Gallery/GalleryNav.css";
import { CircularProgress } from "@mui/material";
// import { NavLink,} from "react-router-dom";
import Exterior from "./Exterior";
import Interior from "./Interior";
// import Video from "./Videos";
const Video = lazy(() => import("./Videos"));

const GallaryNav = () => {
  const [active, setactive] = useState(1);

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
    localStorage.removeItem("Register-Start");
  }, []);

  return (
    <div
      className=" "
      // style={{ height: "100vh" }}
    >
      <nav className="gellnav">
        <ul>
          <li onClick={() => setactive(1)}>
            <span className={active === 1 ? "borderactive2" : null}>
              Exterior
            </span>
          </li>
          <div className="linediv"></div>
          <li onClick={() => setactive(2)}>
            <span className={active === 2 ? "borderactive2" : null}>
              Interior
            </span>
          </li>
          {/* <div className="linediv"></div>
          <li onClick={() => setactive(3)}>
            <span className={active === 3 ? "borderactive2" : null}>
              Videos
            </span>
          </li> */}
        </ul>
        <div>
          <span className="gelle">Galería</span>
        </div>
      </nav>
      {active === 1 && <Exterior />}
      {active === 2 && <Interior />}
      {active === 3 && (
        <Suspense
          fallback={
            <div className="fallbackloading">
              <CircularProgress />
            </div>
          }
        >
          <Video />
        </Suspense>
      )}
    </div>
  );
};

export default GallaryNav;

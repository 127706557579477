import React, { useContext, useEffect, useState } from "react";
import closeimg from "../../Assets/Images/close.png";
import EV6 from "../../Assets/EV6 EX RWD/CV_BL_Front_Glacier_GLB.png";
import tick from "../../Assets/Images/colortickicon.png";

import EV9 from "../../Assets/EV9/1.png";
import "../../Css/Popup.css";
import { motion } from "framer-motion";
import { useLocation, useNavigate } from "react-router-dom";
import { GlobalContext } from "../../Context/GlobalContext";
import { Icons } from "../../Constant/Icons";

const Popup = () => {
  const navigate = useNavigate();
  const pathname = useLocation();
  // const [imgg, setimgg] = useState(0);
  const { isshow, setseparatemodal, setishsow, setnewev9 } =
    useContext(GlobalContext);
  const StaticCartData = JSON.parse(localStorage.getItem("StaticCartData"));
  const [selected, setselected] = useState("");

  useEffect(() => {
    if (StaticCartData?.id && pathname.pathname == "/Cart") {
      setishsow(true);
    }
  }, [pathname.pathname]);

  const handlenavigate = () => {
    if (selected === "EV6") {
      setseparatemodal(true);
      // setishsow(true);
      // navigate("/Modelos");
    } else {
      // navigate("/Modelos/EV9");
      // setishsow(true);
      setnewev9(true);
    }
  };

  // const images = [
  //   img4,
  //   img1,
  //   // img2,
  //   // img3,
  //   img5,
  //   img6,
  //   img7,
  //   img8,
  //   // img9,
  //   // img10,
  // ];

  if (isshow) {
    return null;
  }

  return (
    <>
      <div className="mainpopupdiv">
        <motion.div
          initial={{ scale: 0.7 }}
          whileInView={{ scale: 1 }}
          transition={{ duration: 0.2 }}
        >
          <div className="popupchild">
            <div>
              <img
                style={{ paddingRight: "10px" }}
                src={closeimg}
                alt=""
                onClick={() => setishsow(true)}
              />
              <div className="d-flex flex-column align-items-center justify-content-center">
                <p style={{ color: "#ffffff" }} className="text-center m-0 p-0">
                  Selecciona una opción, ¿en qué tienes interés EV6 o EV9?
                </p>
                <div className="d-flex align-items-center justify-content-center flex-row gap-1 border-2">
                  <div
                    className={`selectcar ${
                      selected === "EV6" ? "activecar" : ""
                    }`}
                    onClick={() => setselected("EV6")}
                  >
                    {selected === "EV6" && <span>{Icons.tick}</span>}{" "}
                    <img src={EV6} alt="" />
                  </div>
                  <div
                    onClick={() => setselected("EV9")}
                    className={`selectcar ${
                      selected === "EV9" ? "activecar" : ""
                    }`}
                  >
                    {selected === "EV9" && <span>{Icons.tick}</span>}{" "}
                    <img src={EV9} alt="" />
                  </div>
                </div>
                <button
                  disabled={selected === "" ? true : false}
                  onClick={handlenavigate}
                  className="mt-2 fordisable"
                >
                  HABLA CON NOSOTROS
                </button>
              </div>
            </div>
          </div>
        </motion.div>
      </div>
    </>
  );
};

export default Popup;

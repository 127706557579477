import React from "react";
import { Outlet } from "react-router-dom";
import ProfileSidebar from "../../Components/Sidebar/ProfileSidebar";
import NavbarThird from "../../Components/UI/NavbarThird";
import "../../Css/Profile/Yes.css";

const ProfileIndex = () => {
  return (
    <div
      className="white"
      style={{ backgroundColor: "#292d32", minHeight: "100vh", height: "100%" }}
    >
      <NavbarThird />
      <div style={{ marginTop: "101px" }}></div>
      <div className="d-flex gap-3 pt-4 pe-3 ps-3 pb-4">
        <ProfileSidebar />
        <div className="w-100">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default ProfileIndex;

import React from "react";
import { Icons } from "../../Constant/Icons";
import { EV9SpecificationData } from "../Data/Data";

const EV9Specification = ({ onClick }) => {
  return (
    <>
      <div>
        <div className="wraperdiv">
          <div className="carddivmain">
            <div>
              <h1>Especificaciones EV9</h1>
              <span onClick={onClick}>{Icons.newico}</span>
            </div>
            <div className="specidivmain">
              {EV9SpecificationData.map((item, index) => {
                return (
                  <div>
                    <div>
                      <div className="limaindivv">
                        <h4>{item[`info${index + 1}`]}</h4>
                        <div>
                          {Object.entries(item).map(
                            ([key, value]) =>
                              key.startsWith("pra") &&
                              value && <li key={key}>{value}</li>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EV9Specification;

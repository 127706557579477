import React, { useContext } from "react";
import "../../Css/Navbar.css";
import logo from "../../Assets/Images/KiaLogo.png";
import {
  Link,
  NavLink,
  Outlet,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { Fade as Hamburger } from "hamburger-react";
import { useState, useEffect } from "react";
// import { Outlet } from "react-router-dom";
import { useRef } from "react";
import axios from "../../API";
import usericon from "../../Assets/Images/usericon.png";
import { GlobalContext } from "../../Context/GlobalContext";
import { useSelector } from "react-redux";
import ev9img from "../../Assets/EV9/1111 1 (1).png";
import ev9model from "../../Assets/EV9/1111 1 (1).png";
// import Dropdownmodel from "../Modals/Dropdownmodel/Dropdownmodel";
import cart from "../../Assets/cart.png";
import { EV9Modeldata, variant } from "../Data/Data";

const Navbar = () => {
  const Navigate = useNavigate();
  const { setmodelsdropdown, setmodelosoffset, setgalleryModal, setnewev9 } =
    useContext(GlobalContext);
  const [open, setIsopen] = useState(false);
  const [show, setshow] = useState(false);
  const [indexx, setindex] = useState(0);
  const [showmodels, setshowmodels] = useState({ ev6: false, ev9: false });
  const [galleryshow, setgalleryshow] = useState(false);

  let menuref = useRef();

  var UserData = JSON.parse(localStorage.getItem("user-info"));

  const TokenLogout = async () => {
    // console.log("TokenLogout Run success");
    try {
      const res = await axios.get("/get-user-profile", {
        headers: {
          Authorization: `Bearer ${UserData.Token}`,
        },
      });
      // console.log("🚀 Response", res);
    } catch (error) {
      // console.log("🚀 Error", error);
      if (error.message == "Request failed with status code 401") {
        localStorage.removeItem("user-info");
      }
    }
  };

  useEffect(() => {
    if (UserData) {
      TokenLogout();
    }
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setIsopen(false);
          setshow(false);
          // setmodelsdropdown(false);
        }
      }
    };
    document.addEventListener("mousedown", handler);
    document.addEventListener("scroll", handler);

    return () => {
      document.removeEventListener("mousedown", handler);
      document.removeEventListener("scroll", handler);
    };
  }, []);

  const toggle = () => {
    setIsopen(false);
    setshow(false);
  };

  const handlehide = () => {
    setIsopen(!open);
    setshow(false);
  };

  const data = localStorage.getItem("user-info");
  const handlenavigate = () => {
    if (!data) {
      Navigate("/Iniciar-Sesion");
    } else {
      Navigate("/Perfil");
    }
  };

  const handlemouse = () => {
    setmodelsdropdown(true);
    setgalleryModal(false);
  };

  const modelosref = useRef();

  // console.log(modelosref);
  useEffect(() => {
    setmodelosoffset(modelosref?.current?.offsetLeft);
  }, [modelosref]);

  const ModalDataFromRedux = useSelector((state) => state.carData);
  localStorage.setItem("cardindex", indexx);

  const handleclick = (index) => {
    setindex(index);
    setTimeout(() => {
      Navigate("/Model");
    }, 100);
  };

  const handlemodelos = (name) => {
    setshowmodels((prev) => {
      if (name === "ev6") {
        return { ...prev, ev6: !prev.ev6 };
      } else {
        return { ...prev, ev9: !prev.ev9 };
      }
    });
  };

  const pathname = useLocation();

  return (
    <div style={{ position: "relative" }}>
      <div className="mainnav">
        <nav ref={menuref} id="navbar">
          <Link to="/" className="logo">
            <img src={logo} alt="" />
          </Link>
          <ul className={open ? "navlinks mobilemenu" : "navlinks"}>
            <li>
              <NavLink to="/Inicio" onClick={toggle}>
                Inicio
              </NavLink>
            </li>
            <li className="ev9li">
              <span
                // to={window.innerWidth > 1080 ? "/Galeria" : ""}
                onMouseEnter={() => {
                  setmodelsdropdown(false);
                  setgalleryModal(true);
                }}
                style={{
                  borderBottom:
                    pathname.pathname === "/Galeria" && "3px solid #d40000",
                }}
                onClick={() => setgalleryshow((prev) => !prev)}
              >
                Galería
              </span>
              <div
                className="mobilemodels"
                style={{ display: galleryshow ? "" : "none" }}
              >
                {variant?.map((item, index) => {
                  return (
                    <div
                      className="mt-2"
                      onClick={() => Navigate(item.pathname)}
                      key={index}
                    >
                      <span style={{ fontSize: "20px" }}>{item.name}</span>
                    </div>
                  );
                })}
              </div>
            </li>
            <li className="ev6li">
              <span
                // to={window.innerWidth > 1080 ? "/Modelos" : ""}
                onClick={() => handlemodelos("ev6")}
                onMouseEnter={handlemouse}
                ref={modelosref}
                style={{
                  borderBottom:
                    pathname.pathname === "/Modelos" && "3px solid #d40000",
                }}
              >
                Modelos
              </span>
              <div
                className="mobilemodels"
                style={{ display: showmodels.ev6 ? "" : "none" }}
              >
                <h6 style={{ color: "#ffffff" }}>EV9</h6>
                {EV9Modeldata?.map((item, index) => {
                  return (
                    <div onClick={() => Navigate("/Model/EV9")} key={index}>
                      <img src={ev9model} alt="" /> <span>{item.name}</span>
                    </div>
                  );
                })}
                <h6 style={{ color: "#ffffff" }}>EV6</h6>
                {ModalDataFromRedux?.map((item, index) => {
                  return (
                    <div onClick={() => handleclick(index)} key={index}>
                      <img src={item.img} alt="" />
                      <span>{item.name}</span>
                    </div>
                  );
                })}
              </div>
            </li>
            {/* <li className="ev9li">
              <div className="d-flex gap-2" onClick={handlemodelos}>
                <NavLink to="">EV9</NavLink>
              </div>
              <div
                className="mobilemodels"
                style={{ display: showmodels.ev9 ? "" : "none" }}
              >
                <div>
                  <img src={ev9img} alt="" />
                  <button
                    onClick={() => {
                      setnewev9(true);
                      setshow(false);
                      setIsopen(false);
                    }}
                  >
                    ME INTERESA
                  </button>
                </div>
              </div>
            </li> */}

            <li>
              {/* <Link to="exterior" offset={-100} onClick={toggle}>
                360 exterior / interior
              </Link> */}
              <NavLink to={"/DealersParticipantes"} onClick={toggle}>
                {/* Concesionarios autorizados */}
                Concesionarios participantes
              </NavLink>
            </li>
            <li>
              {/* {localStorage.getItem("user-info") ? ( */}
              <NavLink to="/Soporte" onClick={toggle}>
                Contáctanos
              </NavLink>
              {/* ) : null} */}
            </li>
            <li className="formobileonly">
              <NavLink to="/Politica/Terminos-y-Condiciones" onClick={toggle}>
                Términos y Condiciones
              </NavLink>
            </li>
            <li className="formobileonly">
              <NavLink to="/Politica/Privacidad" onClick={toggle}>
                Política de Privacidad
              </NavLink>
            </li>
            <li>
              {localStorage.getItem("user-info") ? (
                <NavLink to="/Perfil" onClick={toggle}>
                  Perfil
                </NavLink>
              ) : null}
            </li>
            <div className="btns">
              {/* {!localStorage.getItem("user-info") && (
                <button
                  className="btn1 offformobile"
                  onClick={() => Navigate("/Modelos")}
                >
                  Reservar
                </button>
              )} */}
              <button
                className="beforeregister offforweb"
                onClick={() => handlenavigate()}
              >
                <img src={usericon} alt="" />
                Regístrate
              </button>
            </div>
          </ul>
          <div className="btns">
            {!data ? (
              <button
                className="beforeregister offformobile"
                onClick={() => handlenavigate()}
              >
                <img src={usericon} alt="" />
                Regístrate
              </button>
            ) : (
              <div onClick={() => handlenavigate()}>
                <img src={usericon} alt="" />
              </div>
            )}
            <span className="btn2" onClick={() => Navigate("/Cart")}>
              {window.innerWidth < 1080 ? <img src={cart} alt="" /> : "Cart"}
              <button>{localStorage.getItem("StaticCartData") ? 1 : 0}</button>
            </span>
          </div>
          <div className="menubtn">
            <Hamburger onToggle={handlehide} toggled={open} />
          </div>
        </nav>
        {/* <Outlet /> */}
      </div>
      <Outlet />
    </div>
  );
};

export default Navbar;

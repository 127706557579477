// import React, { useEffect, useRef, useState } from "react";
import "../../Css/Section1.css";
// import video from "../../Assets/Videos/KiaVideo.mp4";
// import PauseImg from "../../Assets/Images/pouse.png";
// import { useNavigate } from "react-router-dom";
// import fullscreen from "../../Assets/Images/fullscreenbtn.png";
import Carimag from "../../Assets/Images/ev6back.png";
import Newev9 from "../../Assets/EV9/newbanner.png";
import checkimg from "../../Assets/Images/fi-br-check.png";
import { useContext } from "react";
import { GlobalContext } from "../../Context/GlobalContext";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Autoplay, Navigation } from "swiper/modules";
import "swiper/css/pagination";
import "swiper/css/navigation";
import "swiper/css";
import { useNavigate } from "react-router-dom";

// SwiperCore.use([Navigation, Pagination, Autoplay]);

const Section1 = ({ ref }) => {
  const { isExpanded, setnewev9 } = useContext(GlobalContext);
  const Navigate = useNavigate();

  // const [show, setshow] = useState(false);
  // const [play, setplay] = useState(true);
  // const [click, setclick] = useState("0");
  // const [load, setload] = useState(true);
  // const VideoRef1 = useRef();

  // const PlayPauseVideo1 = () => {
  //   if (VideoRef1.current.paused) {
  //     VideoRef1.current.play();
  //     setplay(true);
  //   } else {
  //     VideoRef1.current.pause();
  //     setplay(false);
  //   }
  // };

  // const fullscreens = () => {
  //   let elem = document.getElementById("myvideo");
  //   if (elem.requestFullscreen) {
  //     elem.requestFullscreen();
  //   } else if (elem.webkitRequestFullscreen) {
  //     elem.webkitRequestFullscreen();
  //   } else if (elem.msRequestFullscreen) {
  //     elem.msRequestFullscreen();
  //   }
  // };

  const handlenavigate = () => {
    // localStorage.setItem("clicked",click)
    // setnewev9(true);
    Navigate("/Modelos");
    // const userInfo = localStorage.getItem("user-info");
    // if (userInfo == null) {
    //   Navigate("/Modelos");
    // } else {
    //   Navigate("/Iniciar-Sesion");
    // }
  };

  const ReadMore = ({ text, maxChars = 100 }) => {
    const { isExpanded, setIsExpanded } = useContext(GlobalContext);
    const toggleReadMore = () => {
      setIsExpanded(!isExpanded);
    };

    const displayText = isExpanded ? text : text.slice(0, maxChars);

    return (
      <div className="khainiptag" id="formobile">
        <p
          style={{
            fontSize: "8px",
            lineHeight: "12px",
            color: "#fff",
            marginTop: "10px",
          }}
        >
          {displayText}
          {text.length > maxChars && (
            <span
              style={{ color: "#FFCC42", fontSize: "12px" }}
              onClick={toggleReadMore}
            >
              {isExpanded ? "Leer menos..." : "Leer más..."}
            </span>
          )}
        </p>
      </div>
    );
  };

  const longtext =
    "*Bono de $6,000 ($5,000 distribuidor + $1,000 concesionario) aplica a todas las variantes del Kia EV6 que incluyen CVE2-23 EX, CVGL1-23 GT Line RWD y CV4GL1-23 GT Line AWD, con precios sugeridos de venta de $54,595, $57,695 y $62,795 respectivamente. *$500 de descuento en cargador es válido para un cargador residencial tipo 2; Wallbox (GreenLink). Costos de instalación son adicionales y discutidos directamente con el suplidor; estos costos pueden variar por tipo de residencia. El suplidor aquí mencionado es el certificado por Kia Puerto Rico para la instalación de los cargadores. Cliente no está obligado a realizar la instalación del cargador con el suplidor certificado por Kia Puerto Rico. La instalación del cargador con un suplidor diferente no afecta la garantía del cargador, siempre y cuando el instalador tenga licencia de Perito Electricista y/o esté certificado para manejar este tipo de productos. Kia Puerto Rico y Greenlink no se hacen responsable por cualquier daño ocurrido al cargador, carro u hogar cuando la instalación es realizada por un suplidor no certificado por Kia Puerto Rico. Oferta válida del 1 de diciembre de 2023 hasta el 31 de diciembre del 2024 o mientras dure el inventario. Únicamente para unidades nuevas de Kia EV6 adquiridas durante el periodo de promoción a través del website www.kiaevpr.com o en uno de los once concesionarios Kia autorizados.";
  const EV9longtext =
    "$1,000 en horas de carga con Velocicharge. Aplica a todas las variantes del Kia EV9 que incluyen GT-LINE (Con precio sugerido de venta de $79,795) y LAND. $1,000 de descuento en la instalación de un cargador Wallbox Pulsar Plus 48ª (exclusivamente con Greenlink). Instalación hasta 25’desde el punto de conexión. Precio regular: $1,575 + IVU $181.13. Cupón de Kia Puerto Rico tiene un valor de $1,000, cupón de Greenlink tiene un valor de $75, cliente pagaría $681.13. El suplidor aquí mencionado es el certificado por Kia Puerto Rico para la instalación de los cargadores. Cliente no está obligado a realizar la instalación del cargador con el suplidor certificado por Kia Puerto Rico. La instalación del cargador con un suplidor diferente no afecta la garantía del cargador, siempre y cuando el instalador tenga licencia de Perito Electricista y/o esté certificado para manejar este tipo de productos. Kia Puerto Rico y Greenlink no se hacen responsable por cualquier daño ocurrido al cargador, carro u hogar cuando la instalación es realizada por un suplidor no certificado por Kia Puerto Rico. Oferta válida del 1 de junio de 2024 hasta el 31 de diciembre del 2024 o mientras dure el inventario. Únicamente para unidades nuevas de Kia EV9 adquiridas durante el periodo de promoción a través del website https://www.kiaevpr.com o en los concesionarios Kia autorizados.";
  return (
    <div ref={ref}>
      <div className="d-flex justify-content-center" id="Inicio">
        <Swiper
          slidesPerView={1}
          loop={true}
          // autoplay={{ delay: 3000, disableOnInteraction: false }}
          modules={[Pagination, Autoplay, Navigation]}
          navigation={true}
          pagination={true}
          // className="mySwiper"
        >
          <SwiperSlide>
            <div className="container1">
              <div className={isExpanded ? "videoparentdiv" : "Mobileview"}>
                <img src={Newev9} alt="" />
              </div>
              <div className="Detailsev9">
                <h4>Kia EV9 </h4>
                <button
                  className="detail9btn"
                  onClick={() => Navigate("/Modelos/EV9")}
                >
                  {" "}
                  Me interesa{" "}
                </button>
                <div className="Incluyediv">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                    className="khainiptag2"
                  >
                    <img style={{ width: "40px" }} src={checkimg} alt="" />
                    <h4
                      style={{
                        fontSize: "24px",
                        lineHeight: "25px",
                        width: "100%",
                        color: "#fff",
                      }}
                    >
                      {/* Escoge entre $1,000 de descuento en instalación de
                      cargador Wallbox Pulsar Plus 48ª u horas de carga con
                      Velocicharge. */}
                      Escoge entre $1,000 de descuento en instalación de
                      cargador Wallbox Pulsar Plus 48ª* u horas de carga con
                      Velocicharge.
                    </h4>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center ",
                      gap: "1rem",
                    }}
                  >
                    <img style={{ width: "40px" }} src={checkimg} alt="" />
                    <h4
                      style={{
                        fontSize: "24px",
                        lineHeight: "25px",
                        width: "100%",
                        color: "#fff",
                      }}
                    >
                      Garantía de 10 años / 100,000 millas{" "}
                    </h4>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center ",
                      gap: "1rem",
                    }}
                  >
                    <img style={{ width: "40px" }} src={checkimg} alt="" />
                    <h4
                      style={{
                        fontSize: "24px",
                        lineHeight: "25px",
                        width: "75%",
                        color: "#fff",
                      }}
                    >
                      Asistencia en la carretera con recarga de batería en el
                      lugar. Incluida por 3 años.
                    </h4>
                  </div>
                </div>
                <div className="khainiptag" id="forweb">
                  <p
                    style={{
                      fontSize: "8px",
                      lineHeight: "12px",
                      color: "#fff",
                      marginTop: "10px",
                    }}
                  >
                    {EV9longtext}
                  </p>
                </div>
                <ReadMore text={EV9longtext} maxChars={446} />
              </div>
              {/* <div className="Detailsev9">
                <h4>
                  <span>EV9?</span>
                </h4>
                <button
                  className="detail9btn"
                  onClick={() => Navigate("/Modelos/EV9")}
                >
                  {" "}
                  Me interesa{" "}
                </button>
              </div> */}
            </div>
          </SwiperSlide>
          <SwiperSlide>
            <div className="container1">
              <div className={isExpanded ? "videoparentdiv" : "Mobileview"}>
                {/* <video
              id="myvideo"
              ref={VideoRef1}
              autoPlay={true}
              muted
              loop={true}
            >
              <source  src={video} />
            </video> */}
                <img src={Carimag} alt="" />
              </div>
              <div className="pfbtns">
                {/* <div onClick={PlayPauseVideo1} className="playpousebtn">
              {!play ? (
                <svg
                  className="play-svg"
                  viewBox="0 0 46 46"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M37.3751 21.3323L10.5418 5.84565C10.2504 5.67743 9.91988 5.58887 9.58343 5.58887C9.24698 5.58887 8.91647 5.67743 8.6251 5.84565C8.3326 6.01452 8.08993 6.25767 7.92164 6.5505C7.75335 6.84332 7.66542 7.17542 7.66676 7.51315V38.4865C7.66542 38.8242 7.75335 39.1563 7.92164 39.4491C8.08993 39.742 8.3326 39.9851 8.6251 40.154C8.91647 40.3222 9.24698 40.4108 9.58343 40.4108C9.91988 40.4108 10.2504 40.3222 10.5418 40.154L37.3751 24.6673C37.6701 24.5002 37.9154 24.2577 38.0861 23.9648C38.2568 23.6718 38.3467 23.3389 38.3467 22.9998C38.3467 22.6608 38.2568 22.3278 38.0861 22.0349C37.9154 21.7419 37.6701 21.4995 37.3751 21.3323V21.3323ZM11.5001 35.1707V10.829L32.5834 22.9998L11.5001 35.1707Z"
                    fill="white"
                  />
                </svg>
              ) : (
                <img src={PauseImg} width={"100%"} alt="" />
              )}
            </div> */}
                {/* <div onClick={fullscreens} className="fullscreen">
              <img src={fullscreen} alt="" />
            </div> */}
              </div>
              <div className="Details">
                <h4>Kia EV6 </h4>
                <h1>
                  BONO de <span>$6,000</span>
                </h1>
                <h4>Incluye:</h4>
                <div className="Incluyediv">
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "1rem",
                    }}
                  >
                    <img style={{ width: "40px" }} src={checkimg} alt="" />
                    <h4
                      style={{
                        fontSize: "24px",
                        lineHeight: "25px",
                        width: "100%",
                        color: "#fff",
                      }}
                    >
                      {/* Cargador Wallbox 220v
                  <br />
                  incluido* */}
                      $500 de descuento en <br /> cargador 220v nivel 2*
                    </h4>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center ",
                      gap: "1rem",
                    }}
                  >
                    <img style={{ width: "40px" }} src={checkimg} alt="" />
                    <h4
                      style={{
                        fontSize: "24px",
                        lineHeight: "25px",
                        width: "100%",
                        color: "#fff",
                      }}
                    >
                      {/* Garantía de 10 años/ 100,000 millas */}
                      Garantía de 10 años / 100,000 millas
                    </h4>
                  </div>
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center ",
                      gap: "1rem",
                    }}
                  >
                    <img style={{ width: "40px" }} src={checkimg} alt="" />
                    <h4
                      style={{
                        fontSize: "24px",
                        lineHeight: "25px",
                        width: "100%",
                        color: "#fff",
                      }}
                    >
                      Asistencia en la carretera con recarga de <br /> batería
                      en el lugar. Incluida por 3 años.
                    </h4>
                  </div>
                </div>
                <button
                  className="reserbtn"
                  // style={{ width: "100px" }}
                  onClick={() => handlenavigate()}
                >
                  Activar bono
                </button>
                {/* <h1>Nuevo y totalmente eléctrico</h1> */}
                <div className="khainiptag" id="forweb">
                  <p
                    style={{
                      fontSize: "8px",
                      lineHeight: "12px",
                      color: "#fff",
                      marginTop: "10px",
                    }}
                  >
                    {longtext}
                  </p>
                </div>
                <ReadMore text={longtext} maxChars={446} />
              </div>
            </div>
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default Section1;

import React, { useEffect, useState } from "react";
import Wrapper from "../../Components/UI/Wrapper";
import Card from "../../Components/UI/Card";
import "../../Css/Auth/Register.css";
import Input from "../../Components/UI/Input";
import { useFormik } from "formik";
import { RegisterNoSchema } from "../../Schemas";
import Button from "../../Components/UI/Button";
import { useNavigate } from "react-router-dom";
import axios from "../../API";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { toast } from "react-hot-toast";

const RegisterNo = () => {
  const navigate = useNavigate();
  const [show, setshow] = useState(false);
  const [Loading, setLoading] = useState(false);
  const [AccountHolder, setAccountHolder] = useState("Yes");
  const Token = JSON.parse(localStorage.getItem("Register-token"));
  const UserData = JSON.parse(localStorage.getItem("user-info"));

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  const initialValues = {
    Name: "",
    Surname: "",
    Email: "",
    Number: "",
    // AccountHolder: AccountHolder,
  };

  const onSubmit = async () => {
    setLoading(true);
    // console.log(AccountHolder)
    try {
      const response = await axios.post(
        // "/register-step-3",
        "/customer-register-step-3",
        {
          AccountHolderName: values.Name,
          AccountHolderSurName: values.Surname,
          AccountHolderMobileNumber: values.Number,
          AccountHolderEmail: values.Email,
          AccountHolder: AccountHolder,
          RegsterId: UserData.Id,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      // console.log(response)
      if (response) {
        setLoading(false);
      }
      if (response.data.message == "Step 3 Registration Successfully") {
        toast.success(`Paso 3 Registro Exitoso`);
        navigate("/RegistratePayment");
      }
    } catch (error) {
      // console.log("error....", error)
      setLoading(false);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: show ? RegisterNoSchema : null,
      onSubmit,
    });

  return (
    <div>
      <Wrapper>
        <Card>
          <form action="" onSubmit={handleSubmit} className={"tutaler"}>
            <h1>Titular</h1>
            <div id="check">
              <span>¿Serás el titular del vehículo?</span>
              <div className="form-group2">
                <div onClick={() => setAccountHolder("Yes")}>
                  <input
                    onClick={() => setshow(false)}
                    type="radio"
                    name="RadioYesNo"
                    id="radio1"
                    value={"Yes"}
                    className="radio"
                    defaultChecked
                  />
                  <label htmlFor="radio1">Sí</label>
                </div>
                <div onClick={() => setAccountHolder("No")}>
                  <input
                    onClick={() => setshow(true)}
                    type="radio"
                    name="RadioYesNo"
                    id="radio2"
                    value={"No"}
                    className="radio"
                  />
                  <label htmlFor="radio2">No</label>
                </div>
              </div>
            </div>
            <div>
              {/* {!show && (
                <div className="tulerbtn">
                  <Button variant={"red"} type={"submit"}>
                    Continuar
                  </Button>
                </div>
              )} */}
              {show && (
                <div className="inputmaindivvv">
                  <div className="inputsdiv">
                    <Input
                      value={values.Name}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="Name"
                      text={"Nombre"}
                      type={"text"}
                      placeholder={"Nombre del titular"}
                    />
                    {errors.Name && touched.Name ? (
                      <p className="form-error"> {errors.Name} </p>
                    ) : null}
                  </div>
                  <div className="inputsdiv">
                    <Input
                      value={values.Surname}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="Surname"
                      text={"Apellidos"}
                      type={"text"}
                      placeholder={"Apellidos del titular"}
                    />
                    {errors.Surname && touched.Surname ? (
                      <p className="form-error"> {errors.Surname} </p>
                    ) : null}
                  </div>
                  <div className="inputsdiv">
                    <Input
                      value={values.Email}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="Email"
                      text={"Correo electrónico"}
                      type={"email"}
                      placeholder={"Ingresa tu correo electrónico"}
                    />
                    {errors.Email && touched.Email ? (
                      <p className="form-error"> {errors.Email} </p>
                    ) : null}
                  </div>
                  <div className="inputsdiv">
                    <Input
                      value={values.Number}
                      onChange={handleChange}
                      onBlur={handleBlur}
                      name="Number"
                      text={"Teléfono"}
                      type={"number"}
                      placeholder={"Escribe tu teléfono"}
                    />
                    {errors.Number && touched.Number ? (
                      <p className="form-error"> {errors.Number}</p>
                    ) : null}
                  </div>
                </div>
              )}
            </div>
            <div className="tulerbtn">
              <Button variant={"red"} type={"submit"}>
                Continuar
              </Button>
            </div>
          </form>
        </Card>
      </Wrapper>
    </div>
  );
};

export default RegisterNo;

import React, { useContext } from "react";
import "./Chatbot.css";
import close from "../../Assets/Images/close.png";
import { GlobalContext } from "../../Context/GlobalContext";

const Chatbot = () => {
  const { setchatbotshow } = useContext(GlobalContext);

  return (
    <div className="separatecoontainer">
      <div className="chatbotdiv">
        <img src={close} alt="" onClick={() => setchatbotshow(false)} />
        <iframe
          title="chatbot"
          className="chatbot"
          src="https://app.five9.com/clients/consoles/ChatConsole/index.html?title=Kia%20Puerto%20Rico&tenant=ICQ24%20(reseller)%20-%20MAmbar%20TCPA&profiles=Kia%20Chat&showProfiles=false&autostart=true&theme=default-theme.css&logo=https%3A%2F%2Fkianoticias.com%2Fimages%2F2021%2Fkia-white.svg&surveyOptions=%7B%22showComment%22%3Atrue%2C%22requireComment%22%3Afalse%7D&fields=%7B%22name%22%3A%7B%22value%22%3A%22%22%2C%22show%22%3Atrue%2C%22label%22%3A%22Name%22%7D%2C%22email%22%3A%7B%22value%22%3A%22%22%2C%22show%22%3Atrue%2C%22label%22%3A%22Email%22%7D%2C%22Telephone%22%3A%7B%22value%22%3A%22%22%2C%22show%22%3Atrue%2C%22label%22%3A%22Telephone%22%2C%22required%22%3Atrue%7D%2C%22UserLocale%22%3A%7B%22value%22%3A%22en%22%2C%22show%22%3Afalse%7D%7D&playSoundOnMessage=true&allowCustomerToControlSoundPlay=false&showEmailButton=true&useBusinessHours=false&showPrintButton=false&allowUsabilityMenu=false&enableCallback=false&callbackList=&allowRequestLiveAgent=false"
          frameborder="0"
        ></iframe>
      </div>
    </div>
  );
};

export default Chatbot;

/* eslint-disable eqeqeq */
import React, { useState, useEffect, useContext } from "react";
import "../../Css/Car/ContinuePayment.css";
// import img from "../../Assets/Images/Kia-ev-6.png";
// import img from "../../Assets/Images/greenCar.png";
import Button, { Bonobtn } from "../../Components/UI/Button";
import kialogo from "../../Assets/Images/KiaLogo.png";
import { useNavigate } from "react-router-dom";
import Specification from "../../Components/CarDetail/Specification";
import Specificationtwo from "../../Components/CarDetail/Specificationtwo";
import Specificationthree from "../../Components/CarDetail/Specificationthree";
import { motion } from "framer-motion";
import axios from "../../API";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import img1 from "../../Assets/EV6 EX RWD/CV_BL_Front_Yacht_Blue_DU3.png";
import img2 from "../../Assets/EV6 EX RWD/CV_BL_Front_Gravity_Blue_B4U.png";
import img3 from "../../Assets/EV6 EX RWD/CV_BL_Front_Moonscape_Matte_KLM.png";
import img4 from "../../Assets/EV6 EX RWD/CV_BL_Front_Steel_Gray_KLG.png";
import img5 from "../../Assets/EV6 EX RWD/CV_BL_Front_Snow_White_Pearl_SWP.png";
import img6 from "../../Assets/EV6 EX RWD/CV_BL_Front_Glacier_GLB.png";
import img7 from "../../Assets/EV6 EX RWD/CV_BL_Front_Runway_Red_CR5.png";
import img8 from "../../Assets/EV6 EX RWD/CV_BL_Front_Aurora_Black Pearl_ABP.png";
import img9 from "../../Assets/EV6 EX RWD/CV_BL_Front_Urban_Yellow_UBY.png";
import img10 from "../../Assets/EV6 EX RWD/CV_BL_Front_Interstella_Gray_AGT.png";

import img11 from "../../Assets/GT LINE/CV_GT_Line_Front_Yacht_Blue_DU3-removebg-preview.png";
import img13 from "../../Assets/GT LINE/CV_GT_Line_Front_Moonscape_Matte_KLM-removebg-preview.png";
import img15 from "../../Assets/GT LINE/CV_GT_Line_Front_Snow_White_Pearl_SWP-removebg-preview.png";
import img17 from "../../Assets/GT LINE/CV_GT_Line_Front_Runway_Red_CR5-removebg-preview.png";
import img18 from "../../Assets/GT LINE/CV_GT_Line_Front_Aurora_Black_Pearl_ABP-removebg-preview.png";

import NewImg1 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Yacht_Blue_DU3.png";
import NewImg2 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Runway_Red_CR5.png";
import NewImg3 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Aurora_Black Pearl_ABP.png";
import NewImg4 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Moonscape_Matte_KLM.png";
import NewImg5 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Snow_White_Pearl_SWP.png";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { Modal } from "react-bootstrap";
import { Store } from "../../Redux/MainStore";
import { useSelector } from "react-redux";
import { ActivateOfferById } from "../../Redux/CarSlice";
import ApiHook from "../../Components/Hook/ApiHook";
import "../../Css/Car/ConfirmOrder.css";
import { handleOffer } from "../Modelos/Modelos";
import { GlobalContext } from "../../Context/GlobalContext";
import EV9Specification from "../../Components/EV9Detail/EV9Specification";
import { imagesdata } from "../../Components/Data/Data";

const ContinuePayment = () => {
  const location = useLocation();
  const ModalDataFromRedux = useSelector((state) => state.carData);
  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  const Navigate = useNavigate();
  // const [Specs, setSpecs] = useState(false);
  // const [Specs2, setSpecs2] = useState(false);
  // const [Specs3, setSpecs3] = useState(false);
  const [OrderHistory, setOrderHistory] = useState([]);
  const [show, setshow] = useState(false);

  // Get User Data
  const UserData = JSON.parse(localStorage.getItem("user-info"));

  const StaticCartData = JSON.parse(localStorage.getItem("StaticCartData"));

  // Store Api Data in useState Variable
  const [CartData, setCartData] = useState([]);

  // Api Error Data useState Variable
  const [ApiError, setApiError] = useState("");

  // Loader useState Variable
  const [Loading, setLoading] = useState(true);

  // Address add Modal useState Variable
  const [ModalShow, setModalShow] = useState(false);

  // User Profile Data useState Variable
  const [UserProfileData, setUserProfileData] = useState({});

  var nf = new Intl.NumberFormat();

  const { setD4000Show } = useContext(GlobalContext);

  function Alertmodel(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
      >
        <Modal.Header
        //  closeButton
        ></Modal.Header>
        <Modal.Body className="white text-center">
          <h4 className="pt-2 pb-2">Alerta!</h4>
          <p className="Modal-text">
            Su registro está incompleto para continuar por favor registre su
            dirección
          </p>
          <Button
            variant={"red"}
            onClick={() => Navigate("/RegistrateAddress")}
          >
            Dirección de registro
          </Button>
          <div className="p-2"></div>
        </Modal.Body>
      </Modal>
    );
  }

  const fromCartdata = ModalDataFromRedux.filter(
    (item) => item.name == CartData?.data?.Model
  );

  const StaticfromCartdata = ModalDataFromRedux.filter(
    (item) => item.name == StaticCartData?.Model
  );

  // console.log(StaticCartData);

  const AddtoCart = async () => {
    try {
      setLoading(true);
      const response = await axios.post(
        "/add-to-cart",
        {
          ExtColorName: StaticCartData?.ExtColorName,
          IntColorName: StaticCartData?.IntColorName,
          ExtColorCode: StaticCartData?.ExtColorCode,
          IntColorCode: StaticCartData?.IntColorCode,
          Price:
            StaticCartData?.Mainmodel === "EV6"
              ? +StaticfromCartdata[0]?.finalPrice.slice(1).replace(",", "")
              : StaticCartData?.Price,
          Model: StaticCartData?.Model,
          Aros_Type: StaticCartData?.Aros_Type,
          Type: StaticCartData?.Mainmodel,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData.Token}`,
          },
        }
      );
      // console.log(response);
      setLoading(false);
      if (response) {
        if (response.data.status == "success") {
          localStorage.removeItem("StaticCartData");
          getCartData();
        }
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  const Updatcart = async () => {
    try {
      const response = await axios.post(
        "/update-cart",
        {
          OrderId: CartData?.data?.order_id,
          Price: +fromCartdata[0]?.finalPrice.slice(1).replace(",", ""),
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData.Token}`,
          },
        }
      );
      // console.log(response);
      // if (response) {
      //   if (response.data.status == "success") {
      //     localStorage.removeItem("StaticCartData");
      //     getCartData();
      //   }
      // }
      // console.log(response);
    } catch (error) {
      // console.log(error);
    }
  };

  // console.log(StaticCartData);

  useEffect(() => {
    return () => {
      Updatcart();
    };
  }, [fromCartdata[0]?.finalPrice]);

  const getUserProfile = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/get-user-profile", {
        headers: { Authorization: `Bearer ${UserData?.Token}` },
      });
      // console.log("🚀 Contrato ~ getUserProfile ~ res:", res);
      setLoading(false);
      setUserProfileData(res.data);
      AddtoCart();
    } catch (error) {
      setLoading(false);
      // console.log(error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("user-info")) {
      Navigate("/Modelos");
    } else {
      getUserProfile();
    }
  }, []);

  const getCartData = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/get-cart", {
        headers: {
          Authorization: `Bearer ${UserData.Token}`,
        },
      });
      console.log("🚀 ~ file: ContinuePayment.js:36 ~ getCartData ~ res", res);
      // if (res.data.status == "success" && res.data.message == "Record") {
      setLoading(false);
      if (res) {
        setCartData(res.data);
        setOrderHistory(res.data);
      }
    } catch (error) {
      setLoading(false);
      setApiError(error);
      console.log(error);
    }
  };

  useEffect(() => {
    if (!localStorage.getItem("user-info")) {
      Navigate("/Modelos");
    } else {
      getCartData();
    }
  }, []);

  const colorcode = imagesdata.filter(
    (item) => item.colorcode === CartData?.data?.Ext_Color_Code
  );

  let images;
  if (CartData.data) {
    if (CartData.data.ModelType === "EV6") {
      if (CartData.data.Model == "CVE1" || "EV6 EX RWD") {
        if (CartData.data.Ext_Color_Code == "#1A30AD") {
          images = img1;
        } else if (CartData.data.Ext_Color_Code == "#34425F") {
          images = img2;
        } else if (CartData.data.Ext_Color_Code == "#666666") {
          images = img3;
        } else if (CartData.data.Ext_Color_Code == "#9C9EA0") {
          images = img4;
        } else if (CartData.data.Ext_Color_Code == "#C6C4B7") {
          images = img5;
        } else if (CartData.data.Ext_Color_Code == "#F9F9F9") {
          images = img6;
        } else if (CartData.data.Ext_Color_Code == "#933531") {
          images = img7;
        } else if (CartData.data.Ext_Color_Code == "#1B1B1B") {
          images = img8;
        } else if (CartData.data.Ext_Color_Code == "#CD8700") {
          images = img9;
        } else if (CartData.data.Ext_Color_Code == "#4F5354") {
          images = img10;
        }
      } else if (CartData.data.Model == "GT-LINE RWD") {
        if (CartData.data.Ext_Color_Code == "#1A30AD") {
          images = NewImg1;
        } else if (CartData.data.Ext_Color_Code == "#933531") {
          images = NewImg2;
        } else if (CartData.data.Ext_Color_Code == "#1B1B1B") {
          images = NewImg3;
        } else if (CartData.data.Ext_Color_Code == "#666666") {
          images = NewImg4;
        } else if (CartData.data.Ext_Color_Code == "#C6C4B7") {
          images = NewImg5;
        }
      } else if (CartData.data.Model == "EV6 GT-LINE (E-AWD)") {
        if (CartData.data.Ext_Color_Code == "#1A30AD") {
          images = img11;
        } else if (CartData.data.Ext_Color_Code == "#933531") {
          images = img17;
        } else if (CartData.data.Ext_Color_Code == "#1B1B1B") {
          images = img18;
        } else if (CartData.data.Ext_Color_Code == "#666666") {
          images = img13;
        } else if (CartData.data.Ext_Color_Code == "#C6C4B7") {
          images = img15;
        }
      }
    } else if (CartData.data.ModelType === "EV9") {
      if (CartData.data.Ext_Color_Code === colorcode[0]?.colorcode) {
        images = colorcode[0]?.images[5];
      }
    }
  }

  const handlenavi = () => {
    // console.log(OrderHistory.data);
    if (OrderHistory.data.Model == "EV6 EX RWD") {
      setshow(true);
    } else if (OrderHistory.data.Model == "GT-LINE RWD") {
      setshow(true);
    } else if (OrderHistory.data.Model == "EV6 GT-LINE (E-AWD)") {
      setshow(true);
    } else if (OrderHistory.data.Model == "EV9 GT-LINE") {
      setshow(true);
    }
  };

  // console.log(fromCartdata[0].finalPrice);
  const { isloading } = ApiHook("/get-offer-status");
  const UpdateOffer = async (item) => {
    try {
      const res = await axios.get("/update-offer-status", {
        headers: {
          Authorization: `Bearer ${UserData.Token}`,
          "Content-Type": "application/json",
        },
      });
      Store.dispatch(ActivateOfferById(res.data.message));
    } catch (error) {
      // seterror(error);
    } finally {
      // setisloading(false);
    }
  };

  return (
    <>
      <Alertmodel show={ModalShow} onHide={() => setModalShow(false)} />
      {!show ? (
        <div className="white Main-Sec-ContinuePayment">
          {Loading && (
            <div>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
                // onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          )}
          {!Loading &&
            CartData.status == "success" &&
            CartData.message == "Record" && (
              <>
                <div className="pt-5 pb-5">
                  <img
                    src={kialogo}
                    style={{ cursor: "pointer" }}
                    onClick={() => Navigate("/Inicio")}
                    alt="img"
                  />
                </div>
                <div className="w-90 head-ContinuePay">Revisar y pagar</div>
                <div className="ContinuePay-body">
                  <div className="ContinuePay-body-image  d-flex justify-content-center align-items-center w-100">
                    <img src={images} width="90%" />
                  </div>
                  <div className="ContinuePay-body-content">
                    <div className="ModelName-Price-div">
                      <span>
                        Model: {CartData && CartData.data.Model}{" "}
                        {CartData.data.ModelType === "EV6" &&
                          CartData.data.Mng_Year}
                      </span>
                    </div>
                    <div className="Purchase-price-specs-div">
                      {CartData.data.ModelType === "EV6" ? (
                        <>
                          <p className="OrderText" style={{ margin: "0" }}>
                            MSRP:{" "}
                            <span
                              className={
                                fromCartdata[0]?.isOfferActivated &&
                                "line-through"
                              }
                            >
                              {fromCartdata[0]?.price}
                            </span>
                          </p>
                          {fromCartdata[0]?.isOfferActivated && (
                            <p style={{ fontSize: "12px" }}>
                              BONO
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setD4000Show(true);
                                }}
                              >
                                $5,000{" "}
                              </span>
                              <span
                                style={{ fontSize: "20px", color: "#F3C300" }}
                              >
                                {fromCartdata[0]?.finalPrice}
                              </span>
                            </p>
                          )}
                          {fromCartdata[0]?.isOfferActivated ? (
                            <Bonobtn
                              className={"bonobutton"}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleOffer(fromCartdata[0]);
                              }}
                            >
                              Bono Activado
                            </Bonobtn>
                          ) : (
                            <>
                              {isloading ? (
                                <p className="loadingchalu">Loading...</p>
                              ) : (
                                <Button
                                  variant={"reserbtn2"}
                                  style={{ backgroundColor: "#878787" }}
                                  onClick={(event) => {
                                    event.stopPropagation();
                                    handleOffer(fromCartdata[0]);
                                  }}
                                >
                                  Activar bono
                                </Button>
                              )}
                            </>
                          )}
                        </>
                      ) : (
                        <p>MSRP: ${CartData.data.Price}</p>
                      )}
                      <div className="specs-div">
                        <span>
                          Color exterior:{" "}
                          {CartData && CartData.data.Ext_Color_Name}
                        </span>
                        <span>
                          Color interior:{" "}
                          {CartData && CartData.data.Int_Color_Name}
                        </span>
                        <span>
                          <span className="dollor-txt">
                            {/* Total de la reserva $ */}
                            Depósito $
                          </span>
                          500
                        </span>
                        <div className="pb-3"></div>
                        {/* {CartData.data.ModelType === "EV6" && ( */}
                        <div className="additionalSpecs">
                          <button
                            className="additionalSpecs-btn"
                            onClick={handlenavi}
                            type="button"
                          >
                            Especificaciones adicionales
                          </button>
                        </div>
                        {/* )} */}
                      </div>
                    </div>
                    <div className="w-100">
                      <Button
                        variant={"red"}
                        onClick={
                          UserProfileData?.data?.AddressLine1 == null
                            ? () => setModalShow(true)
                            : () => Navigate("/Contrato")
                        }
                      >
                        <span className="btn-space">Continuar con el pago</span>
                      </Button>
                    </div>
                    {CartData.data.ModelType === "EV6" && (
                      <p
                        style={{
                          fontSize: "10px",
                        }}
                      >
                        *Bono de $5,000 ($4,000 distribuidor + $1,000
                        concesionario) aplica a todas las variantes del Kia EV6
                        que incluyen CVE2-23 EX, CVGL1-23 GT Line RWD y
                        CV4GL1-23 GT Line AWD, con precios sugeridos de venta de
                        $54,595, $57,695 y $62,795 respectivamente. *$500 de
                        descuento en cargador es válido para un cargador
                        residencial tipo 2; Wallbox (GreenLink) o Wise (Glenn
                        International). Costos de instalación son adicionales y
                        discutidos directamente con el suplidor de su elección;
                        estos costos pueden variar por tipo de residencia. Los
                        suplidores aquí mencionados son los certificados por Kia
                        Puerto Rico para la instalación de los cargadores.
                        Cliente no está obligado a realizar la instalación del
                        cargador con los suplidores certificados por Kia Puerto
                        Rico. La instalación del cargador con un suplidor
                        diferente no afecta la garantía del cargador, siempre y
                        cuando el instalador tenga licencia de Perito
                        Electricista y/o esté certificado para manejar este tipo
                        de productos. Kia Puerto Rico, Glenn y Greenlink no se
                        hacen responsable por cualquier daño ocurrido al
                        cargador, carro u hogar cuando la instalación es
                        realizada por un suplidor no certificado por Kia Puerto
                        Rico. Oferta válida del 1 de diciembre de 2023 hasta el
                        31 de enero del 2024 o mientras dure el inventario.
                        Únicamente para unidades nuevas de Kia EV6 adquiridas
                        durante el periodo de promoción a través del website
                        www.kiaevpr.com o en uno de los once concesionarios Kia
                        autorizados.
                      </p>
                    )}
                  </div>
                </div>
              </>
            )}
        </div>
      ) : (
        <motion.div
        // initial={{ opacity: 0 }}
        // whileInView={{ opacity: 1 }}
        // transition={{ duration: 0.5 }}
        >
          {OrderHistory?.data.Model == "EV6 EX RWD" ? (
            <Specification onClick={() => setshow(false)} />
          ) : null}
          {OrderHistory?.data.Model == "GT-LINE RWD" ? (
            <Specificationtwo onClick={() => setshow(false)} />
          ) : null}
          {OrderHistory.data.Model == "EV6 GT-LINE (E-AWD)" ? (
            <Specificationthree onClick={() => setshow(false)} />
          ) : null}
          {OrderHistory.data.Model == "EV9 GT-LINE" ? (
            <EV9Specification onClick={() => setshow(false)} />
          ) : null}
        </motion.div>
      )}
    </>
  );
};

export default ContinuePayment;

export const Icons = {
  ico: (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20 18.3333V26.6667M20 35C11.7157 35 5 28.2843 5 20C5 11.7157 11.7157 5 20 5C28.2843 5 35 11.7157 35 20C35 28.2843 28.2843 35 20 35ZM20.083 13.3333V13.5L19.917 13.5V13.3333H20.083Z"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),
  icooo: (
    <svg
      width="16"
      height="17"
      viewBox="0 0 16 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3503_28663)">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.34056 13.2726C5.39072 13.3582 5.4689 13.4225 5.5579 13.4516C5.6469 13.4806 5.73944 13.472 5.81516 13.4276L12.5485 9.47798L11.9416 12.5605C11.9235 12.6525 11.9416 12.7523 11.9918 12.8379C12.042 12.9235 12.1203 12.9879 12.2094 13.017C12.2985 13.0461 12.3912 13.0375 12.467 12.993C12.5428 12.9485 12.5955 12.8719 12.6136 12.7799L13.3846 8.86104C13.3936 8.81549 13.3938 8.76754 13.3853 8.71993C13.3767 8.67232 13.3594 8.62599 13.3346 8.58358C13.3097 8.54117 13.2777 8.50353 13.2403 8.4728C13.2029 8.44208 13.161 8.41887 13.1168 8.40452L9.32006 7.16495C9.23094 7.13586 9.13829 7.14449 9.06248 7.18896C8.98667 7.23343 8.93391 7.31009 8.91581 7.40207C8.89772 7.49405 8.91576 7.59382 8.96598 7.67944C9.0162 7.76505 9.09448 7.82949 9.1836 7.85859L12.1703 8.83317L5.43693 12.7828C5.36122 12.8272 5.30853 12.9038 5.29045 12.9956C5.27238 13.0875 5.29041 13.1871 5.34056 13.2726ZM10.6865 4.07026C10.7366 4.15576 10.7547 4.25541 10.7366 4.34727C10.7185 4.43914 10.6658 4.5157 10.5901 4.56011L3.85674 8.50973L6.84346 9.48431C6.88758 9.49872 6.92951 9.52196 6.96685 9.5527C7.00419 9.58343 7.03621 9.62107 7.06107 9.66347C7.08594 9.70586 7.10317 9.75217 7.11177 9.79976C7.12038 9.84735 7.1202 9.89529 7.11124 9.94084C7.10228 9.98638 7.08472 10.0286 7.05955 10.0652C7.03439 10.1018 7.00212 10.1319 6.96458 10.1539C6.92704 10.176 6.88497 10.1894 6.84078 10.1935C6.79658 10.1977 6.75112 10.1924 6.707 10.178L2.91024 8.93839C2.86609 8.92403 2.82413 8.90083 2.78676 8.8701C2.7494 8.83938 2.71736 8.80173 2.69248 8.75932C2.66761 8.71692 2.65038 8.67058 2.6418 8.62297C2.63322 8.57536 2.63344 8.52741 2.64246 8.48187L3.41342 4.563C3.43152 4.47102 3.48427 4.39436 3.56008 4.3499C3.63589 4.30543 3.72855 4.29679 3.81767 4.32589C3.90678 4.35498 3.98506 4.41942 4.03528 4.50504C4.0855 4.59065 4.10354 4.69042 4.08545 4.78241L3.47852 7.86493L10.2119 3.91531C10.2876 3.8709 10.3802 3.86227 10.4692 3.89133C10.5582 3.92039 10.6363 3.98475 10.6865 4.07026Z"
          fill="white"
        />
      </g>
      <defs>
        <clipPath id="clip0_3503_28663">
          <rect
            width="10.5911"
            height="11.9607"
            fill="white"
            transform="translate(0.419922 6.19226) rotate(-30.3947)"
          />
        </clipPath>
      </defs>
    </svg>
  ),

  newico: (
    <svg
      width="14"
      height="14"
      viewBox="0 0 14 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13 13L1 1M13 1L1 13"
        stroke="white"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  ),

  ratangle: (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="62"
      height="53"
      viewBox="0 0 62 53"
      fill="none"
    >
      <path d="M31 0L61.3109 52.5H0.689112L31 0Z" fill="#9F9F9F" />
    </svg>
  ),
  infoicon: (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15 13.75V20M15 26.25C8.7868 26.25 3.75 21.2132 3.75 15C3.75 8.7868 8.7868 3.75 15 3.75C21.2132 3.75 26.25 8.7868 26.25 15C26.25 21.2132 21.2132 26.25 15 26.25ZM15.0623 10V10.125L14.9377 10.125V10H15.0623Z"
        stroke="white"
        strokeWidth="2"
        strokeLinejoin="round"
      />
    </svg>
  ),

  tick: (
    <svg
      width="20"
      height="14"
      viewBox="0 0 20 14"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.01842 13.3077C6.68655 13.3077 6.35534 13.1831 6.1019 12.9333L0 6.91867L1.83303 5.11122L7.01842 10.2224L17.3892 0L19.2222 1.80745L7.93494 12.9333C7.6815 13.1831 7.35028 13.3077 7.01842 13.3077Z"
        fill="#D40000"
      />
    </svg>
  ),

  BlackWhite_Color: (
    <svg
      width="37"
      height="36"
      viewBox="0 0 62 61"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g filter="url(#filter0_d_309_4555)">
        <ellipse
          cx="31"
          cy="26.5"
          rx="26.5"
          ry="27"
          transform="rotate(-90 31 26.5)"
          fill="#1B1B1B"
        />
        <path
          d="M31 0.500003C45.6444 0.500002 57.5 12.1494 57.5 26.5C57.5 40.8506 45.6444 52.5 31 52.5C16.3556 52.5 4.5 40.8506 4.5 26.5C4.5 12.1494 16.3556 0.500003 31 0.500003Z"
          stroke="#666666"
        />
      </g>
      <g filter="url(#filter1_d_309_4555)">
        <path
          d="M30.5013 3.52813e-06C45.4128 2.87632e-06 57.5013 11.8645 57.5013 26.5C57.5013 41.1355 45.4128 53 30.5013 53C15.5897 53 30.5003 42 32.5 26.5C34.4997 11 15.5897 4.17993e-06 30.5013 3.52813e-06Z"
          fill="#F9F9F9"
        />
        <path
          d="M30.5013 0.500004C45.1455 0.500003 57.0013 12.1494 57.0013 26.5C57.0013 40.8506 45.1455 52.5 30.5013 52.5C28.6602 52.5 27.3222 52.3292 26.3789 52.0217C25.4424 51.7164 24.9388 51.292 24.6844 50.8068C24.4248 50.312 24.3744 49.6597 24.5441 48.7988C24.7135 47.9389 25.0909 46.9297 25.6147 45.768C26.0858 44.7233 26.6716 43.567 27.3076 42.3118C29.4705 38.0428 32.2134 32.629 32.9959 26.564C33.9373 19.2665 30.3556 12.95 27.7615 8.37501C27.5303 7.96733 27.307 7.57349 27.0954 7.19401C26.4419 6.02194 25.9063 5.00025 25.576 4.1219C25.2413 3.23154 25.1519 2.57423 25.2856 2.09534C25.4068 1.66103 25.7439 1.268 26.5582 0.973041C27.39 0.671734 28.6563 0.500004 30.5013 0.500004Z"
          stroke="#666666"
        />
      </g>
      <defs>
        <filter
          id="filter0_d_309_4555"
          x="0"
          y="0"
          width="62"
          height="61"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_309_4555"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_309_4555"
            result="shape"
          />
        </filter>
        <filter
          id="filter1_d_309_4555"
          x="19.9453"
          y="0"
          width="41.5566"
          height="61"
          filterUnits="userSpaceOnUse"
          color-interpolation-filters="sRGB"
        >
          <feFlood flood-opacity="0" result="BackgroundImageFix" />
          <feColorMatrix
            in="SourceAlpha"
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dy="4" />
          <feGaussianBlur stdDeviation="2" />
          <feComposite in2="hardAlpha" operator="out" />
          <feColorMatrix
            type="matrix"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
          />
          <feBlend
            mode="normal"
            in2="BackgroundImageFix"
            result="effect1_dropShadow_309_4555"
          />
          <feBlend
            mode="normal"
            in="SourceGraphic"
            in2="effect1_dropShadow_309_4555"
            result="shape"
          />
        </filter>
      </defs>
    </svg>
  ),
};

import { Modal } from "react-bootstrap";
import checkimg from "../../Assets/Images/fi-br-check.png";
import { useNavigate } from "react-router-dom";
import { useContext } from "react";
import { GlobalContext } from "../../Context/GlobalContext";

function BonoModal(props) {
  const navigate = useNavigate();
  const handleclick = () => {
    navigate("/Modelos");
    props.setbono(false);
  };

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      // backdrop="static"
      centered
      className="bonomodal"
      style={{ zIndex: "1000000001" }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="white text-center">
        <div className="Modal-text">
          <div className="">
            <h3>
              BONO de <span style={{ color: "#F3C300" }}>$4,000</span>
            </h3>
            <h4 style={{ textAlign: "start" }}>Incluye:</h4>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                textAlign: "justify",
              }}
            >
              <div
                style={{ display: "flex", alignItems: "center", gap: "1rem" }}
              >
                <img style={{ width: "20px" }} src={checkimg} alt="" />
                <h4
                  style={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    width: "100%",
                    color: "#fff",
                  }}
                >
                  Cargador Wallbox 220v incluido.*
                </h4>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center ",
                  gap: "1rem",
                }}
              >
                <img style={{ width: "20px" }} src={checkimg} alt="" />
                <h4
                  style={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    width: "100%",
                    color: "#fff",
                  }}
                >
                  Garantía de 10 años / 100,000 millas.
                </h4>
              </div>
              <div
                style={{
                  display: "flex",
                  alignItems: "center ",
                  gap: "1rem",
                }}
              >
                <img style={{ width: "20px" }} src={checkimg} alt="" />
                <h4
                  style={{
                    fontSize: "16px",
                    lineHeight: "20px",
                    width: "100%",
                    color: "#fff",
                  }}
                >
                  Asistencia en la carretera con recarga de batería en el lugar.
                  Incluida por 3 años.
                </h4>
              </div>
            </div>
            <div>
              <p
                style={{
                  fontSize: "10px",
                  lineHeight: "12px",
                  width: "100%",
                  color: "#fff",
                  marginTop: "10px",
                  textAlign: "justify",
                }}
              >
                *Bono de $4,000 ($3,500 distribuidor + $500 concesionario)
                aplica a todas las variantes del Kia EV6 que incluyen CVE2-23
                EX, CVGL1-23 GT Line RWD y CV4GL1-23 GT Line AWD, con precios
                sugeridos de venta de $54,595, $57,695 y $62,795
                respectivamente. Cargador Wallbox 220v valorado en $650 incluido
                con la compra del EV6. Para coordinar entrega de cargador debe
                llamar al 939-204-2340 y proveer información requerida (nombre y
                apellido, VIN del EV6, tablilla y dealer donde compró). Ciertas
                restricciones aplican. Oferta válida del 1 de septiembre hasta
                el 31 de octubre del 2023 o mientras dure el inventario.
                Únicamente para unidades nuevas de Kia EV6 adquiridas durante el
                periodo de promoción a través del website
                https://www.kiaevpr.com o en uno de los once concesionarios
                autorizados Kia.
              </p>
            </div>
            <button
              className="reserbtn"
              // style={{ width: "100px" }}
              onClick={() => handleclick()}
            >
              Activar bono
            </button>
          </div>
        </div>
        <div className="p-1"></div>
      </Modal.Body>
    </Modal>
  );
}

export default BonoModal;

export const Dollor4000Modal = () => {
  const { D4000Show, setD4000Show } = useContext(GlobalContext);
  return (
    <Modal
      show={D4000Show}
      onHide={() => setD4000Show(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        zIndex: "1000000001",
        marginTop: "3rem",
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <div
        className="white text-center dollerkamodal4000"
        style={{ padding: "0 1rem", height: "450px", overflowY: "scroll" }}
      >
        <p style={{ fontSize: "14px" }}>
          {/* *Bono de $6,000 ($5,000 distribuidor + $1,000 concesionario) aplica a
          todas las variantes del Kia EV6 que incluyen CVE2-23 EX, CVGL1-23 GT
          Line RWD y CV4GL1-23 GT Line AWD, con precios sugeridos de venta de
          $54,595, $57,695 y $62,795 respectivamente. *$500 de descuento en
          cargador es válido para un cargador residencial tipo 2; Wallbox
          (GreenLink) o Wise (Glenn International). Costos de instalación son
          adicionales y discutidos directamente con el suplidor de su elección;
          estos costos pueden variar por tipo de residencia. Los suplidores aquí
          mencionados son los certificados por Kia Puerto Rico para la
          instalación de los cargadores. Cliente no está obligado a realizar la
          instalación del cargador con los suplidores certificados por Kia
          Puerto Rico. La instalación del cargador con un suplidor diferente no
          afecta la garantía del cargador, siempre y cuando el instalador tenga
          licencia de Perito Electricista y/o esté certificado para manejar este
          tipo de productos. Kia Puerto Rico, Glenn y Greenlink no se hacen
          responsable por cualquier daño ocurrido al cargador, carro u hogar
          cuando la instalación es realizada por un suplidor no certificado por
          Kia Puerto Rico. Oferta válida del 1 de diciembre de 2023 hasta el 30
          de junio de 2024 o mientras dure el inventario. Únicamente para
          unidades nuevas de Kia EV6 adquiridas durante el periodo de promoción
          a través del website www.kiaevpr.com o en uno de los once
          concesionarios Kia autorizados. */}
          *Bono de $6,000 ($5,000 distribuidor + $1,000 concesionario) aplica a
          todas las variantes del Kia EV6 que incluyen CVE2-23 EX, CVGL1-23 GT
          Line RWD y CV4GL1-23 GT Line AWD, con precios sugeridos de venta de
          $54,595, $57,695 y $62,795 respectivamente. *$500 de descuento en
          cargador es válido para un cargador residencial tipo 2; Wallbox
          (GreenLink). Costos de instalación son adicionales y discutidos
          directamente con el suplidor; estos costos pueden variar por tipo de
          residencia. El suplidor aquí mencionado es el certificado por Kia
          Puerto Rico para la instalación de los cargadores. Cliente no está
          obligado a realizar la instalación del cargador con el suplidor
          certificado por Kia Puerto Rico. La instalación del cargador con un
          suplidor diferente no afecta la garantía del cargador, siempre y
          cuando el instalador tenga licencia de Perito Electricista y/o esté
          certificado para manejar este tipo de productos. Kia Puerto Rico y
          Greenlink no se hacen responsable por cualquier daño ocurrido al
          cargador, carro u hogar cuando la instalación es realizada por un
          suplidor no certificado por Kia Puerto Rico. Oferta válida del 1 de
          diciembre de 2023 hasta el 31 de diciembre del 2024 o mientras dure el
          inventario. Únicamente para unidades nuevas de Kia EV6 adquiridas
          durante el periodo de promoción a través del website www.kiaevpr.com o
          en uno de los once concesionarios Kia autorizados.
        </p>
      </div>
    </Modal>
  );
};

export const GraciasModal = () => {
  const { Gracias, setGraciasModal } = useContext(GlobalContext);

  const handleclick = () => {
    setGraciasModal(false);
  };
  return (
    <Modal
      show={Gracias}
      onHide={() => setGraciasModal(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      style={{
        zIndex: "1000000000000",
        marginTop: "3rem",
      }}
    >
      <Modal.Header closeButton></Modal.Header>
      <div className="white text-center pb-5">
        <h3>¡Gracias!</h3>
        <p
          className="pnoneinmobile"
          style={{
            padding:
              Gracias.pra === "Hemos recibido tu información." ? "0 6rem" : "",
          }}
        >
          {/* Hemos recibido tu información, un representante te estará contactando
          a la brevedad posible. */}
          {Gracias.pra}
        </p>
        <button
          className="reserbtn"
          style={{ borderRadius: "18px" }}
          onClick={() => handleclick()}
        >
          OK
        </button>
      </div>
    </Modal>
  );
};

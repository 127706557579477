import React, { useContext, useEffect, useState } from "react";
// import cardimg2 from "../../Assets/EV6 EX RWD/CV_BL_Front_Snow_White_Pearl_SWP.png";
// import Newcardimg3 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Yacht_Blue_DU3.png";
// import cardimg4 from "../../Assets/EV6 GT-Line (E-AWD)/CV_GT_Line_Front_Aurora_Black Pearl_ABP.png";

import "../../../Css/Section4.css";
import Navbar from "../../../Components/UI/NavbarTwo";
import { useNavigate } from "react-router-dom";
import Footer from "../../../Components/UI/Footer";
import { Modal } from "react-bootstrap";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import Button, { Bonobtn } from "../../../Components/UI/Button";
import ApiHook from "../../../Components/Hook/ApiHook";
import { useSelector } from "react-redux";
import { GlobalContext } from "../../../Context/GlobalContext";
import { EV9Modeldata } from "../../../Components/Data/Data";
import { Icons } from "../../../Constant/Icons";
// import { Store } from "../../Redux/MainStore";
// import { ActivateOfferById } from "../../Redux/CarSlice";
// import axios from "../../API";
// import { toast } from "react-hot-toast";

// export const handleOffer = async (item) => {
//   const UserData = JSON.parse(localStorage.getItem("user-info"));
//   if (UserData) {
//     try {
//       const res = await axios.get("/update-offer-status", {
//         headers: {
//           Authorization: `Bearer ${UserData.Token}`,
//           "Content-Type": "application/json",
//         },
//       });
//       // console.log("🚀 ~ file: Section4.js:174 ~ getdata ~ res:", res);
//       Store.dispatch(ActivateOfferById(res.data.message));
//     } catch (error) {
//       // seterror(error);
//       // console.log(error);
//     } finally {
//       // setisloading(false);
//     }
//   } else {
//     Store.dispatch(ActivateOfferById());
//   }
// };

const Modelos = () => {
  const [indexx, setindex] = useState(0);
  localStorage.setItem("cardindex", indexx);
  const navigate = useNavigate();
  const [modalShow, setModalShow] = useState(false);
  //   const UserData = JSON.parse(localStorage.getItem("user-info"));
  //   const ModalDataFromRedux = useSelector((state) => state.carData);

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, [location.pathname]);

  function ModelModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        // backdrop="static"
        centered
        style={{ zIndex: "10000" }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="white text-center">
          <div className="Dropdown-Car-info-body-Head">
            <i>Información importante</i>
          </div>
          <p className="Modal-text">
            <div>
              Los precios que surgen en la página son válidos al momento en el
              que el Usuario reserva su vehículo mediante un depósito de
              Quinientos dólares, ($500.00). El precio de la reservación se
              honrará para el Usuario que reserve el vehículo mediante el pago
              referenciado. Sin embargo, los precios anunciados pueden cambiar
              en cualquier momento sin ningún tipo de aviso previo.
            </div>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }

  const { isloading } = ApiHook("/get-offer-status");

  const handleclick = (index) => {
    // setindex(index);
    setTimeout(() => {
      navigate("/Model/EV9");
    }, 100);
  };

  return (
    <>
      <Helmet>
        <title>Kia EV9 | Modelos | Reserva en línea</title>
        <link rel="canonical" href="https://kiaevpr.com/#/Modelos/EV9" />
        <meta
          name="description"
          content="Descubre las variantes del nuevo y totalmente eléctrico modelo de Kia, el EV9. ¡Escoge el modelo que te gusta y resérvalo en línea!"
        />
      </Helmet>
      <ModelModal show={modalShow} onHide={() => setModalShow(false)} />
      <Navbar />
      <div className="moulasdivv " id="Specs">
        <span>Modelos</span>
        <div className="cardmaindivv2">
          {EV9Modeldata.map((curelem, index) => {
            return (
              <div className="modulcardd" key={index}>
                <div className="cardimg2">
                  <img
                    src={curelem.img}
                    alt=""
                    onClick={() => handleclick(index)}
                    style={{
                      width: "100%",
                      height: "100%",
                      objectFit: "cover",
                    }}
                  />
                  <span onClick={() => setModalShow(true)}>
                    {Icons.infoicon}
                  </span>
                </div>
                <div
                  className="d-flex align-items-center justify-content-center"
                  style={{ backgroundColor: "#D40000", height: "40px" }}
                  onClick={() => handleclick(index)}
                >
                  <span style={{ color: "white" }}>Reserva con $500</span>
                </div>
                <div
                  className="modulscontent"
                  onClick={() => handleclick(index)}
                >
                  <span>{curelem.name}</span>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <p
                        style={{
                          lineHeight: "20px",
                          color: "#fff",
                          fontSize: "20px",
                        }}
                      >
                        MSRP {curelem.price}
                      </p>
                    </div>
                  </div>
                  <div className="kmm">
                    <div className="kmfirst">
                      <div className="first">
                        <img src={curelem.icon3} alt="" />
                        <span>{curelem.vorte}</span>
                      </div>
                      <div className="first">
                        <img src={curelem.icon2} alt="" />
                        <span>{curelem.km}</span>
                      </div>
                      <div className="first">
                        <img src={curelem.icon} alt="" />
                        <span>{curelem.rwd}</span>
                      </div>
                    </div>
                    <div className="mphdiv2">
                      {/* {curelem.isOfferActivated ? (
                        <Bonobtn
                          onClick={(event) => {
                            event.stopPropagation();
                            handleOffer(curelem);
                          }}
                        >
                          Bono Activado
                        </Bonobtn>
                      ) : (
                        <>
                          {isloading ? (
                            <p className="loadingchalu">Loading...</p>
                          ) : (
                            <Button
                              variant={"reserbtn2"}
                              style={{ backgroundColor: "#878787" }}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleOffer(curelem);
                              }}
                            >
                              Activar bono
                            </Button>
                          )}
                        </>
                      )} */}
                      <div
                        style={{
                          display: "flex",
                          gap: "1rem",
                          justifyContent: "flex-end",
                        }}
                      >
                        <div className="linediv3"></div>
                        <div className="mph">
                          <span>{curelem.MPGe}</span>
                          <p className="pragraphs">Rango</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
        <div className="lasttext">
          <p>
            <span>{Icons.infoicon}</span>
            {/* Disclaimers:  */}
            Las opciones pueden diferir según las regiones. Toda la información
            y las ilustraciones se basan en los datos disponibles en el momento
            de la publicación y están sujetos a cambios sin previo aviso. Favor
            de referirse a la hoja de producto para más información.
          </p>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default Modelos;

import React, { useEffect, useState } from "react";
import Wrapper from "../../Components/UI/Wrapper";
import Card from "../../Components/UI/Card";
import Input from "../../Components/UI/Input";
import Button from "../../Components/UI/Button";
import "../../Css/Auth/ChangePassword.css";
import { useFormik } from "formik";
import { ChangePassSchema } from "../../Schemas";
import { useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "../../API";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const initialValues = {
  SentPassword: "",
  Password: "",
  RepeatPassword: "",
};

const ChangePassword = () => {
  const Navigate = useNavigate();

  const [IsError, setIsError] = useState("");
  const [Loading, setLoading] = useState(false);

  const ForgotPassEmail = JSON.parse(localStorage.getItem("ForgotPass-Email"));

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  useEffect(() => {
    if (!ForgotPassEmail) {
      Navigate("/");
    }
  }, []);

  const onSubmit = async (values) => {
    // console.log(values);
    setLoading(true);
    try {
      const response = await axios.post("/change-password", {
        Email: ForgotPassEmail.Email,
        OldPassword: values.SentPassword,
        NewPassword: values.Password,
        ConfirmPassword: values.RepeatPassword,
      });
      // console.log("response", response);
      setIsError(response.data.message);
      setLoading(false);
      if (response.data.status == "true") {
        localStorage.removeItem("ForgotPass-Email");
        Navigate("/Iniciar-Sesion");
      }
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: ChangePassSchema,
      onSubmit,
    });

  return (
    <div>
      <Wrapper>
        <Card head={"Cambiar contraseña"}>
          <span className="form-error-head"> {IsError} </span>
          <form onSubmit={handleSubmit} className="mainform2">
            <div className="inputs2">
              <div>
                <Input
                  name="SentPassword"
                  text={"Contraseña enviada"}
                  type={"password"}
                  placeholder={"Ingrese la contraseña enviada"}
                  value={values.SentPassword}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.SentPassword && touched.SentPassword ? (
                  <p className="form-error"> {errors.SentPassword} </p>
                ) : null}
              </div>
              <div>
                <Input
                  name="Password"
                  text={"Contraseña"}
                  type={"password"}
                  placeholder={"Ingrese la contraseña "}
                  value={values.Password}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.Password && touched.Password ? (
                  <p className="form-error"> {errors.Password} </p>
                ) : null}
              </div>
              <div>
                <div>
                  <Input
                    name="RepeatPassword"
                    text={"Repetir contraseña"}
                    type={"password"}
                    placeholder={"Repita la contraseña"}
                    value={values.RepeatPassword}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    required
                  />
                  {errors.RepeatPassword && touched.RepeatPassword ? (
                    <p className="form-error"> {errors.RepeatPassword} </p>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="">
              <Button variant={"red"} type={"submit"}>
                Guardar
              </Button>
            </div>
          </form>
        </Card>
        {!Loading && <div></div>}
        {Loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </Wrapper>
    </div>
  );
};

export default ChangePassword;

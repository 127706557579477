import React, { useContext, useState } from "react";
import "./Separate.css";
import logo from "../../Assets/Images/KiaLogo.png";
import { Separatedata } from "../../Components/Data/Data";
import { Newdropdown, Newinput } from "../../Components/UI/Input";
import Button from "../../Components/UI/Button";
import { useFormik } from "formik";
import { SeparateSchemas } from "../../Schemas";
import closeicon from "../../Assets/Images/close.png";
import { GlobalContext } from "../../Context/GlobalContext";
import Api from "../../API";
import { motion } from "framer-motion";

const Separate = () => {
  // STATES............................
  const { setseparatemodal, setGraciasModal, setchatbotshow } =
    useContext(GlobalContext);
  const UserData = JSON.parse(localStorage.getItem("user-info"));
  // console.log(UserData);
  const [Acepto, setAcepto] = useState(false);
  const [No_Acepto, setNoAcepto] = useState(false);
  const [permission, setpermission] = useState("");
  const [Loading, setloading] = useState(false);
  // const [dropvalue, setdropvalue] = useState();
  // const [showmessage, setshowmessaeg] = useState("");
  //   initailValues....................
  const initialValues = {
    name: "",
    dropdown: "",
    email: "",
    telephone: "",
    Acepto: false,

    // new values.............
    username: "",
    password: "",
    firstname: "",
    lastname: "",
    productid: "",
    message: "",
    leadsource: 124310019,
    areasource: 100000001,
    make: "Kia",
    donotbulkemail: false,
    donotphone: false,
    donotsms: false,
  };

  // const api = process.env.REACT_CREATE_LEAD_KEY;
  // const api = "https://dealerleaddev.azurewebsites.net/api/CreateLead";
  // const CreateLead = async () => {
  //   try {
  //     const res = await axios.post(api, {
  //       username: "C-VEN",
  //       password: "123",
  //       firstname: UserData.Name ? UserData.Name : "",
  //       lastname: UserData.SurName ? UserData.SurName : "",
  //       productid: "",
  //       message: "",
  //       leadsource: 124310019,
  //       areasource: 100000001,
  //       make: "Kia",
  //       donotbulkemail: false,
  //       donotphone: false,
  //       donotsms: false,
  //     });
  //     console.log(res);
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  //   send interest API......................
  const onSubmit = async (values, { resetForm }) => {
    setloading(true);
    try {
      const res = await Api.post("/send-Interest", {
        Name: values.name,
        Email: values.email,
        PhoneNumber: values.telephone,
        InterestDeCompare: values.dropdown,
        Permission: permission,
        Type: "EV6",
      });
      // console.log(res);
      setloading(false);
      if (
        res.data.message == "Su mensaje ha sido enviado" &&
        res.data.status == "success"
      ) {
        resetForm();
        setpermission("");
        setseparatemodal(false);
        setAcepto(false);
        setNoAcepto(false);
        setGraciasModal({
          modal: true,
          pra: "Hemos recibido tu información, un representante te estará contactando a la brevedad posible.",
        });
        // setshowmessaeg(res.data.message);
        // setTimeout(() => {
        //   setshowmessaeg("");
        // }, 5000);
        // CreateLead();
      }
    } catch (error2) {
      console.log(error2);
      setloading(false);
    }
  };
  //   formik..................
  const { handleChange, handleSubmit, handleBlur, errors, values, touched } =
    useFormik({
      onSubmit,
      initialValues: initialValues,
      validationSchema: SeparateSchemas,
    });

  //   for Acepto checkbox.............
  const handleclick = () => {
    setAcepto(!Acepto);
    setNoAcepto(false);
    if (!Acepto) {
      setpermission("Acepto");
    } else {
      setpermission("");
    }
    if (permission == "Acepto" && Acepto) {
      values.Acepto = true;
    } else {
      values.Acepto = false;
    }
  };

  //   for No_Acepto checkbox.............
  const handleclick2 = () => {
    setNoAcepto(!No_Acepto);
    setAcepto(false);
    if (!No_Acepto) {
      setpermission("No_Acepto");
    } else {
      setpermission("");
    }
    if (permission == "No_Acepto" && No_Acepto) {
      values.Acepto = true;
    } else {
      values.Acepto = false;
    }
  };

  const handlechatbot = () => {
    setchatbotshow(true);
    setseparatemodal(false);
  };
  return (
    <div className="separatecoontainer">
      <motion.form
        initial={{ opacity: 0, scale: 0.5 }}
        whileInView={{ opacity: 1, scale: 1 }}
        transition={{ duration: 0.3, ease: "easeInOut" }}
        onSubmit={handleSubmit}
        className="Separatemain"
      >
        <div className="bannerdiv">
          <div className="btnstitle2">
            <img src={logo} alt="" />
            <img
              onClick={() => setseparatemodal(false)}
              style={{ cursor: "pointer" }}
              src={closeicon}
              alt=""
            />
          </div>
          <div className="btnstitle">
            <span>La EV6 fácil de tener.</span>
            <div className="separatebtnsparent">
              {Separatedata.map((item) => {
                return (
                  <a
                    href={item.link !== "" && item.link}
                    key={item.id}
                    className="separatebtnsmain"
                    onClick={() => item.id === 3 && handlechatbot()}
                  >
                    <div className="separatebtns">
                      <span>{item.img}</span>
                    </div>
                    <span>{item.name}</span>
                  </a>
                );
              })}
            </div>
          </div>
        </div>
        <div className="middlecontent" style={{ backgroundColor: "#292D32" }}>
          <div className="fielddiv">
            <div style={{ display: "flex", gap: "10px" }}>
              <div>
                <Newinput
                  title={"Déjanos saber tu nombre"}
                  style={{ color: "#fff" }}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  name={"name"}
                  value={values.name}
                />
                {errors.name && touched.name ? (
                  <p className="form-error2">{errors.name}</p>
                ) : null}
              </div>
              <div>
                <Newinput
                  title={"¿Cuál es tu email?"}
                  style={{ color: "#fff" }}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  name={"email"}
                  value={values.email}
                />
                {errors.email && touched.email ? (
                  <p className="form-error2">{errors.email}</p>
                ) : null}
              </div>
            </div>
            <div style={{ display: "flex", gap: "10px" }}>
              {/* <div>
                <Newinput
                  title={"¿Cuál es tu password?"}
                  style={{ color: "#fff" }}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  name={"password"}
                  value={values.password}
                />
                {errors.password && touched.password ? (
                  <p className="form-error2">{errors.password}</p>
                ) : null}
              </div> */}
              <div>
                <Newinput
                  title={"¿Cuál es tu número de teléfono?"}
                  style={{ color: "#fff" }}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  name={"telephone"}
                  value={values.telephone}
                />
                {errors.telephone && touched.telephone ? (
                  <p className="form-error2">{errors.telephone}</p>
                ) : null}
              </div>
              <div>
                <Newdropdown
                  title={"Interés de compra"}
                  style={{ padding: "0 1rem", color: "#fff" }}
                  onChange={handleChange}
                  handleBlur={handleBlur}
                  name={"dropdown"}
                  value={values.dropdown}
                />
                {errors.dropdown && touched.dropdown ? (
                  <p className="form-error2">{errors.dropdown}</p>
                ) : null}
              </div>
            </div>
          </div>
          {/* <select onChange={(e) => setdropvalue(e.target.value)} name="" id="">
            <option value="">select</option>
            <option value="100000001">Customer Services</option>
            <option value="100000009">Sales</option>
          </select> */}
          <div>
            <p
              style={{
                color: "#fff",
                fontFamily: "Kia-font",
                fontSize: "11px",
              }}
            >
              Acepto recibir información al número de teléfono provisto, vía
              llamadas, llamadas grabadas o de voz artificial y/o mensaje de
              texto, sobre productos y servicios de parte de Kia de Puerto Rico,
              su distribuidora Motorambar, Inc., y/o sus concesionarios
              autorizados.
            </p>
            {/* {showmessage && (
              <p style={{ color: "green", textAlign: "center" }}>
                {showmessage}
              </p>
            )} */}
            <div className="checkboxdiv">
              <label
                className="d-flex gap-2 w-auto align-items-center"
                style={{
                  width: "auto",
                  cursor: "pointer",
                }}
                htmlFor="html3"
              >
                <div className="form-group">
                  <input
                    type="checkbox"
                    id={"html3"}
                    name="Acepto"
                    style={{ backgroundColor: "transparent" }}
                    checked={permission == "Acepto" || Acepto ? true : false}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onClick={handleclick}
                  />
                </div>
                <p
                  style={{
                    width: "auto",
                    color: "#fff",
                    fontFamily: "Kia-font",
                    fontSize: "10px",
                  }}
                >
                  Acepto
                </p>
              </label>
              <label
                className="d-flex w-auto gap-2 align-items-center"
                style={{ width: "auto", cursor: "pointer" }}
                htmlFor="html1"
              >
                <div className="form-group">
                  <input
                    type="checkbox"
                    id={"html1"}
                    name="Acepto"
                    style={{ backgroundColor: "transparent" }}
                    checked={
                      permission == "No_Acepto" || No_Acepto ? true : false
                    }
                    onChange={handleChange}
                    onBlur={handleBlur}
                    onClick={handleclick2}
                  />
                </div>
                <p
                  style={{
                    width: "auto",
                    color: "#fff",
                    fontFamily: "Kia-font",
                    fontSize: "10px",
                  }}
                >
                  No Acepto
                </p>
              </label>
              {errors.Acepto && touched.Acepto ? (
                <p className="form-error2">{errors.Acepto}</p>
              ) : null}
            </div>
            <Button
              variant={"reserbtn"}
              type={"submit"}
              style={{ width: "100px" }}
            >
              {Loading ? "Loading..." : "ENVIAR"}
            </Button>
          </div>
        </div>
      </motion.form>
    </div>
  );
};

export default Separate;

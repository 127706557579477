import React, { useEffect } from "react";
import "../../Css/FeaturesCss/Features.css";
import check from "../../Assets/Images/checkred.png";
import close from "../../Assets/Images/crossegrey.png";
import crossicon from "../../Assets/Images/close.png";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const Features = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  return (
    <div className="featurewrapper">
      <div className="featurecard">
        <div className="featureheading">
          <h1>Especificaciones</h1>
          <div></div>
          <img src={crossicon} alt="" onClick={() => navigate("/Model")} />
        </div>
        <div className="carnamesss">
          <div className="mainnames">
            <span>EX RWD</span>
            <span>GT-LINE RWD</span>
            <span>GT-LINE (E-AWD)</span>
          </div>
        </div>
        <div className="tablediv">
          <span className="headingfeature">Variantes</span>
          <div>
            <div className="ocndnp">
              <span>OCN</span>
              <span>DNP</span>
              <span>Arbitrios</span>
              <span>MSRP</span>
            </div>
            <div className="g0o5">
              <span>G0O5</span>
              <span>K939</span>
              <span>K966</span>
            </div>
          </div>
        </div>
        <span className="headingfeature2">Colores exteriores</span>
        <div className="tableone">
          <div className="auroradiv">
            <span>Aurora Black Pearl</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Interstellar Gray</span>
            <img src={check} alt="" />
            <img className="closeimg" src={close} alt="" />
            <img className="closeimg" src={close} alt="" />
          </div>
          <div className="auroradiv">
            <span>Gravity Blue</span>
            <img src={check} alt="" />
            <img className="closeimg" src={close} alt="" />
            <img className="closeimg" src={close} alt="" />
          </div>
          <div className="auroradiv">
            <span>Runway Red</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Yatch Blue</span>
            <img className="khaintick" src={check} alt="" />
            <img className="khaintick2" src={check} alt="" />
            {/* <img src={check} alt="" /> */}
          </div>
          <div className="auroradiv">
            <span>Deep Forest</span>
            <img src={check} alt="" />
            <img className="closeimg" src={close} alt="" />
            <img className="closeimg" src={close} alt="" />
          </div>
          <div className="auroradiv">
            <span>Glacier</span>
            <img src={check} alt="" />
            <img className="closeimg" src={close} alt="" />
            <img className="closeimg" src={close} alt="" />
          </div>
          <div className="auroradiv">
            <span>Steel Gray</span>
            <img src={check} alt="" />
            <img className="closeimg" src={close} alt="" />
            <img className="closeimg" src={close} alt="" />
          </div>
          <div className="auroradiv">
            <span>Moonscape</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Snow White Pearl</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
        </div>
        <span className="headingfeature2">Especificaciones mecánicas</span>
        <div className="tableone">
          <div className="auroradiv">
            <span>Motor Sincrónico de Imanes Permanentes</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Tipo de tracción</span>
            <span className="awdspan">AWD</span>
          </div>
          <div className="auroradiv">
            <span>
              Motor de tracción trasera de 125 kW con 167 caballos de fuerza
            </span>
            <img src={check} alt="" />
            <img className="closeimg" src={close} alt="" />
            <img className="closeimg" src={close} alt="" />
          </div>
          <div className="auroradiv">
            <span>& 258 lb.-pies de par de torsión</span>
            <img src={check} alt="" />
            <img className="closeimg" src={close} alt="" />
            <img className="closeimg" src={close} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Motor de tracción trasera de 168 kW con 225 caballos de fuerza{" "}
            </span>
            <img className="closeimg" src={close} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>& 258 lb.-pies de par de torsión</span>
            <img className="closeimg" src={close} alt="" />
            <img src={check} alt="" />
            <img className="closeimg" src={close} alt="" />
          </div>
          <div className="auroradiv">
            <span>& 446 lb.-pies de par de torsión</span>
            <img className="closeimg" src={close} alt="" />
            <img className="closeimg" src={close} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Freno de estacionamiento electrónico (EPB) con retención
              automática
            </span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Sistema de frenado regenerativo con palancas de control</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Generador de energía a bordo (V2L)</span>
            <img className="closeimg" src={close} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
        </div>
        <span className="headingfeature2">Sistema eléctrico</span>
        <div className="tableone">
          <div className="auroradiv">
            <span>Tipo de batería</span>
            <span className="battery">Lithium Ion Battery</span>
          </div>
          <div className="auroradiv">
            <span>Voltaje (V)</span>
            <span className="battery2">697V</span>
          </div>
          <div className="auroradiv">
            <span>Capacidad de carga (kWh)</span>
            <span className="battery2">77.4 kWh</span>
          </div>
          <div className="auroradiv">
            <span>Velocidad máxima de operación del vehículo (mph) (RWD)</span>
            <span className="battery">115mph</span>
          </div>
          <div className="auroradiv">
            <span>Velocidad máxima de operación del vehículo (mph) (AWD)</span>
            <span className="battery3">115mph</span>
          </div>
          <div className="auroradiv">
            <span>Sistema de carga múltiple (400V–800V)</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
        </div>
        <span className="headingfeature2">Exterior</span>
        <div className="tableone">
          <div className="auroradiv">
            <span>
              Aros de 19” en ruedas aerodinámicas con acabado en gris oscuro
            </span>
            <img className="closeimg" src={close} alt="" />
            <img className="closeimg" src={close} alt="" />
            <img className="closeimg" src={close} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Aros de 19” en ruedas aerodinámicas con acabado en negro
            </span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img className="closeimg" src={close} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Aros de 20” en ruedas aerodinámicas con acabado en negro
            </span>
            <img className="closeimg" src={close} alt="" />
            <img className="closeimg" src={close} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Estilo GT-Line en el interior y exterior</span>
            <img className="closeimg" src={close} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Control automático de luces con asistencia de luces LED</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Retrovisor plegable eléctrico con calefacción e indicadores
            </span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Cristal trasero con privacidad y spoiler trasero</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Manijas automáticas</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Puerta de baúl inteligente con apertura automática</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Power Sunroof</span>
            <img className="closeimg" src={close} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Paquete de remolque</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Paquete de remolque Equipo de reparación para ruptura de goma
              (Tire Mobility Kit)
            </span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>la goma de repuesta no está incluida</span>
          </div>
        </div>
        <span className="headingfeature2">Interior</span>
        <div className="tableone">
          <div className="auroradiv">
            <span>Pantalla Panorámica Dual de 12.3" con navegación</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Apple CarPlay® Android Auto™</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Bluetooth® y Conectividad de manos libres</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Llave inteligente con encendido por botón</span>
            <span className="battery2">Remote Start</span>
          </div>
          <div className="auroradiv">
            <span>Cargador Inalámbrico para teléfonos</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Control automático de temperatura de zona dual</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Asientos delanteros con calefacción</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Asientos delanteros con ventilación</span>
            <img className="closeimg" src={close} alt="" />
            <img className="closeimg" src={close} alt="" />
            <img className="closeimg" src={close} alt="" />
          </div>
          <div className="auroradiv">
            <span>Asiento del conductor eléctrico</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Materiales de asientos en cuero vegano y material reciclado
            </span>
            <img className="closeimg" src={close} alt="" />
            <img className="closeimg" src={close} alt="" />
            <img className="closeimg" src={close} alt="" />
          </div>
          <div className="auroradiv">
            <span>Materiales de asientos en cuero vegano</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Paquete de asientos GT-Line Suede</span>
            <img className="closeimg" src={close} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Sistema de Audio Meridian Premium con 14 bocinas</span>
            <img className="closeimg" src={close} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Cuatro (4) puertos USB con carga rápida</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Toma de corriente de 12V - parte delantera</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Toma de corriente de 12V - parte trasera</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Luces interiores de ambientación tipo LED</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Ventanas eléctricas con subida/bajada automática delantera
            </span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Consola central delantera fija con bandeja de almacenamiento
            </span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>en apoyabrazos y portavasos doble</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>● = Standard — = Not available</span>
          </div>
        </div>
        <span className="headingfeature2">Kia Drive Wise</span>
        <div className="tableone">
          <div className="auroradiv">
            <span>
              Asistencia para prevención de colisión frontal (FCA) con detección
              de peatones (sensor al girar)
            </span>
            <img src={check} alt="" />
            <img className="closeimg" src={close} alt="" />
            <img className="closeimg" src={close} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Asistencia para prevención de colisión frontal (FCA) con detección
              de peatones (sensor al girar y cruzar)
            </span>
            <img className="closeimg" src={close} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Asistencia para prevención de colisión en punto ciego (BCA)
            </span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Asistencia para prevención de colisión de tráfico cruzado
              posterior (RCCA)
            </span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Asistencia de retención de carril (LKA) y Asistencia de
              seguimiento de carril (LFA)
            </span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Alerta de distancia de estacionamiento (PDW) Trasero</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Asistencia de salida segura (SEA)</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Control de crucero inteligente (SCC) con parada y arranque +
              aprendizaje automático
            </span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Alerta de ocupante trasero (ROA)</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Sistema de monitoreo a vuelta redonda (SVM)</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Monitor para punto ciego (BVM)</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Asistencia para estacionamiento inteligente remoto</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Asistencia para prevención de colisión al estacionarse- reversa
            </span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Augmented Reality Head-Up Display</span>
            <img className="closeimg" src={close} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
        </div>
        <span className="headingfeature2">Seguridad</span>
        <div className="tableone">
          <div className="auroradiv">
            <span>Sistema avanzado de bolsas de aire duales</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Bolsa de aire para las rodillas del conductor</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Bolsas de aire laterales en los asientos delanteros</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Bolsas de aire laterales tipo cortina</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Cinturones de seguridad de 3 puntos para todas las posiciones del
              asiento
            </span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Pretensores de cinturones de seguridad (asientos delanteros)
            </span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Sistema de frenos antibloqueo (ABS) con frenos de disco en las 4
              ruedas
            </span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Manejo de estabilidad vehicular (VSM)</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Sistema de control de tracción</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Control de estabilidad electrónica</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Sistema de distribución electrónica de frenos (EBD)</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Sistema de asistencia de frenado (BAS)</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Control de asistencia de en cuestas (HAC)</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Sistema de monitoreo para la presión de aire en las gomas (TPMS)
            </span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>con indicador de presión individual</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Sistema de anclaje para asiento protector de niños (LATCH)
            </span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>
              Cerraduras de seguridad para niños en las puertas traseras
            </span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
          <div className="auroradiv">
            <span>Apertura de emergencia interna del baúl</span>
            <img src={check} alt="" />
            <img src={check} alt="" />
            <img src={check} alt="" />
          </div>
        </div>
        <span className="headingfeature2">Especificaciones</span>
        <div className="tableone">
          <span className="headingfeature2">
            Medidas/capacidades exteriores
          </span>
          <div className="auroradiv">
            <span>Largo</span>
            <span className="khainwalaspan">184.3 in.</span>
            <span className="khainwalaspan">184.8 in.</span>
            <span className="khainwalaspan">184.8 in.</span>
          </div>
          <div className="auroradiv">
            <span>Ancho (sin espejos laterales)</span>
            <span className="khainwalaspan">74.0 in.</span>
            <span className="khainwalaspan">74.4 in.</span>
            <span className="khainwalaspan">74.4 in.</span>
          </div>
          <div className="auroradiv">
            <span>Altura</span>
            <span className="battery2">60.8 in.</span>
          </div>
          <div className="auroradiv">
            <span>Distancia entre ejes</span>
            <span className="battery2">114.2 in.</span>
          </div>
          <div className="auroradiv">
            <span>Alero (delantero/trasero)</span>
            <span className="battery2">33.3 in. /37.4 in.</span>
          </div>
          <div className="auroradiv">
            <span>Track (delantero/trasero)</span>
            <span className="battery2">64.2 in. /64.6 in.</span>
          </div>
          <div className="auroradiv">
            <span>Claridad del pisoe</span>
            <span className="battery2">6.1 in.</span>
          </div>
        </div>
        <span className="headingfeature2">Medidas/capacidades Interiores</span>
        <div className="tableone">
          <div className="auroradiv">
            <span>Espacio para la cabeza con sunroof (delantero/trasero)</span>
            <span className="battery2">36.8/38.0 in.</span>
            <span className="battery3">36.8/38.0 in.</span>
          </div>
          <div className="auroradiv">
            <span>Espacio para la cabeza sin sunroof (delantero/trasero)</span>
            <span className="battery4">39.0/38.0 in.</span>
          </div>
          <div className="auroradiv">
            <span>Espacio para las piernas (delantero/trasero)</span>
            <span className="battery2">42.4/39.0 in.</span>
          </div>
          <div className="auroradiv">
            <span>Espacio para los hombros (delantero/trasero)</span>
            <span className="battery2">57.8/55.6 in.</span>
          </div>
          <div className="auroradiv">
            <span>Espacio para las caderas (delantero/trasero)</span>
            <span className="battery2">54.9/53.2 in.</span>
          </div>
          <div className="auroradiv">
            <span>Volumen de pasajeros</span>
            <span className="battery2">103.0 cu. ft.</span>
          </div>
          <div className="auroradiv">
            <span>
              Volumen de carga (asientos traseros levantados/asientos traseros
              recogidos)
            </span>
            <span className="battery2">24.4/50.2 cu.ft</span>
          </div>
        </div>
        <span className="headingfeature2">Peso</span>
        <div className="tableone">
          <div className="auroradiv">
            <span>Peso en vacío</span>
            <span className="battery2">4,255 lbs.</span>
          </div>
          <div className="auroradiv">
            <span>GVWR</span>
            <span className="battery2">5,335 lbs.</span>
          </div>
          <div className="auroradiv">
            <span>Capacidad de remolque con freno</span>
            <span className="battery2">2,300 lbs.</span>
          </div>
        </div>
        <span className="headingfeature2">MPGe</span>
        <div className="tableone">
          <div className="auroradiv">
            <span>Ciudad (MPGe)</span>
            <span className="battery4">134</span>
            <span className="battery3">117</span>
          </div>
          <div className="auroradiv">
            <span>Autopista (MPGe)</span>
            <span className="battery4">101</span>
            <span className="battery3">96</span>
          </div>
          <div className="auroradiv">
            <span>Combinado (MPGe)</span>
            <span className="battery4">117</span>
            <span className="battery3">109</span>
          </div>
          <div className="auroradiv">
            <span>Autonomía totalmente eléctrica (millas)</span>
            <span className="battery4">310</span>
            <span className="battery3">232</span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Features;

import React, { useEffect, useState } from "react";
import exterior from "../../Assets/Images/enteriorimg.jpg";
import "../../Css/Gallery/Exterior.css";
import exteriorimg1 from "../../Assets/Exterior/1.jpg";
import exteriorimg2 from "../../Assets/Exterior/2.jpg";
import exteriorimg3 from "../../Assets/Exterior/3.jpg";
import exteriorimg4 from "../../Assets/Exterior/4.jpg";
import exteriorimg5 from "../../Assets/Exterior/5.jpg";
import exteriorimg6 from "../../Assets/Exterior/6.jpg";
import exteriorimg7 from "../../Assets/Exterior/7.jpg";
import exteriorimg8 from "../../Assets/Exterior/8.jpg";
import exteriorimg9 from "../../Assets/Exterior/9.jpg";
import { useNavigate } from "react-router-dom";
import close from "../../Assets/Images/close.png";
import load from "../../Assets/Interior/load.png";
import { Skeleton } from "@mui/material";
import { Icons } from "../../Constant/Icons";

const Exterior = () => {
  const navigate = useNavigate();
  const [show, setshow] = useState(false);
  const [image, setimage] = useState("");
  const [loading, setLoading] = useState(true);

  const Imagelink = (e) => {
    setimage(e.target.src);
    setshow(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  let name = "EV6";
  return (
    <>
      <div className="exteriormaindiv">
        {loading ? (
          <Skeleton
            className="SKELETEN"
            width={1990}
            height={1000}
            variant="rounded"
            sx={{ bgcolor: "grey.600" }}
          />
        ) : (
          <img src={exterior} alt="" />
        )}
        <div className="extriorcontentdiv">
          <div className="maincontenthai">
            {loading ? (
              <img className="LOADimg" src={load} alt="" />
            ) : (
              <img onClick={Imagelink} src={exteriorimg1} alt="" />
            )}
            {loading ? (
              <img className="LOADimg" src={load} alt="" />
            ) : (
              <img onClick={Imagelink} src={exteriorimg2} alt="" />
            )}
          </div>
          <div className="maincontenthai">
            {loading ? (
              <img className="LOADimg" src={load} alt="" />
            ) : (
              <img onClick={Imagelink} src={exteriorimg3} alt="" />
            )}
            {loading ? (
              <img className="LOADimg" src={load} alt="" />
            ) : (
              <img onClick={Imagelink} src={exteriorimg9} alt="" />
            )}
          </div>
          <div className="maincontenthai">
            {loading ? (
              <img className="LOADimg" src={load} alt="" />
            ) : (
              <img onClick={Imagelink} src={exteriorimg4} alt="" />
            )}
            {loading ? (
              <img className="LOADimg" src={load} alt="" />
            ) : (
              <img onClick={Imagelink} src={exteriorimg5} alt="" />
            )}
          </div>
          <div className="maincontenthai">
            {loading ? (
              <img className="LOADimg" src={load} alt="" />
            ) : (
              <img onClick={Imagelink} src={exteriorimg6} alt="" />
            )}
            {loading ? (
              <img className="LOADimg" src={load} alt="" />
            ) : (
              <img onClick={Imagelink} src={exteriorimg7} alt="" />
            )}
          </div>
          <div className="maincontenthaiii">
            {loading ? (
              <img className="LOADimg" src={load} alt="" />
            ) : (
              <img onClick={Imagelink} src={exteriorimg8} alt="" />
            )}
          </div>
        </div>
        <div className="verdiv">
          <div className="verbtn">
            <button onClick={() => navigate("/Modelos")}>Ver modelos</button>
            <button
              onClick={() =>
                navigate("/Intro", {
                  state: { name },
                })
              }
            >
              Regístrate
            </button>
            {/* <button onClick={() => setAlertModal(true)}>Regístrate</button> */}
          </div>
          <div className="lasttext">
            <p>
              <span>{Icons.infoicon}</span>
              {/* Disclaimers:  */}
              Las opciones pueden diferir según las regiones. Toda la
              información y las ilustraciones se basan en los datos disponibles
              en el momento de la publicación y están sujetos a cambios sin
              previo aviso. Favor de referirse a la hoja de producto para más
              información.
            </p>
          </div>
        </div>
        {show && (
          <div className="model">
            <div className="closeicon">
              <img src={close} alt="" onClick={() => setshow(false)} />
              <img src={image} alt="" />
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default Exterior;

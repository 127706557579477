import React, { useEffect } from "react";
import Section6 from "../../Components/Sections/Section6";
import Footer from "../../Components/UI/Footer";
import Navbar from "../../Components/UI/NavbarTwo";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const ColorSection = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  return (
    <>
      <Navbar />
      <div className="colorsection">
        <Section6 />
      </div>
      <Footer />
    </>
  );
};

export default ColorSection;

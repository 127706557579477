import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
// import interior from "../../../Assets/Images/interiorimg.jpg";
import interiorimg1 from "../../../Assets/EV9/20140_2024_EV9_GT-Line.jpg";
import interiorimg2 from "../../../Assets/EV9/21743_2024_EV9.jpg";
import interiorimg3 from "../../../Assets/EV9/21744_2024_EV9.jpg";
import load from "../../../Assets/Interior/load.png";
import "../../../Css/Gallery/Exterior.css";
import close from "../../../Assets/Images/close.png";
import { Icons } from "../../../Constant/Icons";

const Interior = () => {
  const navigate = useNavigate();
  const [show, setshow] = useState(false);
  const [loading, setLoading] = useState(true);
  const [image, setimage] = useState("");

  const Imagelink = (e) => {
    setimage(e.target.src);
    setshow(true);
  };

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 3000);
  }, []);

  let name = "EV9";
  return (
    <div className="exteriormaindiv">
      {/* {loading ? (
        <Skeleton
          className="SKELETEN"
          width={1990}
          height={1000}
          variant="rounded"
          sx={{ bgcolor: "grey.600" }}
        />
      ) : (
        <img src={interior} alt="" />
      )} */}

      <div className="extriorcontentdiv">
        <div className="maincontenthai">
          {loading ? (
            <img className="LOADimg" src={load} alt="" />
          ) : (
            <img onClick={Imagelink} src={interiorimg1} alt="" />
          )}
          {loading ? (
            <img className="LOADimg" src={load} alt="" />
          ) : (
            <img onClick={Imagelink} src={interiorimg2} alt="" />
          )}
        </div>
        <div className="maincontenthai">
          {loading ? (
            <img className="LOADimg" src={load} alt="" />
          ) : (
            <img onClick={Imagelink} src={interiorimg3} alt="" />
          )}
        </div>
      </div>
      <div className="verdiv">
        <div className="verbtn">
          <button onClick={() => navigate("/Modelos/EV9")}>Ver modelos</button>
          <button
            onClick={() =>
              navigate("/Intro", {
                state: { name },
              })
            }
          >
            Regístrate
          </button>
        </div>
        <div className="lasttext">
          <p>
            <span>{Icons.infoicon}</span>
            Las opciones pueden diferir según las regiones. Toda la información
            y las ilustraciones se basan en los datos disponibles en el momento
            de la publicación y están sujetos a cambios sin previo aviso. Favor
            de referirse a la hoja de producto para más información.
          </p>
        </div>
      </div>
      {show && (
        <div className="model">
          <div className="closeicon">
            <img src={close} alt="" onClick={() => setshow(false)} />
            <img src={image} alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Interior;

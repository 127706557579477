import React from "react";
import "../../Css/Button.css";
import tick from "../../Assets/Images/fi-br-check.png";

const Button = ({ children, type, style, onClick, variant, disable }) => {
  return (
    <div className="mainbtn">
      <button
        style={style}
        disabled={disable}
        className={variant}
        type={type}
        onClick={onClick}
      >
        {children}
      </button>
    </div>
  );
};

export default Button;

export const Bonobtn = ({ children, onClick, className }) => {
  return (
    <div className="bonobtn">
      <div>
        <img src={tick} alt="" />
      </div>
      <button className={className} onClick={onClick}>
        {children}
      </button>
    </div>
  );
};

import React, { useState, useEffect } from "react";
import "../../Css/Section6.css";
import checktick from "../../Assets/Images/checkboxtick.png";
import Button from "../UI/ButtonSec6";
import axios from "../../API";
// import img2 from "../../Assets/Images/car1.png";
import barLine from "../../Assets/Images/Bar-line.png";
import { Link, useNavigate } from "react-router-dom";
import Loader from "../../Components/UI/Loader";
// import img1 from "../../Assets/Images/CV_BL_Front_Yacht_Blue_DU3.png";
// import img2 from "../../Assets/Images/CV_BL_Front_Gravity_Blue_B4U.png";
// import img3 from "../../Assets/Images/CV_BL_Front_Moonscape_Matte_KLM.png";
// import img4 from "../../Assets/Images/CV_BL_Front_Steel_Gray_KLG.png";
// import img5 from "../../Assets/Images/CV_BL_Front_Snow_White_Pearl_SWP.png";
// import img6 from "../../Assets/Images/CV_BL_Front_Glacier_GLB.png";
// import img7 from "../../Assets/Images/CV_BL_Front_Runway_Red_CR5.png";
// import img8 from "../../Assets/Images/CV_BL_Front_Aurora_Black Pearl_ABP.png";
// import img9 from "../../Assets/Images/CV_BL_Front_Urban_Yellow_UBY.png";
// import img10 from "../../Assets/Images/CV_BL_Front_Interstella_Gray_AGT.png";

import img1 from "../../Assets/EV6 EX RWD/CV_BL_Front_Yacht_Blue_DU3.png";
// import img2 from "../../Assets/EV6 EX RWD/CV_BL_Front_Gravity_Blue_B4U.png";
// import img3 from "../../Assets/EV6 EX RWD/CV_BL_Front_Moonscape_Matte_KLM.png";
import img4 from "../../Assets/EV6 EX RWD/CV_BL_Front_Steel_Gray_KLG.png";
import img5 from "../../Assets/EV6 EX RWD/CV_BL_Front_Snow_White_Pearl_SWP.png";
import img6 from "../../Assets/EV6 EX RWD/CV_BL_Front_Glacier_GLB.png";
import img7 from "../../Assets/EV6 EX RWD/CV_BL_Front_Runway_Red_CR5.png";
import img8 from "../../Assets/EV6 EX RWD/CV_BL_Front_Aurora_Black Pearl_ABP.png";
// import img9 from "../../Assets/EV6 EX RWD/CV_BL_Front_Urban_Yellow_UBY.png";
import img10 from "../../Assets/EV6 EX RWD/CV_BL_Front_Interstella_Gray_AGT.png";
import { useFormik } from "formik";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { motion } from "framer-motion";
import { Icons } from "../../Constant/Icons";

const Section6 = ({ ref }) => {
  const colors = {
    colorss: [
      "#1A30AD",
      // "#34425F",
      // "#7A7B7E",
      "#9C9EA0",
      "#C6C4B7",
      "#F9F9F9",
      "#933531",
      "#1B1B1B",
      // "#CD8700",
      // "#4F5354",
    ],
  };
  const [color, setcolor] = useState(colors.colorss[0]);
  const [current, setcurrent] = useState(0);
  const [ApiData, setApiData] = useState([]);
  const [ApiError, setApiError] = useState("");
  const [Loading, setLoading] = useState(false);
  const [Loading2, setLoading2] = useState(false);

  localStorage.setItem("colorss", color);
  // console.log(color);
  //  else if (color == "#34425F") {
  //   name = "Gravity Blue";
  //   images = img2;
  // }
  // else if (color == "#7A7B7E") {
  //   name = "Moonscape";
  //   images = img3;
  // }
  // else if (color == "#CD8700") {
  //   name = "Urban Yellow";
  //   images = img9;
  // }
  // else if (color == "#4F5354") {
  //   name = "Interstellar Gray";
  //   images = img10;
  // }

  let name;
  let images;
  if (color == "#1A30AD") {
    name = "Yacht Blue";
    images = img1;
  } else if (color == "#9C9EA0") {
    name = "Steel Gray";
    images = img4;
  } else if (color == "#C6C4B7") {
    name = "Snow White Pearl";
    images = img5;
  } else if (color == "#F9F9F9") {
    name = "Glacier";
    images = img6;
  } else if (color == "#933531") {
    name = "Runway Red";
    images = img7;
  } else if (color == "#1B1B1B") {
    name = "Aurora Black Pearl";
    images = img8;
  }

  const getdata = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/get-inventory");
      // console.log(res.data);
      setApiData(res.data.data[0]);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      setApiError(error);
      setLoading(false);
    }
  };
  // console.log(ApiData);

  // useEffect(() => {
  //   getdata();
  // }, []);

  // const handlenavigate = () => {
  //     Navigate("/Model")
  //   // const userInfo = localStorage.getItem("user-info")
  //   // if(userInfo == null){
  //   //   Navigate("/Iniciar-Sesion")
  //   // }else{
  //   // }
  // }
  const Navigate = useNavigate();

  const initialValues = {
    Ext_Color_Code: color,
  };

  // const onSubmit = async () => {
  //   setLoading2(true);
  //   try {
  //     const response = await axios.post(
  //       "/get-inventory-detail",
  //       {
  //         Ext_Color_Code: "#1A30AD",
  //       },
  //       {
  //         headers: {
  //           "content-type": "application/json",
  //         },
  //       }
  //     );
  //     // console.log(response)
  //     if (response) {
  //       setLoading2(false);
  //     }
  //     localStorage.setItem("inventry-id", response.data.data.invtry_id);
  //     if (response.data.message === "Record Found.") {
  //       Navigate("/Modelos");
  //     }
  //   } catch (error) {
  //     // console.log(error);
  //     setLoading2(false);
  //   }
  // };

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues: initialValues,
    // onSubmit,
  });

  return (
    <>
      <div className="mnb-vcxz" ref={ref}>
        <form
          className="sec6 position-relative"
          id="Colores"
          // onSubmit={handleSubmit}
        >
          <p className="form-error"> {ApiError} </p>
          {!Loading && (
            <div className="d-flex w-100 flex-column justify-content-center align-items-center">
              <div className="res-sec">
                <div className="leftsideeee">
                  <div className="namecar">
                    <span className="Heading-sec6">KIA EV6 2023</span>
                    <span>{name}</span>
                  </div>
                  <div className="cbtn2">
                    {colors.colorss.map((curcolor, index) => {
                      return (
                        <button
                          type="button"
                          className={
                            index === current ? "cobtn active" : "cobtn"
                          }
                          // onClick={handleclick}
                          onClick={() => setcolor(curcolor)}
                          key={index}
                          onChange={handleChange}
                          style={{ backgroundColor: curcolor }}
                        >
                          {color === curcolor ? (
                            <img src={checktick} alt="" />
                          ) : null}
                        </button>
                      );
                    })}
                  </div>
                  {/* <div className="pt-1">
                <span className="sec-6-price">$41,400 MSRP inicial</span>
              </div> */}
                  {/* <Link> */}
                  <div className="btnssss">
                    <button
                      className="btn1"
                      type="button"
                      onClick={() => Navigate("/Modelos")}
                    >
                      Reserva con $500
                    </button>
                  </div>
                  {/* </Link> */}
                </div>
                <div className="rightsideee">
                  <motion.img
                    initial={{ x: 70, opacity: 0 }}
                    whileInView={{ x: 0, opacity: 1 }}
                    transition={{ duration: 0.5 }}
                    src={images}
                    alt=""
                  />
                </div>
                <img
                  src={barLine}
                  alt="alt"
                  className="position-absolute bar-img"
                />
              </div>
              <div className="lasttext">
                <p>
                  <span>{Icons.infoicon}</span>
                  {/* Disclaimer:·  */}
                  Las ilustraciones, fotografías y especificaciones en este
                  portal se refieren a la más reciente información disponible
                  sobre el producto al momento de la impresión. Kia de Puerto
                  Rico se reserva el derecho de hacer cambios en cualquier
                  momento, sin que medie previa notificación en cuanto a
                  precios, colores, materiales, equipo, especificaciones y
                  modelos y también para proceder a descontinuar aquellos que
                  así se requieran.
                </p>
              </div>
            </div>
          )}
          {Loading && <Loader />}
          {!Loading2 && <div></div>}
          {Loading2 && (
            <div>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
                // onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          )}
        </form>
        {/* <Footer/> */}
      </div>
    </>
  );
};

export default Section6;

import { Modal } from "react-bootstrap";

export function ShowAlertModal(props) {
  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      backdrop="static"
      centered
      style={{ zIndex: "10000" }}
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body className="white text-center">
        <p className="Modal-text">
          <div>
            Esta página no estará disponible para reservas hasta Abril 2023,
            pero podrás navegar y conocer más sobre el EV6.
          </div>
        </p>
        <div className="p-1"></div>
      </Modal.Body>
    </Modal>
  );
}

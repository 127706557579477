import React, { useEffect, useState } from "react";
import Wrapper from "../../Components/UI/Wrapper";
import Card from "../../Components/UI/Card";
import Input from "../../Components/UI/Input";
import Button from "../../Components/UI/Button";
import "../../Css/Auth/ForgotPassword.css";
import { useFormik } from "formik";
import { ForgotPassSchema } from "../../Schemas";
import axios from "../../API";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const initialValues = {
  Email: "",
};

const ForgotPassword = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);
  const Navigate = useNavigate();

  const [IsError, setIsError] = useState("");
  const [Loading, setLoading] = useState(false);

  const onSubmit = async (values) => {
    // console.log(values);
    setLoading(true);
    try {
      const response = await axios.post("/forgot-password", {
        Email: values.Email,
      });
      // console.log("response", response);
      setIsError(response.data.message);
      setLoading(false);
      if (response.data.message == "Password send to your Email") {
        localStorage.setItem(
          "ForgotPass-Email",
          JSON.stringify(response.data.data)
        );
        Navigate("/Cambiar-Contrasena");
      }
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: ForgotPassSchema,
      onSubmit,
    });

  return (
    <div>
      <Wrapper>
        <Card head={"Olvidaste contraseña"}>
          <form onSubmit={handleSubmit} className="mainform10">
            <div className="inputs3">
              <p>
                Ingresa el correo electrónico con el que te registraste para
                enviarte una contraseña nueva.
              </p>
              <span className="form-error-head"> {IsError} </span>
              <div>
                <Input
                  text={"Correo electrónico"}
                  type={"email"}
                  placeholder={"Ingrese su correo electrónico"}
                  name="Email"
                  value={values.Email}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  required
                />
                {errors.Email && touched.Email ? (
                  <p className="form-error"> {errors.Email} </p>
                ) : null}
              </div>
            </div>
            <div className="">
              <Button variant={"red"} type={"submit"}>
                ENVIAR
              </Button>
            </div>
          </form>
        </Card>
        {!Loading && <div></div>}
        {Loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </Wrapper>
    </div>
  );
};

export default ForgotPassword;

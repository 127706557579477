/* eslint-disable eqeqeq */
import React, { useContext, useEffect, useState } from "react";
import Button from "../../Components/UI/Button";
import Footer from "../../Components/UI/Footer";
import "../../Css/Profile/Cart.css";
import Wrapper from "../../Components/UI/Wrapper";
import { useLocation, useNavigate } from "react-router-dom";
import NavbarThird from "../../Components/UI/NavbarThird";
import Loader from "../../Components/UI/Loader";
import axios from "../../API";
import Modal from "react-bootstrap/Modal";
import img1 from "../../Assets/EV6 EX RWD/CV_BL_Front_Yacht_Blue_DU3.png";
import img2 from "../../Assets/EV6 EX RWD/CV_BL_Front_Gravity_Blue_B4U.png";
import img3 from "../../Assets/EV6 EX RWD/CV_BL_Front_Moonscape_Matte_KLM.png";
import img4 from "../../Assets/EV6 EX RWD/CV_BL_Front_Steel_Gray_KLG.png";
import img5 from "../../Assets/EV6 EX RWD/CV_BL_Front_Snow_White_Pearl_SWP.png";
import img6 from "../../Assets/EV6 EX RWD/CV_BL_Front_Glacier_GLB.png";
import img7 from "../../Assets/EV6 EX RWD/CV_BL_Front_Runway_Red_CR5.png";
import img8 from "../../Assets/EV6 EX RWD/CV_BL_Front_Aurora_Black Pearl_ABP.png";
import img9 from "../../Assets/EV6 EX RWD/CV_BL_Front_Urban_Yellow_UBY.png";
import img10 from "../../Assets/EV6 EX RWD/CV_BL_Front_Interstella_Gray_AGT.png";
import img11 from "../../Assets/GT LINE/CV_GT_Line_Front_Yacht_Blue_DU3-removebg-preview.png";
import img13 from "../../Assets/GT LINE/CV_GT_Line_Front_Moonscape_Matte_KLM-removebg-preview.png";
import img15 from "../../Assets/GT LINE/CV_GT_Line_Front_Snow_White_Pearl_SWP-removebg-preview.png";
import img17 from "../../Assets/GT LINE/CV_GT_Line_Front_Runway_Red_CR5-removebg-preview.png";
import img18 from "../../Assets/GT LINE/CV_GT_Line_Front_Aurora_Black_Pearl_ABP-removebg-preview.png";
import NewImg1 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Yacht_Blue_DU3.png";
import NewImg2 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Runway_Red_CR5.png";
import NewImg3 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Aurora_Black Pearl_ABP.png";
import NewImg4 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Moonscape_Matte_KLM.png";
import NewImg5 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Snow_White_Pearl_SWP.png";

import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import { useSelector } from "react-redux";
import { GlobalContext } from "../../Context/GlobalContext";
import { imagesdata } from "../../Components/Data/Data";

const Cart = () => {
  const ModalDataFromRedux = useSelector((state) => state.carData);

  const navigate = useNavigate();

  function Orderplaced(props) {
    function ChangeBtn() {
      setModalShow(false);
      navigate("/Perfil/History");
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}
      >
        {/* <Modal.Header closeButton></Modal.Header> */}
        <Modal.Body className="white text-center pt-3">
          <h4 className="pb-3">Pedido ya realizado</h4>
          <p className="Modal-text">
            Usted ha realizado una reserva del EV por lo que con este usuario no
            puede volver a realizar una reserva.
          </p>
          <div className="p-2"></div>
          <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
            <Button variant="red" onClick={ChangeBtn}>
              Ir al perfil
            </Button>
          </div>
          <div className="p-3"></div>
        </Modal.Body>
      </Modal>
    );
  }

  const [removedata, setremovedata] = useState(false);

  useEffect(() => {
    if (removedata) {
      localStorage.removeItem("StaticCartData");
      setremovedata(false);
    }
  }, [removedata]);

  // Get User Data
  const UserData = JSON.parse(localStorage.getItem("user-info"));

  // Store Api Data in useState Variable
  const [CartData, setCartData] = useState([]);
  // const [disable, setdisable] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  // Api Error Data useState Variable
  const [ApiError, setApiError] = useState("");

  // Loader useState Variable
  const [Loading, setLoading] = useState(false);

  const getCartData = async () => {
    setLoading(true);
    if (UserData) {
      try {
        const res = await axios.get("/get-cart", {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData.Token}`,
          },
        });
        // console.log("Cart ...............", res);
        if (res.data.message == "Record") {
          setCartData(res.data);
          setLoading(false);
        } else {
          setApiError(res);
          setLoading(false);
        }
      } catch (error) {
        setLoading(false);
        setApiError(error);
        // console.log("errorr..." ,error)
      }
    } else {
      setLoading(false);
    }
  };

  // gethistory api

  const getOrderHistory = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/get-history", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${UserData?.Token}`,
        },
      });
      if (res) {
        // console.log("OrderHistory...............", res);
        if (res.data.message == "Record") {
          setModalShow(true);
        }
        if (res.data.status == "success") {
          setLoading(false);
        } else {
          setLoading(false);
          // console.log("res Error....", res);
        }
      }
    } catch (error) {
      setLoading(false);
      // console.log("Catched Error....", error);
    }
  };

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
    if (localStorage.getItem("user-info")) {
      getCartData();
    }
    if (localStorage.getItem("user-info")) {
      getOrderHistory();
    }
  }, []);

  const StaticCartData = JSON.parse(localStorage.getItem("StaticCartData"));
  const { setD4000Show } = useContext(GlobalContext);

  const colorcode = imagesdata.filter(
    (item) => item.colorcode === StaticCartData?.ExtColorCode
  );
  const colorcode2 = imagesdata.filter(
    (item) => item.colorcode === CartData?.data?.Ext_Color_Code
  );

  let images;
  if (CartData.data) {
    if (CartData.data.ModelType === "EV6") {
      if (CartData.data.Model == "CVE1" || "EV6 EX RWD") {
        if (CartData.data.Ext_Color_Code == "#1A30AD") {
          images = img1;
        } else if (CartData.data.Ext_Color_Code == "#34425F") {
          images = img2;
        } else if (CartData.data.Ext_Color_Code == "#666666") {
          images = img3;
        } else if (CartData.data.Ext_Color_Code == "#9C9EA0") {
          images = img4;
        } else if (CartData.data.Ext_Color_Code == "#C6C4B7") {
          images = img5;
        } else if (CartData.data.Ext_Color_Code == "#F9F9F9") {
          images = img6;
        } else if (CartData.data.Ext_Color_Code == "#933531") {
          images = img7;
        } else if (CartData.data.Ext_Color_Code == "#1B1B1B") {
          images = img8;
        } else if (CartData.data.Ext_Color_Code == "#CD8700") {
          images = img9;
        } else if (CartData.data.Ext_Color_Code == "#4F5354") {
          images = img10;
        }
      } else if (CartData.data.Model == "GT-LINE RWD") {
        if (CartData.data.Ext_Color_Code == "#1A30AD") {
          images = NewImg1;
        } else if (CartData.data.Ext_Color_Code == "#933531") {
          images = NewImg2;
        } else if (CartData.data.Ext_Color_Code == "#1B1B1B") {
          images = NewImg3;
        } else if (CartData.data.Ext_Color_Code == "#666666") {
          images = NewImg4;
        } else if (CartData.data.Ext_Color_Code == "#C6C4B7") {
          images = NewImg5;
        }
      } else if (CartData.data.Model == "EV6 GT-LINE (E-AWD)") {
        if (CartData.data.Ext_Color_Code == "#1A30AD") {
          images = img11;
        } else if (CartData.data.Ext_Color_Code == "#933531") {
          images = img17;
        } else if (CartData.data.Ext_Color_Code == "#1B1B1B") {
          images = img18;
        } else if (CartData.data.Ext_Color_Code == "#666666") {
          images = img13;
        } else if (CartData.data.Ext_Color_Code == "#C6C4B7") {
          images = img15;
        }
      }
    } else if (CartData.data.ModelType === "EV9") {
      if (CartData.data.Ext_Color_Code === colorcode2[0]?.colorcode) {
        images = colorcode2[0]?.images[5];
      }
    }
  } else if (StaticCartData) {
    if (StaticCartData?.Mainmodel === "EV6") {
      if (StaticCartData.Model == "CVE1" || "EV6 EX RWD") {
        if (StaticCartData.ExtColorCode == "#1A30AD") {
          images = img1;
        } else if (StaticCartData.ExtColorCode == "#34425F") {
          images = img2;
        } else if (StaticCartData.ExtColorCode == "#666666") {
          images = img3;
        } else if (StaticCartData.ExtColorCode == "#9C9EA0") {
          images = img4;
        } else if (StaticCartData.ExtColorCode == "#C6C4B7") {
          images = img5;
        } else if (StaticCartData.ExtColorCode == "#F9F9F9") {
          images = img6;
        } else if (StaticCartData.ExtColorCode == "#933531") {
          images = img7;
        } else if (StaticCartData.ExtColorCode == "#1B1B1B") {
          images = img8;
        } else if (StaticCartData.ExtColorCode == "#CD8700") {
          images = img9;
        } else if (StaticCartData.ExtColorCode == "#4F5354") {
          images = img10;
        }
      } else if (StaticCartData.Model == "GT-LINE RWD") {
        if (StaticCartData.Ext_Color_Code == "#1A30AD") {
          images = NewImg1;
        } else if (StaticCartData.Ext_Color_Code == "#933531") {
          images = NewImg2;
        } else if (StaticCartData.Ext_Color_Code == "#1B1B1B") {
          images = NewImg3;
        } else if (StaticCartData.Ext_Color_Code == "#666666") {
          images = NewImg4;
        } else if (StaticCartData.Ext_Color_Code == "#C6C4B7") {
          images = NewImg5;
        }
      } else if (StaticCartData.Model == "EV6 GT-LINE (E-AWD)") {
        if (StaticCartData.Ext_Color_Code == "#1A30AD") {
          images = img11;
        } else if (StaticCartData.Ext_Color_Code == "#933531") {
          images = img17;
        } else if (StaticCartData.Ext_Color_Code == "#1B1B1B") {
          images = img18;
        } else if (StaticCartData.Ext_Color_Code == "#666666") {
          images = img13;
        } else if (StaticCartData.Ext_Color_Code == "#C6C4B7") {
          images = img15;
        }
      }
    } else if (StaticCartData?.Mainmodel === "EV9") {
      if (StaticCartData?.ExtColorCode === colorcode[0]?.colorcode) {
        images = colorcode[0]?.images[5];
      }
    }
  }

  var nf = new Intl.NumberFormat();

  const clearCart = async () => {
    setLoading(true);
    try {
      const res = await axios.post(
        "/delete-cart",
        {
          OrderId: CartData.data.order_id,
        },
        {
          headers: {
            "content-type": "application/json",
            Authorization: `Bearer ${UserData?.Token}`,
          },
        }
      );
      if (res) {
        setLoading(false);
        navigate("/Modelos");
      }
    } catch (error) {
      setLoading(false);
      // console.log("Catched Error....", error);
    }
  };

  const newdata = ModalDataFromRedux.filter(
    (item) => item?.name == StaticCartData?.Model
  );
  const fromCartdata = ModalDataFromRedux.filter(
    (item) => item?.name == CartData?.data?.Model
  );

  let name = StaticCartData?.Mainmodel || CartData?.data?.ModelType;
  return (
    <>
      <Helmet>
        <title>Kia EV | Tu Carrito | Reserva en línea</title>
        <link rel="canonical" href="https://kiaevpr.com/#/Cart" />
        <meta
          name="description"
          content="Mira el modelo del nuevo y totalmente eléctrico Kia EV, que añadiste a tu carrito de compra durante la reserva en línea."
        />
      </Helmet>
      <NavbarThird />
      <Wrapper>
        <div className="cart">
          <div className="d-flex justify-content-center white pt-4 ">
            <div className="d-flex justify-content-center heading-policy border-bottom-for-policy">
              <div className="p-4"></div>
              {StaticCartData ||
              (CartData.status == "success" &&
                CartData.message == "Record" &&
                CartData.data.status == "Pending") ? (
                <span>Carrito</span>
              ) : (
                <span onClick={() => setModalShow(true)}>Carrito vacío</span>
              )}
              <div className="p-4"></div>
            </div>
          </div>
          {Loading && <Loader />}
          <div style={{ color: "white" }}>
            {!Loading &&
            CartData.status == "success" &&
            CartData.message == "Record" ? (
              <div className="d-flex flex-column align-items-center justify-content-center">
                <div className="p-3"></div>
                <div
                  className=" d-flex gap-4 align-items-center cartdiv"
                  style={{
                    cursor: "pointer",
                    backgroundColor: "#9f9f9f",
                    borderRadius: "18px",
                    padding: "0 20px",
                  }}
                  onClick={() => navigate("/ContinuePayment")}
                >
                  <div style={{ width: "150px" }}>
                    <img src={images} width="100%" className="car-img" />
                  </div>
                  <div style={{ width: "70%" }}>
                    <div className="d-flex gap-4 align-items-center justify-content-between w-100">
                      <div className="d-flex flex-column">
                        <span className="OrderText">
                          Model: {CartData.data.Model}
                        </span>
                        {StaticCartData?.Mainmodel === "EV6" ? (
                          <>
                            <p className="OrderText" style={{ margin: "0" }}>
                              MSRP:{" "}
                              <span
                                className={
                                  fromCartdata[0]?.isOfferActivated &&
                                  "line-through"
                                }
                              >
                                {fromCartdata[0]?.price}
                              </span>
                            </p>
                            {fromCartdata[0]?.isOfferActivated && (
                              <p style={{ fontSize: "12px" }}>
                                BONO{" "}
                                <span
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setD4000Show(true);
                                  }}
                                >
                                  $5,000{" "}
                                </span>
                                <span
                                  style={{ fontSize: "20px", color: "#F3C300" }}
                                >
                                  {/* ${nf.format(parseInt(StaticCartData.Price))} */}
                                  {fromCartdata[0].finalPrice}
                                </span>
                              </p>
                            )}
                          </>
                        ) : (
                          <p>
                            MSRP: {CartData.data.Price || StaticCartData?.Price}
                          </p>
                        )}
                      </div>
                      <div className="d-flex flex-column justify-content-center gap-3 align-items-center">
                        <button
                          className="delete-btn"
                          onClick={(e) => {
                            e.stopPropagation();
                            clearCart();
                          }}
                        >
                          <i className="fa fa-trash" aria-hidden="true"></i>
                        </button>
                        <svg
                          width="15"
                          height="25"
                          viewBox="0 0 15 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M14.3723 11.1213L3.33586 0.553275C3.1546 0.377961 2.93894 0.238811 2.70132 0.143851C2.46371 0.0488906 2.20885 0 1.95144 0C1.69403 0 1.43917 0.0488906 1.20156 0.143851C0.963943 0.238811 0.748283 0.377961 0.567015 0.553275C0.203845 0.903726 0 1.37779 0 1.87194C0 2.36608 0.203845 2.84015 0.567015 3.1906L10.219 12.5428L0.567015 21.8015C0.203845 22.152 0 22.6261 0 23.1202C0 23.6143 0.203845 24.0884 0.567015 24.4389C0.747603 24.6156 0.962959 24.7562 1.2006 24.8525C1.43825 24.9488 1.69345 24.999 1.95144 25C2.20942 24.999 2.46463 24.9488 2.70228 24.8525C2.93992 24.7562 3.15528 24.6156 3.33586 24.4389L14.3723 13.8708C14.5702 13.6957 14.7281 13.4831 14.8362 13.2465C14.9442 13.0099 15 12.7544 15 12.4961C15 12.2378 14.9442 11.9822 14.8362 11.7456C14.7281 11.509 14.5702 11.2964 14.3723 11.1213V11.1213Z"
                            fill="#ED1B24"
                          />
                        </svg>
                      </div>
                      {/* <div className='pe-1'></div> */}
                    </div>
                  </div>
                </div>
                <div className="p-3"></div>
              </div>
            ) : (
              <>
                {!Loading && StaticCartData ? (
                  <div className="d-flex flex-column align-items-center justify-content-center">
                    <div className="p-3"></div>
                    {/* <div className="d-flex align-items-center justify-content-center gap-3"> */}
                    <div
                      className=" d-flex gap-4 align-items-center cartdiv"
                      style={{
                        cursor: "pointer",
                        backgroundColor: "#9f9f9f",
                        borderRadius: "18px",
                        padding: "0 20px",
                      }}
                      // onClick={() => navigate("/ContinuePayment")}
                      onClick={() =>
                        navigate("/Intro", {
                          state: { name },
                        })
                      }
                    >
                      <div className="cart-img-div">
                        <img src={images} width="100%" className="car-img" />
                      </div>
                      <div className="txt-div-cart">
                        <div className="d-flex gap-4 align-items-center justify-content-between w-100">
                          <div className="d-flex flex-column">
                            <span className="OrderText">
                              Model: {StaticCartData.Model}
                            </span>
                            {StaticCartData?.Mainmodel === "EV6" ? (
                              <>
                                <p
                                  className="OrderText"
                                  style={{ margin: "0" }}
                                >
                                  MSRP:{" "}
                                  <span
                                    className={
                                      newdata[0]?.isOfferActivated &&
                                      "line-through"
                                    }
                                  >
                                    {newdata[0]?.price}
                                  </span>
                                </p>
                                {newdata[0]?.isOfferActivated && (
                                  <p style={{ fontSize: "12px" }}>
                                    BONO{" "}
                                    <span
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        setD4000Show(true);
                                      }}
                                    >
                                      $5,000{" "}
                                    </span>
                                    <span
                                      style={{
                                        fontSize: "20px",
                                        color: "#F3C300",
                                      }}
                                    >
                                      {/* ${nf.format(parseInt(StaticCartData.Price))} */}
                                      {newdata[0].finalPrice}
                                    </span>
                                  </p>
                                )}
                              </>
                            ) : (
                              <p>MSRP: ${StaticCartData?.Price}</p>
                            )}
                          </div>
                          <div className="d-flex flex-column justify-content-center gap-3 align-items-center">
                            <button
                              className="delete-btn"
                              onClick={(e) => {
                                e.stopPropagation();
                                getCartData();
                                setremovedata(true);
                              }}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                            <svg
                              width="15"
                              height="25"
                              viewBox="0 0 15 25"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M14.3723 11.1213L3.33586 0.553275C3.1546 0.377961 2.93894 0.238811 2.70132 0.143851C2.46371 0.0488906 2.20885 0 1.95144 0C1.69403 0 1.43917 0.0488906 1.20156 0.143851C0.963943 0.238811 0.748283 0.377961 0.567015 0.553275C0.203845 0.903726 0 1.37779 0 1.87194C0 2.36608 0.203845 2.84015 0.567015 3.1906L10.219 12.5428L0.567015 21.8015C0.203845 22.152 0 22.6261 0 23.1202C0 23.6143 0.203845 24.0884 0.567015 24.4389C0.747603 24.6156 0.962959 24.7562 1.2006 24.8525C1.43825 24.9488 1.69345 24.999 1.95144 25C2.20942 24.999 2.46463 24.9488 2.70228 24.8525C2.93992 24.7562 3.15528 24.6156 3.33586 24.4389L14.3723 13.8708C14.5702 13.6957 14.7281 13.4831 14.8362 13.2465C14.9442 13.0099 15 12.7544 15 12.4961C15 12.2378 14.9442 11.9822 14.8362 11.7456C14.7281 11.509 14.5702 11.2964 14.3723 11.1213V11.1213Z"
                                fill="#ED1B24"
                              />
                            </svg>
                          </div>
                          {/* <div className='pe-1'></div> */}
                        </div>
                      </div>
                    </div>
                    {/* </div> */}
                    <div className="p-3"></div>
                  </div>
                ) : (
                  <div>
                    {!Loading && (
                      <div>
                        <div className="p-3"></div>
                        <div className="d-flex justify-content-center">
                          <div className="d-flex cart-btns-div">
                            <Button
                              variant="white"
                              onClick={() => navigate("/Inicio")}
                            >
                              Conocer más
                            </Button>
                            <Button
                              variant="red"
                              onClick={() => {
                                CartData.status == "success" &&
                                CartData.message == "Record"
                                  ? navigate("/ContinuePayment")
                                  : navigate("/Modelos");
                              }}
                            >
                              Reservar
                            </Button>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <Orderplaced show={modalShow} onHide={() => setModalShow(false)} />
      </Wrapper>
      <Footer />
    </>
  );
};

export default Cart;

import React from "react";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import PaymentMethod from "./PaymentMethod";

// Live
// const stripePromise = loadStripe(
//   "pk_live_51MAIgBGul4sfg2qk549FfkXF7hETsD15u6j7CD9n1dYx9qqxgRZWkjJoCQsnvqa0Lr0Bbvab0LfxtQFLjEbKLqbB00R3RG1jce"
// );
const StripePublicKey = process.env.REACT_APP_STRIP_KEY;
const stripePromise = loadStripe(StripePublicKey);

// Test
// const stripePromise = loadStripe(
//   "pk_test_51KVyV0AJ9kDiPelhQATfYgez7QYawfNdfeZqusSDTgT3ay4yvkJe6xmSP0SfckSW2EOcIOXgFClgoqc28eVyEco600Zr5672lu"
// );

const StripeContainer = () => {
  return (
    <Elements stripe={stripePromise}>
      <PaymentMethod />
    </Elements>
  );
};

export default StripeContainer;

import React, { useEffect, useState } from "react";
import "../../Css/FAQS/Faqs.css";
import NavbarTwo from "../../Components/UI/NavbarTwo";
import Footer from "../../Components/UI/Footer";
import { useNavigate } from "react-router-dom";
import axios from "../../API";
import Loader from "../../Components/UI/Loader";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const FAQ = () => {
  const [select, setselected] = useState(null);
  const [apidata, setapidata] = useState([]);
  const [Loading, setLoading] = useState(true);

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  let ico = (
    <svg
      width="30"
      height="30"
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle
        cx="15"
        cy="15"
        r="15"
        transform="rotate(90 15 15)"
        fill="#D40000"
      />
      <path
        d="M9.99986 15.9998H17.5899L14.2899 19.2898C14.1016 19.4781 13.9958 19.7335 13.9958 19.9998C13.9958 20.2661 14.1016 20.5215 14.2899 20.7098C14.4782 20.8981 14.7336 21.0039 14.9999 21.0039C15.2662 21.0039 15.5216 20.8981 15.7099 20.7098L20.7099 15.7098C20.8009 15.6147 20.8723 15.5026 20.9199 15.3798C21.0199 15.1364 21.0199 14.8633 20.9199 14.6198C20.8723 14.4971 20.8009 14.3849 20.7099 14.2898L15.7099 9.28982C15.6169 9.19609 15.5063 9.12169 15.3844 9.07092C15.2626 9.02015 15.1319 8.99402 14.9999 8.99402C14.8678 8.99402 14.7371 9.02015 14.6153 9.07092C14.4934 9.12169 14.3828 9.19609 14.2899 9.28982C14.1961 9.38278 14.1217 9.49338 14.071 9.61524C14.0202 9.7371 13.9941 9.8678 13.9941 9.99981C13.9941 10.1318 14.0202 10.2625 14.071 10.3844C14.1217 10.5063 14.1961 10.6169 14.2899 10.7098L17.5899 13.9998H9.99986C9.73464 13.9998 9.48029 14.1052 9.29275 14.2927C9.10522 14.4802 8.99986 14.7346 8.99986 14.9998C8.99986 15.265 9.10522 15.5194 9.29275 15.7069C9.48029 15.8945 9.73464 15.9998 9.99986 15.9998Z"
        fill="white"
      />
    </svg>
  );

  let ico2 = (
    <svg
      width="16"
      height="10"
      viewBox="0 0 16 10"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.56106 8.90637L14.8824 2.06721C14.9873 1.95488 15.0705 1.82123 15.1273 1.67399C15.1841 1.52674 15.2134 1.36881 15.2134 1.20929C15.2134 1.04978 15.1841 0.891841 15.1273 0.744594C15.0705 0.597347 14.9873 0.463704 14.8824 0.351374C14.6728 0.126321 14.3892 0 14.0937 0C13.7981 0 13.5145 0.126321 13.3049 0.351374L7.71075 6.33262L2.17255 0.351374C1.96293 0.126321 1.67936 0 1.38378 0C1.0882 0 0.804635 0.126321 0.595009 0.351374C0.489293 0.463284 0.40519 0.596738 0.347574 0.744004C0.289958 0.89127 0.259975 1.04942 0.259361 1.20929C0.259975 1.36916 0.289958 1.52731 0.347574 1.67458C0.40519 1.82184 0.489293 1.9553 0.595009 2.06721L6.91638 8.90637C7.02115 9.02903 7.14831 9.12691 7.28984 9.19386C7.43137 9.26081 7.58421 9.29538 7.73872 9.29538C7.89323 9.29538 8.04607 9.26081 8.1876 9.19386C8.32913 9.12691 8.45629 9.02903 8.56106 8.90637Z"
        fill="white"
      />
    </svg>
  );

  const getquestion = async () => {
    try {
      const res = await axios.get("/get-question");
      if (res.data.data) {
        setapidata(res.data.data);
      }
      if (!res) {
        setLoading(true);
      } else {
        setLoading(false);
      }
      //  console.log("resss",res)
    } catch (error) {
      // console.log("erorr,,,,", error);
    }
  };

  useEffect(() => {
    getquestion();
  }, []);

  const toggle = (i, Que_Id) => {
    localStorage.setItem("Que_Id", Que_Id);
    if (select === i) {
      return setselected(null);
    }
    setselected(i);
  };

  const navigate = useNavigate();

  return (
    <div>
      <NavbarTwo />
      {/* <WrapperSecond> */}
      <div className="faqwrapper">
        <div className="faqdiv" style={{ marginTop: "4rem" }}>
          <div className="venta">
            <h1 className="pre">Preguntas frecuentes</h1>
            <hr className="hr" />
          </div>
          {!Loading && (
            <div className="mainfaq">
              {apidata.map((curElem, i) => {
                const { Que, Que_Id, Ans } = curElem;
                return (
                  <div
                    onClick={() => toggle(i, Que_Id)}
                    key={curElem.Que_Id}
                    className={select === i ? "questionback" : "question"}
                  >
                    <span className={select === i ? "svg" : ""}>{ico}</span>
                    <div>
                      <h3
                        onClick={() => {
                          localStorage.setItem("Que_Id", Que_Id);
                          // navigate("/preguntasFaqs");
                        }}
                      >
                        {Que}
                      </h3>
                      {
                        <p className={select === i ? "contentshow" : "content"}>
                          {Ans}
                        </p>
                      }
                    </div>
                    <span>{ico2}</span>
                  </div>
                );
              })}
            </div>
          )}
          {Loading && <Loader />}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default FAQ;

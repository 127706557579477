import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Button from "../../Components/UI/Button";
import Navbar from "../../Components/UI/NavbarTwo";
import "../../Css/Error.css";
import Wrapper from "../../Components/UI/Wrapper";

const Error = () => {
  const Navigate = useNavigate();
  return (
    <>
      <Navbar />
      <Wrapper>
        <div className="white d-flex justify-content-center align-items-center error-page ">
          <div className="d-flex flex-column align-items-center gap-4">
            <h1>Error 404 page not found</h1>
            <Button onClick={() => Navigate("/Inicio")} variant={"red"}>
              Go Back Home
            </Button>
          </div>
        </div>
      </Wrapper>
      <Outlet />
    </>
  );
};

export default Error;

import React from "react";
import { createRoot } from "react-dom/client";
import "./index.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "bootstrap/dist/css/bootstrap.min.css";
import { HashRouter } from "react-router-dom";
// import ReactGA from "react-ga4";

const container = document.getElementById("root");
const root = createRoot(container);

// let ga_key = process.env.REACT_APP_ANALYTIC_KEY;

// ReactGA.initialize(`${ga_key}`);

root.render(
  // <React.StrictMode>
  <HashRouter>
    <App />
  </HashRouter>
  // </React.StrictMode>
);

// const SendAnalytics = () => {
//   ReactGA.send({
//     hitType: "pageview",
//     page: window.location.pathname,
//   });
// };

reportWebVitals();
// reportWebVitals(SendAnalytics);

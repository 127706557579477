import axios from "axios";

const ApiUrl = process.env.REACT_APP_API_KEY;

const API = axios.create({
  // baseURL: "http://kiaevpr.com/KIA-ADMIN/index.php/api/v1",
  baseURL: ApiUrl,
});

export default API;

import React, { useState, useEffect } from "react";
import "../../Css/FAQS/FaqsTwo.css";
import axios from "../../API";
import NavbarTwo from "../../Components/UI/NavbarTwo";
import Footer from "../../Components/UI/Footer";
import Loader from "../../Components/UI/Loader";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const FAQTwo = () => {
  let [faq, setfaq] = useState([]);
  const [Loading, setLoading] = useState(true);

  const Navigate = useNavigate();

  let Que_Id = localStorage.getItem("Que_Id");

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  useEffect(() => {
    if (!localStorage.getItem("Que_Id")) {
      Navigate("/preguntas");
    }
  }, []);

  const getFaqData = async () => {
    try {
      const res = await axios.get(`/get-faq/${Que_Id}`);
      setfaq(res.data.data);
      if (!res) {
        setLoading(true);
      } else {
        setLoading(false);
      }
      // console.log(res)
    } catch (error) {
      // console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    getFaqData();
  }, []);

  return (
    <div>
      <NavbarTwo />
      <div className="freq">
        <div className="overly"></div>
        <h1 className="white">Preguntas frecuentes</h1>
        {!Loading && (
          <div className="lists">
            {faq.map((curelem, index) => {
              return (
                <div key={index} className="faqbox">
                  <span>
                    <svg
                      width="35"
                      height="34"
                      viewBox="0 0 35 34"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <ellipse
                        cx="17.5188"
                        cy="16.679"
                        rx="16.5891"
                        ry="16.5938"
                        transform="rotate(90 17.5188 16.679)"
                        fill="#D40000"
                      />
                      <path
                        d="M11.9873 17.7851H20.3837L16.7331 21.4237C16.5248 21.6319 16.4078 21.9144 16.4078 22.2089C16.4078 22.5034 16.5248 22.7858 16.7331 22.9941C16.9414 23.2023 17.2239 23.3193 17.5185 23.3193C17.8131 23.3193 18.0957 23.2023 18.304 22.9941L23.8352 17.4644C23.9359 17.3592 24.0149 17.2352 24.0675 17.0994C24.1782 16.8302 24.1782 16.5282 24.0675 16.2589C24.0149 16.1232 23.9359 15.9991 23.8352 15.8939L18.304 10.3642C18.2011 10.2606 18.0788 10.1783 17.944 10.1222C17.8092 10.066 17.6646 10.0371 17.5185 10.0371C17.3725 10.0371 17.2279 10.066 17.0931 10.1222C16.9583 10.1783 16.8359 10.2606 16.7331 10.3642C16.6294 10.4671 16.5471 10.5894 16.4909 10.7241C16.4348 10.8589 16.4059 11.0035 16.4059 11.1495C16.4059 11.2955 16.4348 11.44 16.4909 11.5748C16.5471 11.7096 16.6294 11.8319 16.7331 11.9347L20.3837 15.5732H11.9873C11.6939 15.5732 11.4125 15.6897 11.205 15.8971C10.9976 16.1046 10.881 16.3859 10.881 16.6792C10.881 16.9725 10.9976 17.2538 11.205 17.4612C11.4125 17.6686 11.6939 17.7851 11.9873 17.7851Z"
                        fill="white"
                      />
                    </svg>
                  </span>
                  <div className="khainnnn">
                    <h1>{curelem.Question}</h1>
                    <div>
                      <p>{curelem.Answer}</p>
                      {/* <span>
                        <svg
                          width="10"
                          height="18"
                          viewBox="0 0 10 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9.12285 7.94564L2.11745 0.395289C2.00239 0.270035 1.8655 0.170619 1.71468 0.102774C1.56385 0.03493 1.40207 0 1.23868 0C1.07529 0 0.913517 0.03493 0.762692 0.102774C0.611866 0.170619 0.474975 0.270035 0.359915 0.395289C0.129391 0.645669 0 0.984368 0 1.33741C0 1.69045 0.129391 2.02915 0.359915 2.27954L6.48654 8.96126L0.359915 15.5762C0.129391 15.8265 0 16.1652 0 16.5183C0 16.8713 0.129391 17.21 0.359915 17.4604C0.474544 17.5867 0.611242 17.6871 0.762087 17.756C0.912933 17.8248 1.07493 17.8606 1.23868 17.8613C1.40244 17.8606 1.56443 17.8248 1.71528 17.756C1.86612 17.6871 2.00282 17.5867 2.11745 17.4604L9.12285 9.91006C9.24848 9.78493 9.34875 9.63305 9.41732 9.464C9.4859 9.29495 9.52131 9.1124 9.52131 8.92785C9.52131 8.7433 9.4859 8.56075 9.41732 8.3917C9.34875 8.22265 9.24848 8.07078 9.12285 7.94564Z"
                            fill="white"
                          />
                        </svg>
                      </span> */}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        )}
        {Loading && <Loader />}
      </div>

      <Footer />
    </div>
  );
};

export default FAQTwo;

import "../../Css/Section4.css";
import React, { useContext, useEffect, useState } from "react";
// import { Swiper, SwiperSlide } from "swiper/react";
// import { EffectCards, Navigation } from "swiper/modules";
import "swiper/css/effect-cards";
import "swiper/css";
// import downarrow from "../../Assets/Images/chevrondown.png";
import { useNavigate } from "react-router-dom";
import Footer from "../UI/Footer";
import { Modal } from "react-bootstrap";
// import { motion } from "framer-motion";
// import Button, { Bonobtn } from "../UI/Button";
// import axios from "../../API";
import ApiHook from "../Hook/ApiHook";
// import { Store } from "../../Redux/MainStore";
import { useSelector } from "react-redux";
// import { ActivateOfferById } from "../../Redux/CarSlice";
// import { toast } from "react-hot-toast";
// import { handleOffer } from "../../Pages/Modelos/Modelos";
import { GlobalContext } from "../../Context/GlobalContext";
import ev6img from "../../Assets/modelosev6.png";
import ev9img from "../../Assets/modelosev9.png";
import { CircularProgress } from "@mui/material";

const Section4 = ({ ref }) => {
  const navigate = useNavigate();
  const [indexx, setindex] = useState(0);
  const [modalShow, setModalShow] = useState(false);
  const [loading, setloading] = useState(true);
  localStorage.setItem("cardindex", indexx);

  const { setD4000Show, setnewev9 } = useContext(GlobalContext);

  function ModelModal(props) {
    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        // backdrop="static"
        centered
        style={{ zIndex: "10000" }}
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body className="white text-center">
          <div className="Dropdown-Car-info-body-Head">
            <i>Información importante</i>
          </div>
          <p className="Modal-text">
            <div>
              Los precios que surgen en la página son válidos al momento en el
              que el Usuario reserva su vehículo mediante un depósito de
              Quinientos dólares, ($500.00). El precio de la reservación se
              honrará para el Usuario que reserve el vehículo mediante el pago
              referenciado. Sin embargo, los precios anunciados pueden cambiar
              en cualquier momento sin ningún tipo de aviso previo.
            </div>
          </p>
          <div className="p-1"></div>
        </Modal.Body>
      </Modal>
    );
  }

  useEffect(() => {
    setTimeout(() => {
      setloading(false);
    }, 3000);
  }, []);

  // const Modeldata = [
  //   // {
  //   //   id: 1,
  //   //   img: cardimg1,
  //   //   name: "Model CVE1-23",
  //   //   price: "$40,789",
  //   //   vorte: "522.7V",
  //   //   km: "58 kWh",
  //   //   rwd: "RWD",
  //   //   icon: cardicon1,
  //   //   icon2: cardicon2,
  //   //   icon3: cardicon3,
  //   //   MPGe: 232,
  //   // },
  //   {
  //     id: 2,
  //     img: cardimg2,
  //     name: "EV6 EX RWD",
  //     price: "$54,595",
  //     vorte: "697V",
  //     km: "77.4 kWh",
  //     rwd: "RWD",
  //     icon: cardicon1,
  //     icon2: cardicon2,
  //     icon3: cardicon3,
  //     MPGe: 310,
  //   },
  //   {
  //     id: 3,
  //     img: Newcardimg3,
  //     name: "EV6 GT-LINE RWD",
  //     price: "$57,695",
  //     vorte: "697V",
  //     km: "77.4 kWh",
  //     rwd: "RWD",
  //     icon: cardicon1,
  //     icon2: cardicon2,
  //     icon3: cardicon3,
  //     MPGe: 310,
  //   },
  //   {
  //     id: 4,
  //     img: cardimg4,
  //     name: "EV6 GT-LINE (E-AWD)",
  //     price: "$62,795",
  //     vorte: "697V",
  //     km: "77.4 kWh",
  //     rwd: "E-AWD",
  //     icon: cardicon1,
  //     icon2: cardicon2,
  //     icon3: cardicon3,
  //     MPGe: 260,
  //   },
  // ];

  // const icon3 = (
  //   <svg
  //     width="15"
  //     height="19"
  //     viewBox="0 0 15 19"
  //     fill="none"
  //     xmlns="http://www.w3.org/2000/svg"
  //   >
  //     <path
  //       opacity="0.6"
  //       d="M5.00905 18.3214C4.90624 18.3215 4.80465 18.2992 4.71143 18.256C4.61821 18.2129 4.53562 18.1499 4.46946 18.0716C4.4033 17.9933 4.35517 17.9015 4.32847 17.8027C4.30176 17.7039 4.29712 17.6005 4.31487 17.4997V17.4955L5.39296 11.604H1.21905C1.10319 11.604 0.989705 11.5714 0.891716 11.5099C0.793728 11.4483 0.71524 11.3605 0.66533 11.2564C0.61542 11.1524 0.596128 11.0364 0.609685 10.9219C0.623242 10.8074 0.669094 10.699 0.741939 10.6094L9.14769 0.268417C9.24337 0.147551 9.37619 0.0611972 9.52585 0.0225615C9.67551 -0.0160742 9.83377 -0.00486563 9.97642 0.0544733C10.1191 0.113812 10.2383 0.218015 10.3158 0.351148C10.3933 0.48428 10.4249 0.639014 10.4057 0.791687C10.4057 0.803137 10.4026 0.814205 10.4007 0.825655L9.31874 6.71865H13.4919C13.6077 6.71866 13.7212 6.75131 13.8192 6.81282C13.9172 6.87434 13.9957 6.9622 14.0456 7.06625C14.0955 7.17029 14.1148 7.28628 14.1013 7.40078C14.0877 7.51528 14.0418 7.62363 13.969 7.71328L5.5621 18.0543C5.49604 18.1373 5.41201 18.2045 5.31625 18.2507C5.2205 18.297 5.11549 18.3212 5.00905 18.3214Z"
  //       fill="white"
  //     />
  //   </svg>
  // );

  // const handleclick = (index) => {
  //   setindex(index);
  //   setTimeout(() => {
  //     navigate("/Model");
  //   }, 100);
  // };
  // const UserData = JSON.parse(localStorage.getItem("user-info"));

  // const ModalDataFromRedux = useSelector((state) => state.carData);

  // const { isloading } = ApiHook("/get-offer-status");

  // const getdata = async (item) => {
  //   // setisloading(true);
  //   try {
  //     const res = await axios.get("/update-offer-status", {
  //       headers: {
  //         Authorization: `Bearer ${UserData.Token}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     console.log("🚀 ~ file: Section4.js:174 ~ getdata ~ res:", res);
  //     toast.success(`${res.data.message}`);
  //     Store.dispatch(ActivateOfferById(res.data.message));
  //   } catch (error) {
  //     // seterror(error);
  //     // console.log(error);
  //   } finally {
  //     // setisloading(false);
  //   }
  // };
  return (
    <>
      <ModelModal show={modalShow} onHide={() => setModalShow(false)} />
      <div className="moulasdiv" id="Specs" ref={ref}>
        <span>Modelos</span>
        <div style={{ height: loading ? "50vh" : "" }}>
          {loading ? (
            <CircularProgress />
          ) : (
            <div className="d-flex">
              <div className="item1" onClick={() => navigate("/Modelos")}>
                <img src={ev6img} alt="" />
                <div>
                  <h2>EV6</h2>
                </div>
              </div>
              <div className="item1" onClick={() => navigate("/Modelos/EV9")}>
                <img src={ev9img} alt="" />
                <div>
                  <h2>EV9</h2>
                  {/* <h3>Pronto</h3> */}
                </div>
              </div>
            </div>
          )}
        </div>
        {/* <div className="swiperkhain">
          <div className="cardmaindivv">
            <Swiper
              slidesPerView={3}
              spaceBetween={10}
              navigation={true}
              // loop={true}
              modules={[Navigation]}
              pagination={{
                clickable: true,
                dynamicBullets: true,
              }}
              wrapperClass={window.innerWidth > 1450 ? "democlass" : null}
              className="w-100"
              breakpoints={{
                0.0: {
                  slidesPerView: 2,
                  spaceBetween: 20,
                },
                768: {
                  slidesPerView: 2,
                  spaceBetween: 40,
                },
                1024: {
                  slidesPerView: 3,
                  spaceBetween: 50,
                },
                1450: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
                1920: {
                  slidesPerView: 4,
                  spaceBetween: 50,
                },
              }}
            >
              {ModalDataFromRedux.map((curelem, index) => {
                return (
                  <SwiperSlide key={curelem.id}>
                    <div className="modulcard">
                      <div className="cardimg">
                        <motion.img
                          initial={{ x: 70, opacity: 0 }}
                          whileInView={{ x: 0, opacity: 1 }}
                          transition={{ duration: 0.5 }}
                          src={curelem.img}
                          alt=""
                          onClick={() => handleclick(index)}
                        />
                        <span onClick={() => setModalShow(true)}>{ico}</span>
                      </div>
                      <div
                        className="masinfo"
                        onClick={() => handleclick(index)}
                      >
                        <span>Más información</span>
                      </div>
                      <div
                        onClick={() => handleclick(index)}
                        className="modulscontent"
                      >
                        <span>{curelem.name}</span>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          <div>
                            {curelem.isOfferActivated && (
                              <p
                                style={{
                                  lineHeight: "10px",
                                  color: "#fff",
                                  fontSize: "20px",
                                }}
                              >
                                BONO{" "}
                                <span
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setD4000Show(true);
                                  }}
                                >
                                  $6,000
                                </span>
                              </p>
                            )}

                            <p
                              className={
                                curelem.isOfferActivated && "line-through"
                              }
                              style={{
                                lineHeight: "20px",
                                color: "#fff",
                                fontSize: "20px",
                              }}
                            >
                              {curelem.price}
                            </p>
                          </div>
                          {curelem.isOfferActivated && (
                            <p
                              style={{
                                lineHeight: "20px",
                                color: "#F3C300",
                                fontSize: "30px",
                              }}
                            >
                              {curelem.finalPrice}
                            </p>
                          )}
                        </div>

                        <div className="km">
                          <div className="first">
                            <img src={curelem.icon3} alt="" />
                            <span>{curelem.vorte}</span>
                          </div>
                          <div className="first">
                            <img src={curelem.icon2} alt="" />
                            <span>{curelem.km}</span>
                          </div>
                          <div className="first">
                            <img src={curelem.icon} alt="" />
                            <span>{curelem.rwd}</span>
                          </div>
                        </div>
                        <div className="mphdiv">
                          <div className="linediv3"></div>
                          <div className="mph">
                            <span>{curelem.MPGe}</span>
                            <p className="pragraphs">Rango</p>
                          </div>
                        </div>
                        {curelem.isOfferActivated ? (
                          <Bonobtn
                            onClick={(event) => {
                              event.stopPropagation();
                              handleOffer(curelem);
                            }}
                          >
                            Bono Activado
                          </Bonobtn>
                        ) : (
                          <>
                            {isloading ? (
                              <p className="loadingchalu">Loading...</p>
                            ) : (
                              <Button
                                variant={"reserbtn2"}
                                style={{ backgroundColor: "#878787" }}
                                onClick={(event) => {
                                  event.stopPropagation();
                                  handleOffer(curelem);
                                }}
                              >
                                Activar bono
                              </Button>
                            )}
                          </>
                        )}
                      </div>
                    </div>
                  </SwiperSlide>
                );
              })}
            </Swiper>
          </div>
        </div>
        <div className="cardmaindivvv">
          <Swiper
            effect={"cards"}
            grabCursor={true}
            modules={[EffectCards]}
            className="mySwiper"
            tabIndex={0}
            initialSlide={0}
          >
            {ModalDataFromRedux.map((curelem, index) => {
              return (
                <SwiperSlide key={curelem.id}>
                  <div className="modulcard">
                    <div className="cardimg">
                      <img
                        src={curelem.img}
                        alt=""
                        onClick={() => handleclick(index)}
                      />
                      <span onClick={() => setModalShow(true)}>{ico}</span>
                    </div>
                    <div onClick={() => handleclick(index)} className="masinfo">
                      <span>Más información</span>
                    </div>
                    <div
                      onClick={() => handleclick(index)}
                      className="modulscontent"
                    >
                      <span>{curelem.name}</span>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          {curelem.isOfferActivated && (
                            <p
                              style={{
                                lineHeight: "10px",
                                color: "#fff",
                                fontSize: "20px",
                              }}
                            >
                              BONO{" "}
                              <span
                                onClick={(e) => {
                                  e.stopPropagation();
                                  setD4000Show(true);
                                }}
                              >
                                $6,000
                              </span>
                            </p>
                          )}
                          <p
                            className={
                              curelem.isOfferActivated && "line-through"
                            }
                            style={{
                              lineHeight: "20px",
                              color: "#fff",
                              fontSize: "20px",
                            }}
                          >
                            {curelem.price}
                          </p>
                        </div>
                        {curelem.isOfferActivated && (
                          <p
                            style={{
                              lineHeight: "20px",
                              color: "#F3C300",
                              fontSize: "30px",
                            }}
                          >
                            {curelem.finalPrice}
                          </p>
                        )}
                      </div>
                      <div className="km">
                        <div className="first">
                          <img src={curelem.icon3} alt="" />
                          <span>{curelem.vorte}</span>
                        </div>
                        <div className="first">
                          <img src={curelem.icon2} alt="" />
                          <span>{curelem.km}</span>
                        </div>
                        <div className="first">
                          <img src={curelem.icon} alt="" />
                          <span>{curelem.rwd}</span>
                        </div>
                      </div>
                      <div className="mphdiv">
                        <div className="linediv3"></div>
                        <div className="mph">
                          <span>{curelem.MPGe}</span>
                          <p className="pragraphs">Rango</p>
                        </div>
                      </div>
                      {curelem.isOfferActivated ? (
                        <Bonobtn
                          onClick={(event) => {
                            event.stopPropagation();
                            handleOffer(curelem);
                          }}
                        >
                          Bono Activado
                        </Bonobtn>
                      ) : (
                        <>
                          {isloading ? (
                            <p className="loadingchalu">Loading...</p>
                          ) : (
                            <Button
                              variant={"reserbtn2"}
                              style={{ backgroundColor: "#878787" }}
                              onClick={(event) => {
                                event.stopPropagation();
                                handleOffer(curelem);
                              }}
                            >
                              Activar bono
                            </Button>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </SwiperSlide>
              );
            })}
            ;
          </Swiper>
        </div>
        <div className="viewarrowdiv">
          <div className="smajhniaraha" onClick={() => navigate("/Modelos")}>
            <span>Ver más</span>
            <div>
              <img src={downarrow} alt="" />
            </div>
          </div>
        </div> */}
      </div>
    </>
  );
};
<Footer />;
export default Section4;

import React from "react";
import { SpecificationData } from "../Data/Data";
import "../../Css/CarDetail/Specification.css";
import { Icons } from "../../Constant/Icons";

const Specificationthree = ({ onClick }) => {
  return (
    <div>
      <div className="wraperdiv">
        <div className="carddivmain">
          <div>
            <h1>Especificaciones EV6 GT-LINE(E-AWD)</h1>
            <span onClick={onClick}>{Icons.newico}</span>
          </div>
          {SpecificationData.map((curelem, index) => {
            return (
              <div key={index} className="specidivmain">
                <div>
                  <div className="limaindivv">
                    <h4>Especificaciones Mecánicas</h4>
                    <div>
                      <li>Motor Sincrónico de Imanes Permanentes</li>
                      <li>Tipo de tracción AWD</li>
                      <li>
                        Motor de tracción trasera de 239 kW con 320 caballos de
                        fuerza & 446 lbs- pies. de par de torsión
                      </li>
                      <li>
                        Freno de estacionamiento electrónico (EPB) con retención
                        automática
                      </li>
                      <li>
                        Sistema de frenado regenerativo con palancas de control
                      </li>
                      <li>Generador de energía a bordo (V2L)</li>
                    </div>
                  </div>
                  <div className="limaindivv" id="khainiexterior">
                    <h4>Exterior</h4>
                    <div>
                      <li>
                        Aros de 20 pulgadas en ruedas aerodinámicas con acabado
                        en gris oscuro
                      </li>
                      <li>Estilo GT-Line en el interior y exterior</li>
                      <li>
                        Control automático de luces con asistencia de luces LED
                      </li>
                      <li>
                        Retrovisor plegable eléctrico con calefacción e
                        indicadores
                      </li>
                      <li>
                        Cristal trasero con privacidad y spoiler traserotrasero
                      </li>
                      <li>Manijas automáticas</li>
                      <li>
                        Puerta de baúl inteligente con apertura automática
                      </li>
                      <li>Power Sunroof</li>
                      <li>Paquete de remolque</li>
                      <li>
                        Equipo de reparación para ruptura de goma (Tire Mobility
                        Kit) * la goma de repuesta no está incluida
                      </li>
                    </div>
                  </div>
                  <div className="limaindivv">
                    <h4>Kia Drive Wise</h4>
                    <div>
                      <li>
                        Asistencia para prevención de colisión frontal (FCA) con
                        detección de peatones (sensor al girar)
                      </li>
                      <li>
                        Asistencia para prevención de colisión frontal (FCA) con
                        detección de peatones (sensor al girar y cruzar)
                      </li>
                      <li>
                        Asistencia para prevención de colisión en punto ciego
                        (BCA)
                      </li>
                      <li>
                        Asistencia para prevención de colisión de tráfico
                        cruzado posterior (RCCA)
                      </li>
                      <li>
                        Asistencia de retención de carril (LKA) y Asistencia de
                        seguimiento de carril (LFA)
                      </li>
                      <li>
                        Alerta de distancia de estacionamiento (PDW) Trasero
                      </li>
                      <li>Asistencia de salida segura (SEA)</li>
                      <li>
                        Control de crucero inteligente (SCC) con parada y
                        arranque + aprendizaje automático
                      </li>
                      <li>Alerta de ocupante trasero (ROA)</li>
                      <li>Sistema de monitoreo a vuelta redonda (SVM)</li>
                      <li>Monitor para punto ciego (BVM)</li>
                      <li>
                        Asistencia para estacionamiento inteligente remoto
                      </li>
                      <li>
                        Asistencia para prevención de colisión al estacionarse-
                        reversa
                      </li>
                      <li>Head-Up Display </li>
                    </div>
                  </div>
                  <div className="limaindivv">
                    <h4>Seguridad</h4>
                    <div>
                      <li>Sistema avanzado de bolsas de aire duales</li>
                      <li>Bolsa de aire para las rodillas del conductor</li>
                      <li>
                        Bolsas de aire laterales en los asientos delanteros
                      </li>
                      <li>Bolsas de aire laterales tipo cortina</li>
                      <li>
                        Cinturones de seguridad de 3 puntos para todas las
                        posiciones del asiento
                      </li>
                      <li>
                        Pretensores de cinturones de seguridad (asientos
                        delanteros)
                      </li>
                      <li>
                        Sistema de frenos antibloqueo (ABS) con frenos de disco
                        en las 4 ruedas
                      </li>
                      <li>Manejo de estabilidad vehicular (VSM)</li>
                      <li>Sistema de control de tracción</li>
                      <li>Control de estabilidad electrónica</li>
                      <li>
                        Sistema de distribución electrónica de frenos (EBD)
                      </li>
                      <li>Sistema de asistencia de frenado (BA)</li>
                      <li>Control de asistencia de en cuestas (HAC)</li>
                      <li>
                        Sistema de monitoreo para la presión de aire en las
                        gomas (TPMS) con indicador de presión individual
                      </li>
                      <li>
                        Sistema de anclaje para asiento protector de niños
                        (LATCH)
                      </li>
                      <li>
                        Cerraduras de seguridad para niños en las puertas
                        traseras
                      </li>
                      <li>Apertura de emergencia interna del baúl</li>
                    </div>
                  </div>
                  <div className="limaindivv">
                    <h4>Sistema Eléctrico</h4>
                    <div>
                      <li>Tipo de batería Iones de Litio</li>
                      <li>Voltaje (V) 697V</li>
                      <li>Capacidad de carga 77.4 kWh</li>
                      <li>Velocidad máxima (mph) (AWD) 115 mph</li>
                      <li>Sistema de carga múltiple (400V–800V) )</li>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="limaindivv">
                    <h4>Interior</h4>
                    <div>
                      <li> Pantalla Panorámica Dual de 12.3" con navegación</li>
                      <li>Apple CarPlay® Android Auto™</li>
                      <li>Bluetooth® y Conectividad de manos libres</li>
                      <li>
                        Llave inteligente con encendido por botón - Encendido
                        Remoto
                      </li>
                      <li>Cargador Inalámbrico para teléfonos</li>
                      <li>Control automático de temperatura de zona dual</li>
                      <li>Asientos delanteros con calefacción</li>
                      <li>Asientos delanteros con ventilación</li>
                      <li>Asiento del conductor eléctrico con memoria</li>
                      <li>Materiales de asientos en cuero vegano</li>
                      <li>Paquete de asientos GT-Line Suede</li>
                      <li>Sistema de Audio Meridian Premium con 14 bocinas</li>
                      <li>Cuatro (4) puertos USB con carga rápida</li>
                      <li>Toma de corriente de 12V - parte delantera</li>
                      <li>Toma de corriente de 12V - parte trasera</li>
                      <li>Luces interiores de ambientación tipo LED</li>
                      <li>
                        Ventanas eléctricas con subida/bajada automática
                        delantera
                      </li>
                      <li>
                        Consola central delantera fija con bandeja de
                        almacenamiento en apoyabrazos y portavasos doble
                      </li>
                    </div>
                  </div>

                  <div className="limaindivv">
                    <h4>Medidas/ capacidades exteriores</h4>
                    <div>
                      <li>Largo 184.8 in.</li>
                      <li>Ancho (sin espejos laterales) 74.4 in</li>
                      <li>Altura 60.8 in</li>
                      <li>Distancia entre ejes 114.2 in</li>
                      <li>Alero (delantero/trasero) 33.3 in. / 37.4 in.</li>
                      <li>Pista (delantero/trasero) RWD 64.2 in. / 64.4 in.</li>
                      <li>Pista (delantera/trasera) RWD 6.1 in.</li>
                    </div>
                  </div>

                  <div className="limaindivv">
                    <h4>Medidas/ capacidades interiores</h4>
                    <div>
                      <li>
                        Espacio para cabeza con sunroof (delantero/trasero)
                        36.8/38.0 in.
                      </li>
                      <li>
                        Espacio para piernas (delantero/trasero) 42.2/39.0 in
                      </li>
                      <li>
                        Espacio para caderas (delantero/trasero) 54.9/53.2 in
                      </li>
                      <li>Volumen de pasajeros 103.0 cu. ft.</li>
                      <li>
                        Volumen de carga (asientos traseros levantados/asientos
                        traseros doblados) 24.4 in / 50.2 cu. ft.
                      </li>
                    </div>
                  </div>
                  <div className="limaindivv">
                    <h4>MPGe</h4>
                    <div>
                      <li>Ciudad 117</li>
                      <li>Autopista 96</li>
                      <li>Combinado 109</li>
                      <li>Autonomía totalmente eléctrica (millas) 232</li>
                    </div>
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default Specificationthree;

import React, { useEffect, useState } from "react";
import "../../Css/Profile/CanceledOrders.css";
import axios from "../../API";
import Loader from "../UI/Loader";
import img1 from "../../Assets/EV6 EX RWD/CV_BL_Front_Yacht_Blue_DU3.png";
import img2 from "../../Assets/EV6 EX RWD/CV_BL_Front_Gravity_Blue_B4U.png";
import img3 from "../../Assets/EV6 EX RWD/CV_BL_Front_Moonscape_Matte_KLM.png";
import img4 from "../../Assets/EV6 EX RWD/CV_BL_Front_Steel_Gray_KLG.png";
import img5 from "../../Assets/EV6 EX RWD/CV_BL_Front_Snow_White_Pearl_SWP.png";
import img6 from "../../Assets/EV6 EX RWD/CV_BL_Front_Glacier_GLB.png";
import img7 from "../../Assets/EV6 EX RWD/CV_BL_Front_Runway_Red_CR5.png";
import img8 from "../../Assets/EV6 EX RWD/CV_BL_Front_Aurora_Black Pearl_ABP.png";
import img9 from "../../Assets/EV6 EX RWD/CV_BL_Front_Urban_Yellow_UBY.png";
import img10 from "../../Assets/EV6 EX RWD/CV_BL_Front_Interstella_Gray_AGT.png";

import img11 from "../../Assets/GT LINE/CV_GT_Line_Front_Yacht_Blue_DU3-removebg-preview.png";
import img13 from "../../Assets/GT LINE/CV_GT_Line_Front_Moonscape_Matte_KLM-removebg-preview.png";
import img15 from "../../Assets/GT LINE/CV_GT_Line_Front_Snow_White_Pearl_SWP-removebg-preview.png";
import img17 from "../../Assets/GT LINE/CV_GT_Line_Front_Runway_Red_CR5-removebg-preview.png";
import img18 from "../../Assets/GT LINE/CV_GT_Line_Front_Aurora_Black_Pearl_ABP-removebg-preview.png";
import { useLocation } from "react-router-dom";
import NewImg1 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Yacht_Blue_DU3.png";
import NewImg2 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Runway_Red_CR5.png";
import NewImg3 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Aurora_Black Pearl_ABP.png";
import NewImg4 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Moonscape_Matte_KLM.png";
import NewImg5 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Snow_White_Pearl_SWP.png";

import ReactGA from "react-ga";
import { imagesdata } from "../Data/Data";

const CanceledOrdersPage = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  const UserData = JSON.parse(localStorage.getItem("user-info"));

  const [Loading, setLoading] = useState(true);

  const [CanceledOrders, setCanceledOrders] = useState([]);

  // OrderHistory API
  const getCanceledOrders = async () => {
    try {
      setLoading(true);
      const res = await axios.get("/get-cancel-history", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${UserData?.Token}`,
        },
      });
      if (res) {
        setLoading(false);
        // console.log("Canceled Order Data...............", res);
        setCanceledOrders(res.data);
      }
    } catch (error) {
      setLoading(false);
      // console.log("Catched Error....", error);
    }
  };

  useEffect(() => {
    getCanceledOrders();
  }, []);

  // const Navigate = useNavigate();

  var nf = new Intl.NumberFormat();

  function ImageShow(props) {
    const colorcode = imagesdata.filter(
      (item) => item.colorcode === props.extColor
    );
    let imageGet;
    if (props.ModalName === "EV6") {
      if (props.ModalName == "CVE1" || "EV6 EX RWD") {
        if (props.extColor == "#1A30AD") {
          imageGet = img1;
        } else if (props.extColor == "#34425F") {
          imageGet = img2;
        } else if (props.extColor == "#666666") {
          imageGet = img3;
        } else if (props.extColor == "#9C9EA0") {
          imageGet = img4;
        } else if (props.extColor == "#C6C4B7") {
          imageGet = img5;
        } else if (props.extColor == "#F9F9F9") {
          imageGet = img6;
        } else if (props.extColor == "#933531") {
          imageGet = img7;
        } else if (props.extColor == "#1B1B1B") {
          imageGet = img8;
        } else if (props.extColor == "#CD8700") {
          imageGet = img9;
        } else if (props.extColor == "#4F5354") {
          imageGet = img10;
        }
      } else if (props.ModalName == "GT-LINE RWD") {
        if (props.extColor == "#1A30AD") {
          imageGet = NewImg1;
        } else if (props.extColor == "#933531") {
          imageGet = NewImg2;
        } else if (props.extColor == "#1B1B1B") {
          imageGet = NewImg3;
        } else if (props.extColor == "#666666") {
          imageGet = NewImg4;
        } else if (props.extColor == "#C6C4B7") {
          imageGet = NewImg5;
        }
      } else if (props.ModalName == "EV6 GT-LINE (E-AWD)") {
        if (props.extColor == "#1A30AD") {
          imageGet = img11;
        } else if (props.extColor == "#933531") {
          imageGet = img17;
        } else if (props.extColor == "#1B1B1B") {
          imageGet = img18;
        } else if (props.extColor == "#666666") {
          imageGet = img13;
        } else if (props.extColor == "#C6C4B7") {
          imageGet = img15;
        }
      }
    } else if (props.ModalName === "EV9") {
      if (props.extColor === colorcode[0]?.colorcode) {
        imageGet = colorcode[0]?.images[5];
      }
    }
    return <img src={imageGet} width="150px" />;
  }

  return (
    <section className="white wasd-wasd-wasd pt-4">
      {!Loading && CanceledOrders.status === "success" ? (
        <>
          {CanceledOrders.message === "No Record" ? (
            <div>No tiene ninguna reserva realizada.</div>
          ) : (
            <div className="CanceledOrder-cars-divs-parent-div">
              {CanceledOrders.data.map((item, index) => {
                return (
                  <div
                    key={index}
                    className="OrderHistoryDiv d-flex gap-4 align-items-center"
                  >
                    <div>
                      <ImageShow
                        ModalName={item.ModelType}
                        extColor={item.Ext_Color_Code}
                      />
                    </div>
                    <div>
                      <div className="d-flex gap-4 align-items-center ">
                        <div className="d-flex flex-column">
                          <span className="OrderText">
                            Número de orden: {item.order_id}
                          </span>
                          <span className="OrderText">
                            Fecha de la orden: {item.order_at}
                          </span>
                        </div>
                        {/* <div>
                      <svg
                        width="15"
                        height="25"
                        viewBox="0 0 15 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M14.3723 11.1213L3.33586 0.553275C3.1546 0.377961 2.93894 0.238811 2.70132 0.143851C2.46371 0.0488906 2.20885 0 1.95144 0C1.69403 0 1.43917 0.0488906 1.20156 0.143851C0.963943 0.238811 0.748283 0.377961 0.567015 0.553275C0.203845 0.903726 0 1.37779 0 1.87194C0 2.36608 0.203845 2.84015 0.567015 3.1906L10.219 12.5428L0.567015 21.8015C0.203845 22.152 0 22.6261 0 23.1202C0 23.6143 0.203845 24.0884 0.567015 24.4389C0.747603 24.6156 0.962959 24.7562 1.2006 24.8525C1.43825 24.9488 1.69345 24.999 1.95144 25C2.20942 24.999 2.46463 24.9488 2.70228 24.8525C2.93992 24.7562 3.15528 24.6156 3.33586 24.4389L14.3723 13.8708C14.5702 13.6957 14.7281 13.4831 14.8362 13.2465C14.9442 13.0099 15 12.7544 15 12.4961C15 12.2378 14.9442 11.9822 14.8362 11.7456C14.7281 11.509 14.5702 11.2964 14.3723 11.1213V11.1213Z"
                          fill="#ED1B24"
                        />
                      </svg>
                    </div> */}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </>
      ) : (
        <Loader />
      )}
    </section>
  );
};

export default CanceledOrdersPage;

import Spinner from 'react-bootstrap/Spinner';
import tyre from '../../Assets/Images/tyre.png';

const Loader = () => {
    return (
        <>
            <div className='d-flex justify-content-center align-items-center' style={{height: '70vh'}}>
                <Spinner animation="border" variant="danger" />
                    {/* <img src={tyre} alt="" className='spin' /> */}
            </div>
        </>
    );
}

export default Loader;
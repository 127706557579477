import React, {
  useContext,
  useEffect,
  // useState
} from "react";
// import { Modal } from "react-bootstrap";
// import Button from "../../UI/Button";
import Footer from "../../UI/Footer";
import Navbar from "../../UI/Navbar";
import Section1 from "../Section1";
import Section2 from "../Section2";
import Section3 from "../Section3";
import Section4 from "../Section4";
import Section5 from "../Section5";
import Section6 from "../Section6";
// import Cookies from "universal-cookie";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import Separate from "../../../Pages/NewScreen/Separate";
import { GlobalContext } from "../../../Context/GlobalContext";

const LandingLayout = () => {
  const location = useLocation();

  const { setishsow } = useContext(GlobalContext);

  // useEffect(() => {
  //   setishsow(false);
  // }, []);

  useEffect(() => {
    ReactGA.pageview(location.pathname);
    localStorage.removeItem("Register-Start");
  }, []);

  // function ReservaModal(props) {
  //   return (
  //     <Modal
  //       {...props}
  //       size="lg"
  //       aria-labelledby="contained-modal-title-vcenter"
  //       backdrop="static"
  //       centered
  //       style={{ zIndex: 1000000000 }}
  //     >
  //       <Modal.Header closeButton></Modal.Header>
  //       <Modal.Body className="white text-center">
  //         <h4 className="pt-2 pb-2">¡Alerta!</h4>
  //         <p className="Modal-text">
  //           {/* El usuario entiende y acepta que la página de EV no es una página de
  //           mercadeo. La razón para visitar la página de EV es para completar la
  //           reservación de un vehículo, no es para recibir información
  //           publicitaria sobre el mismo. Del usuario desear obtener información
  //           sobre los vehículos distribuidos por Kia Puerto Rico, debe visitar
  //           el enlace <br />{" "} */}
  //           El usuario entiende y acepta que la página de Kiaevpr no es una
  //           página exclusivamente de mercadeo. En esta página el usuario además
  //           de ver y educarse sobre el producto de EV también tendrá la opción
  //           de reservarlo de así desearlo. Del usuario querer conocer más sobre
  //           Kia y el resto de los modelos distribuidos por Kia Puerto Rico, debe
  //           visitar el enlace <br />
  //           <a
  //             style={{ borderBottom: "1px solid grey" }}
  //             target={"_blank"}
  //             href="https://www.kia.com/pr/main.html"
  //           >
  //             {/* www.kia.com/pr. */}
  //             www.kia.com/pr.
  //           </a>
  //         </p>
  //         {/* <p className="Modal-text">
  //           Esta página no estará disponible para reservas hasta Abril 2023,
  //           pero podrás navegar y conocer más sobre el EV6.
  //         </p> */}

  //         {/* <div className="p-2"></div> */}
  //         <Button variant="red" onClick={props.onHide}>
  //           Entendido
  //         </Button>
  //         <div className="p-3"></div>
  //       </Modal.Body>
  //     </Modal>
  //   );
  // }

  // const [modalShow, setModalShow] = useState(false);
  // const cookies = new Cookies("registered");

  // useEffect(() => {
  //   setTimeout(() => {
  //     setModalShow(true);
  //   }, 3000);
  //   localStorage.removeItem("Register-token");
  // }, []);

  // useEffect(() => {
  //   if (cookies.get("registered")) {
  //     setModalShow(false); //Modal does not open if cookie exists
  //   } else if (!cookies.get("registered")) {
  //     setTimeout(() => {
  //       cookies.set("registered", "true", {
  //         path: "/",
  //       });
  //       setModalShow(true); //Creates a cookie and shows modal.
  //     }, 2000);
  //   }
  // }, []);

  // useEffect(() => {
  //   window.addEventListener("beforeunload", cookies.remove("registered"));
  // }, []);

  return (
    <>
      <Navbar />
      <div className="khainwalidiv">
        <Section1 />
        <Section4 />
      </div>
      <Section2 />
      <Section3 />
      <Section5 />
      <Section6 />
      <Footer />
    </>
  );
};

export default LandingLayout;

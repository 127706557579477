import React, { useEffect } from "react";
import Wrapper from "../../Components/UI/Wrapper";
import Card from "../../Components/UI/Card";
import Button from "../../Components/UI/Button";
import "../../Css/Alert/Alert.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";

const Intro = () => {
  const location = useLocation();
  const name = location?.state?.name;

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  return (
    <div>
      <Wrapper>
        <Card head={""}>
          <div id="akhaini">
            <h1>¡Estás a un solo paso de reservar tu {name || "EV6"}!</h1>
            <div>
              <p>
                Al registrar tu cuenta podrás completar la reservación de tu{" "}
                {name || "EV6"}, ver el estatus de tu orden y recibir
                actualizaciones de ella. ¡Registra tu cuenta ahora!
              </p>
            </div>
            <div className="d-flex justify-content-center align-items-center">
              <Link
                to={
                  localStorage.getItem("StaticCartData")
                    ? "/Iniciar-Sesion"
                    : "/Registrate"
                }
              >
                <Button variant="red">Continuar</Button>
              </Link>
            </div>
          </div>
        </Card>
      </Wrapper>
    </div>
  );
};

export default Intro;

import React, { useContext, useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import WrapperSecond from "../../Components/UI/Wrapper";
import "../../Css/Car/Detail.css";
import img from "../../Assets/Images/greenCar.png";
import Button from "../../Components/UI/Button";
// import CancleOrderAlert from '../Alerts/CancleOrder'
import Modal from "react-bootstrap/Modal";
import { useNavigate } from "react-router-dom";
import KiaLogo from "../../Assets/Images/KiaLogo.png";
import axios from "../../API";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import img1 from "../../Assets/EV6 EX RWD/CV_BL_Front_Yacht_Blue_DU3.png";
import img2 from "../../Assets/EV6 EX RWD/CV_BL_Front_Gravity_Blue_B4U.png";
import img3 from "../../Assets/EV6 EX RWD/CV_BL_Front_Moonscape_Matte_KLM.png";
import img4 from "../../Assets/EV6 EX RWD/CV_BL_Front_Steel_Gray_KLG.png";
import img5 from "../../Assets/EV6 EX RWD/CV_BL_Front_Snow_White_Pearl_SWP.png";
import img6 from "../../Assets/EV6 EX RWD/CV_BL_Front_Glacier_GLB.png";
import img7 from "../../Assets/EV6 EX RWD/CV_BL_Front_Runway_Red_CR5.png";
import img8 from "../../Assets/EV6 EX RWD/CV_BL_Front_Aurora_Black Pearl_ABP.png";
import img9 from "../../Assets/EV6 EX RWD/CV_BL_Front_Urban_Yellow_UBY.png";
import img10 from "../../Assets/EV6 EX RWD/CV_BL_Front_Interstella_Gray_AGT.png";

import img11 from "../../Assets/GT LINE/CV_GT_Line_Front_Yacht_Blue_DU3-removebg-preview.png";
import img13 from "../../Assets/GT LINE/CV_GT_Line_Front_Moonscape_Matte_KLM-removebg-preview.png";
import img15 from "../../Assets/GT LINE/CV_GT_Line_Front_Snow_White_Pearl_SWP-removebg-preview.png";
import img17 from "../../Assets/GT LINE/CV_GT_Line_Front_Runway_Red_CR5-removebg-preview.png";
import img18 from "../../Assets/GT LINE/CV_GT_Line_Front_Aurora_Black_Pearl_ABP-removebg-preview.png";
import Specification from "../../Components/CarDetail/Specification";
import Specificationtwo from "../../Components/CarDetail/Specificationtwo";
import Specificationthree from "../../Components/CarDetail/Specificationthree";

import NewImg1 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Yacht_Blue_DU3.png";
import NewImg2 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Runway_Red_CR5.png";
import NewImg3 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Aurora_Black Pearl_ABP.png";
import NewImg4 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Moonscape_Matte_KLM.png";
import NewImg5 from "../../Assets/EV6 GT-Line RWD/CV_GT_Line_Front_Snow_White_Pearl_SWP.png";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { useSelector } from "react-redux";
import { GlobalContext } from "../../Context/GlobalContext";
import EV9Specification from "../../Components/EV9Detail/EV9Specification";
import { imagesdata } from "../../Components/Data/Data";

const Detail = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  const { setD4000Show } = useContext(GlobalContext);

  function CancleOrderModal(props) {
    function ChangeBtn() {
      setdisable(true);
      setModalShow(false);
    }

    return (
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        backdrop="static"
        centered
        style={{ backgroundColor: "rgba(159, 160, 163, 0.55)" }}
      >
        <Modal.Body className="white text-center pt-3">
          <h4 className="pb-3">Cancelar pedido</h4>
          <p className="Modal-text">
            ¿Está seguro que desea cancelar su reserva? Al hacerlo se le
            devolverá el total de su reserva en un periodo de cinco a diez días.
            Sí en ese periodo de tiempo, no se ha reflejado el reembolso favor
            de comunicarse. (puede ir el tiempo que ustedes estiman que se
            devolverá, por lo menos Stripe ese es el tiempo que ellos
            determina).
          </p>
          <div className="p-2"></div>
          <div className="d-flex justify-content-around gap-3 cancle-modal-btns">
            <Button variant="white" onClick={props.onHide}>
              No deseo cancelar
            </Button>
            <Button variant="red" onClick={ChangeBtn}>
              SÍ, deseo Cancelar
            </Button>
          </div>
          <div className="p-3"></div>
        </Modal.Body>
      </Modal>
    );
  }

  const Navigate = useNavigate();

  const UserData = JSON.parse(localStorage.getItem("user-info"));

  const [disable, setdisable] = useState(false);
  const [Specs, setshow] = useState(false);

  const [modalShow, setModalShow] = useState(false);

  const [Loading, setLoading] = useState(true);

  const [OrderHistory, setOrderHistory] = useState([]);

  const getOrderHistory = async () => {
    setLoading(true);
    try {
      const res = await axios.get("/get-history", {
        headers: {
          "content-type": "application/json",
          Authorization: `Bearer ${UserData?.Token}`,
        },
      });
      // console.log("OrderHistory...............", res);
      if (res.data.status == "success") {
        setOrderHistory(res.data);
        setLoading(false);
      } else {
        // setLoading(false);
        // console.log("res Error....", res);
      }
    } catch (error) {
      // setLoading(false);
      // console.log("Catched Error....", error);
    }
  };

  useEffect(() => {
    getOrderHistory();
  }, []);

  const colorcode = imagesdata.filter(
    (item) => item.colorcode === OrderHistory?.data?.Ext_Color_Code
  );
  let images;
  if (OrderHistory.data) {
    if (OrderHistory.data.ModelType === "EV6") {
      if (OrderHistory.data.model == "CVE1" || "CVE2") {
        if (OrderHistory.data.Ext_Color_Code == "#1A30AD") {
          images = img1;
        } else if (OrderHistory.data.Ext_Color_Code == "#34425F") {
          images = img2;
        } else if (OrderHistory.data.Ext_Color_Code == "#666666") {
          images = img3;
        } else if (OrderHistory.data.Ext_Color_Code == "#9C9EA0") {
          images = img4;
        } else if (OrderHistory.data.Ext_Color_Code == "#C6C4B7") {
          images = img5;
        } else if (OrderHistory.data.Ext_Color_Code == "#F9F9F9") {
          images = img6;
        } else if (OrderHistory.data.Ext_Color_Code == "#933531") {
          images = img7;
        } else if (OrderHistory.data.Ext_Color_Code == "#1B1B1B") {
          images = img8;
        } else if (OrderHistory.data.Ext_Color_Code == "#CD8700") {
          images = img9;
        } else if (OrderHistory.data.Ext_Color_Code == "#4F5354") {
          images = img10;
        }
      } else if (OrderHistory.data.model == "GT-LINE RWD") {
        if (OrderHistory.data.Ext_Color_Code == "#1A30AD") {
          images = NewImg1;
        } else if (OrderHistory.data.Ext_Color_Code == "#933531") {
          images = NewImg2;
        } else if (OrderHistory.data.Ext_Color_Code == "#1B1B1B") {
          images = NewImg3;
        } else if (OrderHistory.data.Ext_Color_Code == "#666666") {
          images = NewImg4;
        } else if (OrderHistory.data.Ext_Color_Code == "#C6C4B7") {
          images = NewImg5;
        }
      } else if (OrderHistory.data.model == "EV6 GT-LINE (E-AWD)") {
        if (OrderHistory.data.Ext_Color_Code == "#1A30AD") {
          images = img11;
        } else if (OrderHistory.data.Ext_Color_Code == "#933531") {
          images = img17;
        } else if (OrderHistory.data.Ext_Color_Code == "#1B1B1B") {
          images = img18;
        } else if (OrderHistory.data.Ext_Color_Code == "#666666") {
          images = img13;
        } else if (OrderHistory.data.Ext_Color_Code == "#C6C4B7") {
          images = img15;
        }
      }
    } else if (OrderHistory.data.ModelType === "EV9") {
      if (OrderHistory.data.Ext_Color_Code === colorcode[0].colorcode) {
        images = colorcode[0]?.images[5];
      }
    }
  }

  // console.log(OrderHistory);
  var nf = new Intl.NumberFormat();

  const ModalDataFromRedux = useSelector((state) => state.carData);

  const fromCartdata = ModalDataFromRedux.filter(
    (item) => item?.name == OrderHistory?.data?.model
  );

  const navigate = useNavigate();
  const handlenavi = () => {
    // console.log(OrderHistory.data);
    if (OrderHistory.data.model == "EV6 EX RWD") {
      setshow(true);
    } else if (OrderHistory.data.model == "GT-LINE RWD") {
      setshow(true);
    } else if (OrderHistory.data.model == "EV6 GT-LINE (E-AWD)") {
      setshow(true);
    } else if (OrderHistory.data.model == "EV9 GT-LINE") {
      setshow(true);
    }
  };

  return (
    <>
      {!Specs ? (
        <div style={{ background: "#292d32" }}>
          {Loading ? (
            <div>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
                // onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          ) : (
            <div className="OrderDetail-Main-parent-div">
              <div className="OrderDetail-kia-logo-div pt-5 pb-5">
                <img
                  src={KiaLogo}
                  style={{ cursor: "pointer" }}
                  alt="Logo"
                  onClick={() => Navigate("/Inicio")}
                />
              </div>
              <div className="ConfirmOrder-Body-parent-div">
                <div className="ConfirmOrder-Body-header-div">
                  Confirmación de la orden
                </div>
                <div className="ConfirmOrder-Body-content-div">
                  <div className="ConfirmOrder-Body-image-div d-flex justify-content-center align-items-center w-100">
                    <img src={images} width="90%" />
                  </div>
                  <div className="ConfirmOrder-Body-content">
                    <div className="Confirm-order-Specs-div">
                      <span>
                        Número de transacción: {OrderHistory.data.trans_id}
                      </span>
                      <span>Número de orden: {OrderHistory.data.order_id}</span>
                      {/* <span className="dollor-txt">Total reservas: $ 500</span> */}
                      <span className="dollor-txt">Depósito: $500</span>
                    </div>
                    <div className="ModelName-Price-div">
                      <span>
                        Model {OrderHistory.data.model}{" "}
                        {OrderHistory.data.ModelType === "EV6" &&
                          OrderHistory.data.Mng_Year}
                      </span>
                    </div>
                    <div className="Confirm-order-Specs-div">
                      {OrderHistory.data.ModelType === "EV6" ? (
                        <>
                          {fromCartdata[0]?.isOfferActivated == true ? (
                            <>
                              <p className="OrderText">
                                MSRP:
                                <span className="line-through">
                                  {fromCartdata[0]?.price}
                                </span>
                              </p>
                              <p>
                                <span
                                  onClick={(e) => {
                                    e.stopPropagation();
                                    setD4000Show(true);
                                  }}
                                >
                                  -$6,000{" "}
                                </span>
                                <span
                                  style={{
                                    color: "#F3C300",
                                    fontSize: "22px",
                                    fontWeight: "600",
                                  }}
                                >
                                  {" "}
                                  $
                                  {nf.format(
                                    parseInt(OrderHistory?.data.Price)
                                  )}
                                </span>
                              </p>
                            </>
                          ) : (
                            <>
                              <p className="OrderText">
                                MSRP:
                                <span className="">
                                  $
                                  {nf.format(
                                    parseInt(OrderHistory?.data.Price)
                                  )}
                                </span>
                              </p>
                            </>
                          )}
                        </>
                      ) : (
                        <span>MSRP: ${OrderHistory.data.Price}</span>
                      )}
                      <span>
                        Color exterior: {OrderHistory.data.Ext_Color_Name}
                      </span>
                      <span>
                        Color interior: {OrderHistory.data.Int_Color_Name}
                      </span>
                      {/* <span>Aros: Gris oscuro</span> */}
                    </div>
                    {/* {OrderHistory.data.ModelType === "EV6" && ( */}
                    <div className="additionalSpecs">
                      <button
                        className="additionalSpecs-btn"
                        onClick={handlenavi}
                      >
                        Especificaciones adicionales
                      </button>
                    </div>
                    {/* )} */}
                    <div className="w-100">
                      <Button
                        disable={disable}
                        variant={"red"}
                        onClick={() => navigate("/Perfil/History")}
                      >
                        {/* {disable ? "pedido cancelado" : "Cancelar pedido"} */}
                        Ir al perfil
                      </Button>

                      {/* <CancleOrderModal
                      show={modalShow}
                      onHide={() => setModalShow(false)}
                    /> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {/* {!Loading && OrderHistory && (
      )} */}
        </div>
      ) : (
        <>
          {" "}
          {OrderHistory?.data.model == "EV6 EX RWD" ? (
            <Specification onClick={() => setshow(false)} />
          ) : null}
          {OrderHistory?.data.model == "GT-LINE RWD" ? (
            <Specificationtwo onClick={() => setshow(false)} />
          ) : null}
          {OrderHistory.data.model == "EV6 GT-LINE (E-AWD)" ? (
            <Specificationthree onClick={() => setshow(false)} />
          ) : null}
          {OrderHistory.data.model == "EV9 GT-LINE" ? (
            <EV9Specification onClick={() => setshow(false)} />
          ) : null}
        </>
      )}
    </>
  );
};

export default Detail;

import React, { useEffect, useState } from "react";
import "../../Css/Auth/CarRegister/RegisterAddress.css";
import Wrapper from "../../Components/UI/Wrapper";
import Card from "../../Components/UI/Card";
import Input from "../../Components/UI/Input";
import Button from "../../Components/UI/Button";
import { useFormik } from "formik";
import { RegisterAddressSchema } from "../../Schemas";
import { Form } from "react-bootstrap";
import axios from "../../API";
import { Navigate, useNavigate } from "react-router-dom";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { toast } from "react-hot-toast";

const RegisterAddress = () => {
  const Navigate = useNavigate();

  // const Token = JSON.parse(localStorage.getItem("Register-token"));
  const UserData = JSON.parse(localStorage.getItem("user-info"));
  const StaticCartData = JSON.parse(localStorage.getItem("StaticCartData"));

  // console.log("🚀", Token.reg_id)

  const [IsError, setIsError] = useState("");
  const [Loading, setLoading] = useState(false);

  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  const onSubmit = async () => {
    // console.log("Form-values......", values);
    setLoading(true);
    try {
      const response = await axios.post(
        // "/register-step-2",
        "/customer-register-step-2",
        {
          RegsterId: UserData.Id,
          Address_Line_1: values.Address,
          Address_Line_2: values.SecondAddress,
          Country: values.Country,
          PostalCode: values.PostalCode,
        },
        {
          headers: {
            "content-type": "application/json",
          },
        }
      );
      // console.log("🚀 response.....", response);
      setIsError(response.data.message);
      if (response) {
        setLoading(false);
        if (response.data.status == "success" && StaticCartData) {
          toast.success(`Paso 2 Registro Exitoso`);
          Navigate("/Titular");
        } else {
          toast.success(`Paso 2 Registro Exitoso`);
          Navigate("/Modelos");
        }
        // if (response.data.status == "success" && StaticCartData) {
        //   Navigate("/Titular");
        //   toast.success(`Paso 2 Registro Exitoso`);
        // } else {
        //   // if (response.data.data.reg_id) {
        //   toast.success(`Paso 2 Registro Exitoso`);
        //   Navigate("/Modelos");
        // }
      }

      if (response) {
        var res = response.data.data;
        // console.log("🚀 ~ file: RegisterAddress.js ~ line 26 ~ onSubmit ~ res", res)
      }
    } catch (error) {
      setLoading(false);
      // console.log("🚀error....", error.message);
    }
  };

  const initialValues = {
    Address: "",
    SecondAddress: "",
    Country: "",
    PostalCode: "",
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: RegisterAddressSchema,
      onSubmit,
    });

  return (
    <div>
      <Wrapper>
        <Card head={"Ingresa tu dirección"}>
          <span className="form-error-head"> {IsError} </span>
          <Form onSubmit={handleSubmit} className="mainform5">
            <div className="inputs5">
              <div className="w-100 d-flex flex-column justify-content-start">
                <Input
                  name="Address"
                  text={"Dirección"}
                  type={"text"}
                  placeholder={"Escribe tu dirección"}
                  value={values.Address}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.Address && touched.Address ? (
                  <p className="form-error"> {errors.Address} </p>
                ) : null}
              </div>

              <div className="w-100 d-flex flex-column justify-content-start">
                <Input
                  name="SecondAddress"
                  text={"Segunda línea de dirección (opcional)"}
                  type={"text"}
                  placeholder={"Escribe tu dirección"}
                  value={values.SecondAddress}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </div>

              <div className="w-100 d-flex flex-column justify-content-start">
                <Input
                  name="Country"
                  text={"País"}
                  type={"text"}
                  placeholder={"Escribe tu país"}
                  value={values.Country}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.Country && touched.Country ? (
                  <p className="form-error"> {errors.Country} </p>
                ) : null}
              </div>
              <div className="w-100 d-flex flex-column justify-content-start">
                <Input
                  name="PostalCode"
                  text={"Código postal"}
                  type={"number"}
                  placeholder={"Escribe el código postal"}
                  value={values.PostalCode}
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
                {errors.PostalCode && touched.PostalCode ? (
                  <p className="form-error"> {errors.PostalCode} </p>
                ) : null}
              </div>
            </div>
            <div className="">
              <Button variant="red" type={"submit"}>
                <span className="p-4">Continuar con la reserva</span>
              </Button>
            </div>
          </Form>
        </Card>
        {!Loading && <div></div>}
        {Loading && (
          <div>
            <Backdrop
              sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={true}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
          </div>
        )}
      </Wrapper>
    </div>
  );
};

export default RegisterAddress;

import React, { useState, useEffect, useRef } from "react";
import "../../Css/Section2.css";
import Carousel from "react-bootstrap/Carousel";
import imgslide from "../../Assets/Images/imginter.png";
import inter2 from "../../Assets/Images/inter2.png";
import inter3 from "../../Assets/Images/inter3.png";
import inter4 from "../../Assets/Images/inter4.png";
import inter5 from "../../Assets/Images/inter5.png";
import exter1 from "../../Assets/Images/exter1.png";
import exter2 from "../../Assets/Images/exter2.png";
import exter3 from "../../Assets/Images/exter3.png";
import exter4 from "../../Assets/Images/exter4.png";
import exter5 from "../../Assets/Images/exter5.png";
import exter6 from "../../Assets/Images/exter6.png";
import exter7 from "../../Assets/Images/exter7.png";
import exter8 from "../../Assets/Images/exter8.png";

import img4 from "../../Assets/Images/img4.png";
import img5 from "../../Assets/Images/img5.png";
import downarrow from "../../Assets/Images/chevrondown.png";
import { useNavigate } from "react-router-dom";
import close from "../../Assets/Images/close.png";

const Section2 = ({ref}) => {
  const navigate = useNavigate();
  const [show, setshow] = useState(false);
  const [image, setimage] = useState("");

  const menuref = useRef(null);
  useEffect(() => {
    let handler = (event) => {
      if (menuref.current) {
        if (!menuref.current.contains(event.target)) {
          setshow(false);
        }
      }
    };
    document.addEventListener("scroll", handler);
    return () => {
      document.removeEventListener("scroll", handler);
    };
  }, []);

  const [index, setIndex] = useState(0);

  const handleSelect = (selectedIndex, e) => {
    setIndex(selectedIndex);
  };

  const Imagelink = (e) => {
    setimage(e.target.src);
    setshow(true);
  };



  return (
    <div className="gellerimaindiv" id="section2"  ref={ref}>
      <h1 className="gelehead">Galería</h1>
      <Carousel
        id="Galeria"
        interval={null}
        activeIndex={index}
        onSelect={handleSelect}
      >
        <Carousel.Item>
          <div className="container2">
            <div className="maindivimg">
              <div>
                <img onClick={Imagelink} src={inter4} alt="" />
                <img onClick={Imagelink} src={inter5} alt="" />
              </div>
              <div>
                <img onClick={Imagelink} src={img5} alt="" />
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="container2">
            <div className="maindivimg">
              <div>
                <img onClick={Imagelink} src={exter2} alt="" />
                <img onClick={Imagelink} src={img4} alt="" />
              </div>
              <div>
                <img onClick={Imagelink} src={exter1} alt="" />
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="container2">
            <div className="maindivimg">
              <div>
                <img onClick={Imagelink} src={inter2} alt="" />
                <img onClick={Imagelink} src={inter3} alt="" />
              </div>
              <div>
                <img onClick={Imagelink} src={imgslide} alt="" />
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="container2">
            <div className="maindivimg">
              <div>
                <img onClick={Imagelink} src={exter5} alt="" />
                <img onClick={Imagelink} src={exter4} alt="" />
              </div>
              <div>
                <img onClick={Imagelink} src={exter3} alt="" />
              </div>
            </div>
          </div>
        </Carousel.Item>
        <Carousel.Item>
          <div className="container2">
            <div className="maindivimg">
              <div>
                <img onClick={Imagelink} src={exter7} alt="" />
                <img onClick={Imagelink} src={exter8} alt="" />
              </div>
              <div>
                <img onClick={Imagelink} src={exter6} alt="" />
              </div>
            </div>
          </div>
        </Carousel.Item>
      </Carousel>
      <div className="viewarrowdiv">
        <div className="smajhniaraha" onClick={() => navigate("/Galeria")}>
          <span>Ver más imágenes</span>
          <div>
            <img onClick={Imagelink} src={downarrow} alt="" />
          </div>
        </div>
      </div>
      {show && (
        <div className="model" ref={menuref}>
          <div className="closeicon">
            <img src={close} alt="" onClick={() => setshow(false)} />
            <img src={image} alt="" />
          </div>
        </div>
      )}
    </div>
  );
};

export default Section2;

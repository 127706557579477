import React, { Suspense, lazy } from "react";
import Navbar from "../UI/Navbar";
import Footer from "../UI/Footer";
import { CircularProgress } from "@mui/material";
const EV9Model = lazy(() => import("./EV9Model"));

const CarDetailWrapper = () => {
  return (
    <>
      <Navbar />
      <div className="cardetailpage">
        <div className="modelselectdiv">
          <span
            className={"spanactive"}
            // onClick={() => setsteps(0)}
          >
            EV9 GT-LINE
          </span>
        </div>
        <div className="allmo">
          <Suspense
            fallback={
              <div className="fallbackloading">
                <CircularProgress />
              </div>
            }
          >
            <EV9Model />
          </Suspense>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default CarDetailWrapper;

import axios from "../../API";
import { useEffect, useState } from "react";

const ApiHook = (url, params) => {
  const [isloading, setisloading] = useState(false);
  const [error, seterror] = useState(false);
  const [data, setdata] = useState();
  const UserData = JSON.parse(localStorage.getItem("user-info"));

  // const getdata = async () => {
  //   setisloading(true);
  //   try {
  //     const res = await axios.get(url, params, {
  //       headers: {
  //         Authorization: `Bearer ${UserData.Token}`,
  //         "Content-Type": "application/json",
  //       },
  //     });
  //     setdata(res.data);
  //     setisloading(false);
  //     console.log(res);
  //   } catch (error) {
  //     seterror(error);
  //   } finally {
  //     setisloading(false);
  //   }
  // };

  const getdata = async () => {
    setisloading(true);
    try {
      const res = await axios.get(url, {
        params: params, // Pass query parameters here if needed
        headers: {
          Authorization: `Bearer ${UserData.Token}`,
          "Content-Type": "application/json",
        },
      });
      setdata(res.data);
      setisloading(false);
      // console.log(res);
    } catch (error) {
      seterror(error);
    } finally {
      setisloading(false);
    }
  };

  useEffect(() => {
    getdata();
  }, []);

  return { error, data, isloading };
};

export default ApiHook;

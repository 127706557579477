import "../../Css/Section3.css";

function Section3() {
  return (
    <>
      <iframe
        title="kia360"
        id="exterior"
        className="ifram"
        // src="https://kiaevpr.com/video/"
        src="https://testkia.brainmoodpr.co/Video/"
      ></iframe>
    </>
  );
}

export default Section3;

import React, { useEffect, useState } from "react";
import "../../Css/Section5.css";
import sliderback from "../../Assets/Images/SliderbackNew.jpg";
import sliderback2 from "../../Assets/Images/Sliderback2New.jpg";
import sliderback3 from "../../Assets/Images/Sliderback3New.jpg";
import sliderback4 from "../../Assets/Images/Sliderback4New.jpg";
import overlay from "../../Assets/Images/overlayback.png";

const data = [
  {
    id: 1,
    head: "Pantalla curva",
    pra: "Ambas pantallas de 12 pulgadas te ofrecen la mejor vista con resolución 1,920x720, tanto en el tablero de instrumentos como en la pantalla central de información.",
    img: sliderback,
  },
  {
    id: 2,
    head: "Sonido Premium",
    pra: "Sistema de 14 altavoces con integración Intelli Q el cual se ajusta a la velocidad del vehículo y Horizon; optimizando el sonido y control de imagen. Tendrás una experiencia de inmersiva con el sonido e-ASD de su sistema de audio Meridian, uno de los mejores del mercado.",
    img: sliderback2,
  },
  {
    id: 3,
    head: "Controlador conmutable de infoentretenimiento/clima",
    pra: "El sistema de audio premium Meridian de 14 altavoces con altavoz para graves y amplificador externo brinda un sonido potente para amplificar la experiencia de manejo del EV6.1",
    img: sliderback3,
  },
  {
    id: 4,
    head: "Monitor de vista 360° (SVM)",
    pra: "Puedes darle zoom a la vista 360° que las cámaras del sistema SVM, por sus siglas en inglés, provee de tu EV6, permitiéndote conducir mejor en lugares estrechos.",
    img: sliderback4,
  },
];

const Section5 = ({ref}) => {
  const [current, setcurrent] = useState(0);
  const [sliderdata, setsliderdata] = useState(data[0]);
  const [active, setactive] = useState({
    activeObject: null,
    objects: [{ id: 1 }, { id: 2 }, { id: 3 }, { id: 4 }],
  });

  const length = data.length;

  const nextslide = () => {
    setcurrent(current === length - 1 ? 0 : current + 1);
  };
  const prevslide = () => {
    setcurrent(current === 0 ? length - 1 : current - 1);
  };

  useEffect(() => {
    const slider = data[current];
    setsliderdata(slider);
    setactive({ ...active, activeObject: active.objects[current] });
  }, [current]);

  const handleclick = (index) => {
    const slider = data[index];
    setsliderdata(slider);
    setactive({ ...active, activeObject: active.objects[index] });
    setcurrent(index);
  };

  return (
    // React-Bootstrap carousel
    <div className="conntai" ref={ref}>
      <div className="section5">
        <div className="overdataimg">
          <img src={sliderdata.img} alt="" />
          <img className="over" src={overlay} alt="" />
        </div>
        <div className="slidediv" onClick={prevslide}>
          <svg
            width="45"
            height="45"
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M15.8624 23.8311L26.4749 34.4249C26.6492 34.6006 26.8566 34.7401 27.085 34.8353C27.3135 34.9305 27.5586 34.9795 27.8061 34.9795C28.0537 34.9795 28.2987 34.9305 28.5272 34.8353C28.7557 34.7401 28.9631 34.6006 29.1374 34.4249C29.4866 34.0736 29.6826 33.5983 29.6826 33.103C29.6826 32.6076 29.4866 32.1324 29.1374 31.7811L19.8561 22.4061L29.1374 13.1249C29.4866 12.7736 29.6826 12.2983 29.6826 11.803C29.6826 11.3076 29.4866 10.8324 29.1374 10.4811C28.9637 10.304 28.7566 10.163 28.5281 10.0665C28.2996 9.9699 28.0542 9.91965 27.8061 9.91862C27.5581 9.91965 27.3127 9.9699 27.0841 10.0665C26.8556 10.163 26.6485 10.304 26.4749 10.4811L15.8624 21.0749C15.6721 21.2504 15.5202 21.4635 15.4163 21.7007C15.3124 21.9379 15.2588 22.1941 15.2588 22.453C15.2588 22.7119 15.3124 22.9681 15.4163 23.2053C15.5202 23.4424 15.6721 23.6555 15.8624 23.8311Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="conte">
          <div className="MainC">
            {data.map((curelem, i) => {
              return (
                <div
                  key={curelem.id}
                  className={i === current ? "slideactive" : "prahead"}
                  onClick={() => handleclick(i)}
                >
                  <h3>{curelem.head}</h3>
                  <div
                    className={i === current ? "borderactive" : "borderrrr"}
                  ></div>
                  <div>
                    <p>{curelem.pra}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
        <div className="slidediv2" onClick={nextslide}>
          <svg
            width="45"
            height="45"
            viewBox="0 0 45 45"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M29.1375 21.1689L18.525 10.5751C18.3507 10.3994 18.1433 10.2599 17.9148 10.1647C17.6863 10.0695 17.4413 10.0205 17.1937 10.0205C16.9462 10.0205 16.7012 10.0695 16.4727 10.1647C16.2442 10.2599 16.0368 10.3994 15.8625 10.5751C15.5133 10.9264 15.3173 11.4017 15.3173 11.897C15.3173 12.3924 15.5133 12.8676 15.8625 13.2189L25.1437 22.5939L15.8625 31.8751C15.5133 32.2264 15.3173 32.7017 15.3173 33.197C15.3173 33.6924 15.5133 34.1676 15.8625 34.5189C16.0361 34.696 16.2432 34.837 16.4717 34.9335C16.7003 35.0301 16.9457 35.0803 17.1937 35.0814C17.4418 35.0803 17.6872 35.0301 17.9157 34.9335C18.1443 34.837 18.3513 34.696 18.525 34.5189L29.1375 23.9251C29.3278 23.7496 29.4797 23.5365 29.5836 23.2993C29.6875 23.0621 29.7411 22.8059 29.7411 22.547C29.7411 22.2881 29.6875 22.0319 29.5836 21.7947C29.4797 21.5576 29.3278 21.3445 29.1375 21.1689Z"
              fill="white"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Section5;

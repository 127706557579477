import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Button from "../UI/Button";
import { PaymentSchema } from "../../Schemas";
import { useFormik } from "formik";
import Backdrop from "@mui/material/Backdrop";
import CircularProgress from "@mui/material/CircularProgress";
import axios from "../../API";
import "../../Css/Auth/CarRegister/PaymentMethod.css";
import { CardElement, useElements, useStripe } from "@stripe/react-stripe-js";
import Input from "../UI/Input";
import visalogo from "../../Assets/Images/visalogo.png";
import masterlogo from "../../Assets/Images/masterlogo.png";
import Loader from "../UI/Loader";

const Payment = () => {
  const [StripeCardToken, setStripeCardToken] = useState([]);
  const [ContentOne, setContentOne] = useState(true);
  const [carddata, setcard] = useState([]);
  const [IsError, setIsError] = useState("");
  const [Loading, setLoading] = useState(false);
  const [BackDropLoading, setBackDropLoading] = useState(false);
  const UserData = JSON.parse(localStorage.getItem("user-info"));
  // console.log(token);
  const getcard = async () => {
    try {
      setLoading(true);
      const response = await axios.get("/get-card-list", {
        headers: {
          Authorization: `Bearer ${UserData.Token}`,
        },
      });
      if (response) {
        setcard(response.data.data);
        setLoading(false);
        // console.log("Get card responce", response);
      }
    } catch (error) {
      setLoading(false);
      // console.log(error);
    }
  };
  // console.log(carddata);

  useEffect(() => {
    getcard();
  }, []);

  const initialValues = {
    CardName: "",
    CardNumber: "",
  };

  const CARD_OPTIONS = {
    iconStyle: "solid",
    style: {
      base: {
        iconColor: "#c4f0ff",
        color: "#000000",
        fontWeight: 500,
        fontFamily: "Roboto, Open Sans, Segoe UI, sans-serif",
        fontSize: "16px",
        fontSmoothing: "antialiased",
        ":-webkit-autofill": {
          color: "#fce883",
        },
        "::placeholder": {
          color: "#000000",
        },
      },
      invalid: {
        iconColor: "#D40000",
        color: "#D40000",
      },
    },
  };
  const stripe = useStripe();
  const element = useElements();
  const onSubmit = async () => {
    setLoading(true);
    const card = element.getElement(CardElement);
    const result = await stripe.createToken(card, {
      name: values.CardName,
    });
    setStripeCardToken(result);

    if (result.error) {
      setLoading(false);
      // console.log("[error]", result.error);
      setIsError(result.error.message);
    } else {
      // console.log("[Result]", result);
      try {
        const response = await axios.post(
          "/add-card",
          {
            CardToken: result.token.id,
          },
          {
            headers: {
              Authorization: `Bearer ${UserData.Token}`,
            },
          }
        );
        if (response) {
          // console.log(response);
          setLoading(false);
          setContentOne(true);
          getcard();
        }
      } catch (error) {
        setLoading(false);
        setIsError(error);
        // console.log(error);
      }
    }
    // console.log("result.....", result);
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: initialValues,
      validationSchema: PaymentSchema,
      onSubmit,
    });

  const deleteCard = async (CardId) => {
    // console.log(CardId);
    if (carddata.length == 1) {
      // console.log("You cannot delete all cards");
    } else {
      try {
        setBackDropLoading(true);
        const responce = await axios.post(
          "/delete-card",
          {
            card_id: CardId,
          },
          {
            headers: {
              Authorization: `Bearer ${UserData.Token}`,
            },
          }
        );
        if (responce) {
          setBackDropLoading(false);
          getcard();
        }
      } catch (error) {
        setBackDropLoading(false);
        // console.log(error);
      }
    }
  };

  return (
    <section className="white pt-4">
      {ContentOne ? (
        <>
          {!Loading && (
            <>
              <div className="getcarddata">
                {carddata &&
                  carddata.map((curelem, index) => {
                    return (
                      <div lg={12} key={index} className="d-flex gap-4">
                        <div lg={6}>
                          <Row>
                            <div className="d-flex gap-3">
                              <div className="brandlogo">
                                <img
                                  src={
                                    curelem.CardBrand === "Visa"
                                      ? visalogo
                                      : curelem.CardBrand === "MasterCard"
                                      ? masterlogo
                                      : null
                                  }
                                  alt=""
                                />
                              </div>
                              <span className="ProfileHead">
                                Ending {curelem.CardNumber}
                              </span>
                            </div>
                          </Row>

                          <Row className="pt-1">
                            <span className="HeadText">{curelem.CardName}</span>
                          </Row>
                        </div>
                        {carddata.length == 1 ? (
                          <div></div>
                        ) : (
                          <div>
                            <button
                              className="delete-btn"
                              onClick={() => deleteCard(curelem.CardId)}
                            >
                              <i className="fa fa-trash" aria-hidden="true"></i>
                            </button>
                          </div>
                        )}
                      </div>
                    );
                  })}
              </div>

              <div className="p-4"></div>
              <Col lg={12}>
                <Button variant={"red"} onClick={() => setContentOne(false)}>
                  Añadir tarjeta
                </Button>
              </Col>
            </>
          )}
          {Loading && <Loader />}

          {!BackDropLoading && <div></div>}
          {BackDropLoading && (
            <div>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
                // onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          )}
        </>
      ) : (
        <div className="back-btn">
          <svg
            style={{
              cursor: "pointer",
              position: "absolute",
              top: "-45px",
              left: "0",
            }}
            onClick={() => setContentOne(true)}
            width="40"
            height="40"
            viewBox="0 0 108 108"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M38.07 57.1951L63.54 82.6201C63.9584 83.0419 64.4561 83.3766 65.0044 83.6051C65.5528 83.8335 66.141 83.9512 66.735 83.9512C67.3291 83.9512 67.9172 83.8335 68.4656 83.6051C69.014 83.3766 69.5117 83.0419 69.93 82.6201C70.7681 81.7769 71.2386 80.6364 71.2386 79.4476C71.2386 78.2587 70.7682 77.1182 69.93 76.2751L47.655 53.7751L69.93 31.5001C70.7682 30.657 71.2386 29.5164 71.2386 28.3276C71.2386 27.1387 70.7682 25.9982 69.93 25.1551C69.5133 24.7299 69.0163 24.3916 68.4678 24.1599C67.9194 23.9281 67.3304 23.8076 66.735 23.8051C66.1396 23.8076 65.5507 23.9281 65.0022 24.1599C64.4538 24.3916 63.9568 24.7299 63.54 25.1551L38.07 50.5801C37.6132 51.0015 37.2487 51.5129 36.9994 52.0821C36.75 52.6514 36.6213 53.2661 36.6213 53.8876C36.6213 54.509 36.75 55.1238 36.9994 55.693C37.2487 56.2623 37.6132 56.7737 38.07 57.1951Z"
              fill="#d40000"
            />
          </svg>
          <Col lg={8}>
            <form onSubmit={handleSubmit} className="mainform3">
              <div className="khaincard">
                <div className="cardelemnt2">
                  <Input
                    type={"text"}
                    text={"Nombre en la tarjeta"}
                    name="CardName"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.CardName}
                  />
                </div>
                {errors.CardName && touched.CardName ? (
                  <p className="form-error2"> {errors.CardName} </p>
                ) : null}
                <div className="cardelemnt2">
                  <CardElement
                    options={{ CARD_OPTIONS, hidePostalCode: true }}
                    name="CardNumber"
                  />
                </div>
                <p className="form-error2">{IsError}</p>
              </div>
              <div className="">
                <Button variant={"red"} type={"submit"}>
                  {!Loading ? " Guardar" : "Loading....."}
                </Button>
              </div>
            </form>
          </Col>
          {!Loading && <div></div>}
          {Loading && (
            <div>
              <Backdrop
                sx={{
                  color: "#fff",
                  zIndex: (theme) => theme.zIndex.drawer + 1,
                }}
                open={true}
                // onClick={handleClose}
              >
                <CircularProgress color="inherit" />
              </Backdrop>
            </div>
          )}
        </div>
      )}
    </section>
  );
};

export default Payment;

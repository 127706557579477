import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga";
import { Helmet } from "react-helmet";
import Navbar from "../../../Components/UI/NavbarTwo";
import GalleryNav from "../EV9/GallaryNav";
import Footer from "../../../Components/UI/Footer";

const Gallery = () => {
  const location = useLocation();

  useEffect(() => {
    ReactGA.pageview(location.pathname);
  }, []);

  return (
    <>
      <Helmet>
        <title>Kia EV9 | Galería de Fotos | Reserva en línea</title>
        <link rel="canonical" href="https://kiaevpr.com/#/Galeria" />
        <meta
          name="description"
          content="Mira la galería de fotos del nuevo y totalmente eléctrico Kia EV9. Marcarás camino en esta nueva generación de movilidad sostenible. Reserva en línea tu modelo favorito."
        />
      </Helmet>
      <Navbar />
      <div className="gallerydiv">
        <GalleryNav />
      </div>
      <Footer />
    </>
  );
};

export default Gallery;

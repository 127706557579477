import React from "react";
import "../../Css/Input.css";

const Input = ({
  type,
  name,
  onChange,
  text,
  placeholder,
  onBlur,
  required,
}) => {
  return (
    <div className="maindivinput">
      <div id="input">
        <span>{text}</span>
        <input
          required={required}
          type={type}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          onBlur={onBlur}
        />
      </div>
    </div>
  );
};

export default Input;

export const Newinput = ({ title, ...all }) => {
  return (
    <div className="newinputparent">
      <span>{title}</span>
      <input className="newinput" {...all} />
    </div>
  );
};

export const Newdropdown = ({
  type,
  name,
  onChange,
  placeholder,
  onBlur,
  required,
  title,
  style,
  value,
}) => {
  return (
    <div className="newinputparent">
      <span>{title}</span>
      <div className="newinputparentselect">
        <select
          required={required}
          type={type}
          name={name}
          onChange={onChange}
          placeholder={placeholder}
          onBlur={onBlur}
          className=""
          style={style}
          value={value}
        >
          <option value="" disabled={"disable"}>
            Selecciona tiempo
          </option>
          <option value="1 mes">1 mes</option>
          <option value="3 meses">3 meses</option>
          <option value="6 meses">6 meses</option>
          <option value="1 año">1 año</option>
        </select>
      </div>
    </div>
  );
};
